// IMPORT REACT
import React,{ useState } from 'react'
// IMPORT USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// MENU WE PERNIKAHAN
const MenuWePernikahanMandiri = () => { // KURUNG BUKA MENU WE PERNIKAHAN
    // MEMBUAT VARIABEL WE PERNIKAHAN
    const [displayLoader, setDisplayLoader] = useState("none")
    const { panggilanParams } = useParams();
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = [
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/aglaonema`, viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/agapanthus`, viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/alamanda`, viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/alamanda.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/amaryllis`, viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/bnw1`, viewLink: '/we-pernikahan/bnw1', nama: 'Bnw 1', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bnw1.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/bnw2`, viewLink: '/we-pernikahan/bnw2', nama: 'Bnw 2', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bnw2.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/bnw3`, viewLink: '/we-pernikahan/bnw3', nama: 'Bnw 3', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bnw3.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/bnw4`, viewLink: '/we-pernikahan/bnw4', nama: 'Bnw 4', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bnw4.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/blegold`, viewLink: '/we-pernikahan/blegold', nama: 'BleGold', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/blegold.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/blepink`, viewLink: '/we-pernikahan/blepink', nama: 'BlePink', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/blepink.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/bletosca`, viewLink: '/we-pernikahan/bletosca', nama: 'BleTosca', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bletosca.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/blewhite`, viewLink: '/we-pernikahan/blewhite', nama: 'BleWhite', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/blewhite.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/cute`, viewLink: '/we-pernikahan/cute', nama: 'Cute', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/cute.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/adorable`, viewLink: '/we-pernikahan/adorable', nama: 'Adorable', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/adorable.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/beauty`, viewLink: '/we-pernikahan/beauty', nama: 'Beauty', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/beauty.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form-mandiri/${panggilanParams}/cool`, viewLink: '/we-pernikahan/cool', nama: 'Cool', imgSrc: 'http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/cool.jpg', alt: 'Undangan Digital Pernikahan' }
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>Menu We Pernikahan Mandiri</h4>
                                            <p className='text-center fw-semibold'>Buat Undangan Pernikahan Digital Secara Mandiri Di We Pernikahan</p>
                                        </div>
                                    </div>
                                    <div className="row mb-5 pb-1">
                                    {jenisWePernikahan.map((data, index) => (
                                            <div key={index.id} className='col-6 col-lg-4 col-xl-3 mb-4' id='pernikahan'>
                                                <div className='card' style={{ width: '100%' }}>
                                                    <h5 className='card-title text-center mt-2 fw-bold'>{data.nama}</h5>
                                                    <img src={data.imgSrc} className='mx-auto d-block mt-1' style={{ width: '125px', minHeight: '175px' }} alt={data.alt} />
                                                    <div className='card-body'>
                                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                                        <div className='row'>
                                                            <div className='col-12 col-sm-6'>
                                                                <a href={`${data.viewLink}`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                                            </div>
                                                            <div className='col-12 col-sm-6'>
                                                                <a href={`${data.createLink}`} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN
// EKSPOR
export default MenuWePernikahanMandiri