import React, {useState} from 'react'
import axios from 'axios'
import NavbarBot from '../../Layout/NavbarBot.jsx'

const ResumeArsitekturIot = () => {
    const [name,] = useState('webewers')
    const [saran, setSaran] = useState('')
    const [msg, setMsg] = useState('')
    const createSaran = async (e) => {
        e.preventDefault()
        try{
            await axios.post('https://server13.webew.id/createSaranBE',{
                saran: saran,
                nama: name
            })
            window.location.reload()
        } catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    return (
        <div className='background-home' style={{marginTop:'0px'}}>
            <section id='home'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='title-webew-home'>Resume Arsitektur IOT</h1>
                        </div>
                    </div>
                </div>    
            </section>
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            <section id='about' style={{marginTop:'-70px'}}>
                <div className='container'>
                    <p className='text-webew-home px-4' style={{textAlign:"justify"}}>
                        <h4>Author by Mauladafa Fiscal Wibowo (10210006)</h4>
                        <br/>
                        Deskripsi Arsitektur Internet of Things (IoT)
                        Konstruksi Arsitektur Internet of Things (IoT) mengacu pada serangkaian langkah yang mengatur pengembangan IoT. Dengan kata lain, arsitektur IoT berperan sebagai strategi agar pengembangan IoT berlangsung dengan lancar sehingga mencapai target yang diinginkan.
                        <br/>
                        <br/>
                        Tingkatan dalam Arsitektur Internet of Things (IoT)
                        Lapisan Persepsi: Membicarakan komponen fisik dalam struktur IoT seperti sensor, aktuator, dan alat lainnya yang berinteraksi langsung dengan lingkungan sekitar.
                        Lapisan Jaringan: Bertanggung jawab atas manajemen data yang terkumpul oleh lapisan persepsi dan menjaga keamanan dalam pertukaran data antar perangkat.
                        Lapisan Aplikasi: Mirip dengan lapisan aplikasi pada OSI (Open System Interconnection), mengatur aliran komunikasi data perangkat di internet dan terkoneksi langsung dengan pengguna.
                        <br/>
                        <br/>
                        Tahapan dalam Arsitektur Internet of Things (IoT)
                        Terdapat empat tahapan penting dalam arsitektur IoT yang perlu dipahami:
                        Objek Terhubung: Perangkat harus mampu tersambung dengan internet melalui sensor atau aktuator.
                        Gateway Internet: Data diubah menjadi format biner dan dikirimkan melalui gateway internet.
                        Sistem Informasi Pinggir: Melibatkan proses pra-pemrosesan dan analisis data.
                        Pusat Data dan Penyimpanan Cloud: Melibatkan proses penyimpanan data pada platform IoT.
                        <br/>
                        <br/>
                        Ragam dalam Arsitektur Internet of Things (IoT)
                        Objek: Benda yang dilengkapi dengan sensor dan aktuator untuk mengumpulkan, mengirimkan data, dan menjalankan tindakan yang diinginkan.
                        Gateways: Menyediakan konektivitas antar perangkat dan melakukan pra-pemrosesan serta penyaringan data sebelum dikirimkan ke cloud.
                        Cloud Gateways: Bertindak sebagai perantara antara gerbang dan server Cloud IoT, memastikan kesesuaian protokol jaringan.
                        Pengolah Data Streaming: Memastikan aliran data input yang efisien ke dalam data lake serta kelancaran aplikasi pengontrolan.
                        Data Lake: Tempat penyimpanan data yang dihasilkan dari objek IoT.
                        Big Data: Basis data besar yang menyimpan data yang telah disaring dan diproses sebelumnya dari waduk data.
                        Analisis Data: Memahami himpunan data dengan presentasi seperti skema, diagram, atau infografis.
                        Machine Learning: Memperbarui model IoT dengan mempelajari peluang terbaik dari penggunaan model IoT tertentu.
                        Perangkat Lunak Pengguna: Perangkat lunak yang memungkinkan pengguna mengontrol objek IoT.
                        <br/>
                        <br/>
                        Contoh Penerapan Arsitektur Internet of Things (IoT)
                        <ul>
                            <li>Rumah Cerdas</li>
                            <li>Kendaraan Cerdas</li>
                            <li>Sistem E-Tilang</li>
                            <li>Pemantauan Kesehatan</li>
                            <li>Rak Pintar</li>
                            <li>Pencahayaan Cerdas</li>
                            <li>dan lain-lain.</li>
                        </ul>
                        Daftar Referensi:
                        <br/>
                        Marza Akhda, Andri. (2023, September, 26). Yuk, Kenali Lebih dalam Tentang Arsitektur IoT di Sini!. Edspert.id. <a href="https://edspert.id/blog/arsitektur-iot/#:~:text=Arsitektur%20IoT%20adalah%20lembaran%20kerja,proses%20pengembangan%20IoT%20mencapai%20tujuannya">https://edspert.id/blog/arsitektur-iot/#:~:text=Arsitektur%20IoT%20adalah%20lembaran%20kerja,proses%20pengembangan%20IoT%20mencapai%20tujuannya</a>.
                    </p>
                </div>
            </section>
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px',marginTop:'0px'}} alt='bg lengkung'/>
            <section id='saran' className='mb-5'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <div className='box p-3' style={{width: '100%',minHeight:'100px'}}>
                                <form onSubmit={createSaran} className='mt-3'>
                                    <div className='container'>
                                        <div className='row'>
                                            <h6 className='card-title text-center mb-3'>Beri saran untuk kemajuan webew</h6>
                                            <div className='col-12 col-md-6'>
                                                <input type='text' className='form-control form mb-3' value={saran} onChange={(e)=> setSaran(e.target.value)} required placeholder='Beri saran'/>
                                                <input type='text' className='form-disable form mb-3' value={name} readOnly/>
                                            </div>
                                            <div className='col-12 col-md-6 mb-2'>
                                                <input type='submit' className='btn btn-webew-login mb-2' value='Kirim Saran'/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* NAVBARBOT */}
            <NavbarBot/>
        </div>
    )
}

export default ResumeArsitekturIot