// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT JWT DECODE
import jwt_decode from 'jwt-decode'
// IMPORT USE PARAMS, USE NAVIGATE
import { useParams, useNavigate } from 'react-router-dom'
// IMPORT FORMAT RUPIAH
import { FormatRupiah } from "@arismun/format-rupiah"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE DASHBOARD
const MenuWeNabung = () => { // KURUNG BUKA MENU WE DASHBOARD
    // MEMBUAT VARIABEL WE DASHBOARD
    const { panggilanParams, jenisTabunganParams } = useParams();
    const [displayLoader, setDisplayLoader] = useState('')
    const [nama, setNama] = useState('')
    const [jenisTabungan, setJenisTabungan] = useState(jenisTabunganParams)
    const [jenisTabunganUser, setJenisTabunganUser] = useState('')
    const [target, setTarget] = useState('')
    const [uangMasukKeluar, setUangMasukKeluar] = useState('')
    const [keterangan, setKeterangan] = useState('')
    const [nominal, setNominal] = useState('')
    const [totalNominal, setTotalNominal] = useState('')
    const [uangMasuk, setUangMasuk] = useState('')
    const [uangKeluar, setUangKeluar] = useState('')
    const [panggilan, setPanggilan] = useState('')
    const [dataWeNabung, setDataWeNabung] = useState([])
    const [dataWeNabungByJenisTabungan, setDataWeNabungByJenisTabungan] = useState([])
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL MESSAGE
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        SelectWeNabungByJenisTabungan()
        SelectWeNabung()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setNama(decoded.name)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader('none')
        } catch (error) {
            if(error.response){
                navigate('/login/menu-we-nabung', { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setNama(decoded.name)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENYIMPAN WENABUNG
    const createWeNabung = async (e) => {
        e.preventDefault()
        try{
            // Gunakan jenisTabunganUser jika ada input dari pengguna, jika tidak gunakan nilai default
            const finalJenisTabungan = jenisTabunganUser || jenisTabungan;
            await axios.patch('https://server13.webew.id/createWeNabungBE',{
                nama,
                jenisTabungan:finalJenisTabungan,
                target,
                uangMasukKeluar,
                keterangan,
                nominal,
                panggilan
            })
            setTarget("")
            setUangMasukKeluar("")
            setKeterangan("")
            setNominal("")
            SelectWeNabungByJenisTabungan()
            SelectWeNabung()
        } catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN WE NABUNG BERDASARKAN PANGGILAN DAN JENIS TABUNGAN BY PARAMS
    const SelectWeNabungByJenisTabungan = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeNabungByJenisTabunganBE/${panggilanParams}/${jenisTabunganParams}`)
            const data = response.data;
            setDataWeNabungByJenisTabungan(data);
            // Filter data untuk mengambil target dari item dengan nominal 0
            const targetTabunganData = data.filter(item => item.nominal === 0).map(item => item.target); // Ambil hanya nilai target
            setTarget(targetTabunganData);
            // SUM uang masuk dan uang keluar secara terpisah
            const uangMasukTotal = data
            .filter(item => item.uangMasukKeluar === "Uang Masuk").reduce((acc, item) => acc + (item.nominal || 0), 0);
            const uangKeluarTotal = data
            .filter(item => item.uangMasukKeluar === "Uang Keluar").reduce((acc, item) => acc + (item.nominal || 0), 0);
            setTotalNominal(uangMasukTotal - uangKeluarTotal); // Total keseluruhan
            setUangMasuk(uangMasukTotal); // Simpan total uang masuk ke state
            setUangKeluar(uangKeluarTotal);
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN WE NABUNG BERDASARKAN PANGGILAN BY PARAMS
    const SelectWeNabung = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeNabungBE/${panggilanParams}`)
            // Hapus duplikat berdasarkan 'jenisTabungan'
            const uniqueData = response.data.filter((item, index, self) =>
                index === self.findIndex((t) => t.jenisTabungan === item.jenisTabungan)
            );
            setDataWeNabung(uniqueData);
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES HANDLE CLICK BACK
    const handleClickBack  = () => {
        navigate(`/menu-we-nabung/${panggilanParams}/${panggilanParams}`)
        window.location.reload()
    }
    // PROSES HANDLE CLICK NEXT
    const handleClickNext  = async (jenisTabungan) => {
        navigate(`/menu-we-nabung/${panggilanParams}/${jenisTabungan}`)
        window.location.reload()
    }
    // MENGHITUNG PRESENTASE PROGRESS
    const progress = target > 0 ? (totalNominal / target) * 100 : 0;
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id='wrapper'>
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* MODAL TAMBAH TABUNGAN */}
                <div className="modal fade" id="staticBackdropTambahTabungan" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto px-4 py-1"  data-bs-toggle='modal' data-bs-target='#staticBackdropTambahTabungan'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12">
                                        <p className="text-center fw-bold text-center">Tambahkan Pemasukan / pengeluaran pada ({jenisTabunganParams})</p>
                                        <form onSubmit={createWeNabung}>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='mt-2' style={{marginBottom:"-7px",marginLeft:"14px"}}>Uang Masuk / Keluar:</p>
                                                        <select className="form-select form mb-3" id="uangMasukKeluar" aria-label="uangMasukKeluar" value={uangMasukKeluar} onChange={(e)=>setUangMasukKeluar(e.target.value)} required>
                                                            <option defaultValue={"Masuk"}></option>
                                                            <option defaultValue="Uang Masuk">Uang Masuk</option>
                                                            <option defaultValue="Uang Keluar">Uang Keluar</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='mt-2' style={{marginBottom:"-7px",marginLeft:"14px"}}>Keterangan:</p>
                                                        <input type='text' className='form-control form mb-3' value={keterangan} onChange={(e)=> setKeterangan(e.target.value)} required/>
                                                        <input type='text' className='form-disable mb-3' value={panggilan} onChange={(e)=> setPanggilan(e.target.value)} required/>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='mt-2' style={{marginBottom:"-7px",marginLeft:"14px"}}>Nominal Uang:</p>
                                                        <input type='number' className='form-control form mb-3' value={nominal} onChange={(e)=> setNominal(e.target.value)} required placeholder=''/>
                                                        <input type='text' className='form-disable mb-3' value={jenisTabungan} onChange={(e)=> setJenisTabungan(e.target.value)} required/>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='mt-2' style={{marginBottom:"0.9px",marginLeft:"14px",color:"white"}}>Tombol Tambahkan:</p>
                                                        <button type='submit' className='btn btn-webew-product mb-3'><i className="bi bi-send-plus"></i> Oke Tambahkan</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP TAMBAH TABUNGAN */}
            {/* MODAL JENIS TABUNGAN */}
                <div className="modal fade" id="staticBackdropJenisTabungan" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="btn btn-logout mx-auto px-4 py-1"  data-bs-toggle='modal' data-bs-target='#staticBackdropJenisTabungan'>Tutup</button>
                            </div>
                            <div className="modal-body">
                                <p className="text-center fw-bold text-center">Tambahkan Jenis Tabungan dan Target Tabungan</p>
                                <div className="row">
                                    <div className="col-12">
                                        <form onSubmit={createWeNabung}>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='mt-2' style={{marginBottom:"-7px",marginLeft:"14px"}}>Jenis Tabungan:</p>
                                                        <input type='text' className='form-control form mb-3' value={jenisTabunganUser} onChange={(e)=> setJenisTabunganUser(e.target.value)} maxLength={"30"} required/>
                                                        <input type='text' className='form-disable mb-3' value={nama} onChange={(e)=> setNama(e.target.value)} required/>
                                                    </div>
                                                    <div className='col-12 col-md-6'>
                                                        <p className='mt-2' style={{marginBottom:"-7px",marginLeft:"14px"}}>Target Tabungan:</p>
                                                        <input type='number' className='form-control form mb-3' value={target} onChange={(e)=> setTarget(e.target.value)} required/>
                                                        <input type='text' className='form-disable mb-3' value={panggilan} onChange={(e)=> setPanggilan(e.target.value)} required/>
                                                    </div>
                                                    <p className='text-center' style={{color:"red",marginBottom:"-10px",fontSize:"10pt"}}>{msg}</p>
                                                    <div className='col-12'>
                                                        <button type='submit' className='btn btn-webew-product mt-4 mb-2 mt-2'><i className="bi bi-send-plus"></i> Oke Tambahkan</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP JENIS TABUNGAN */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR*/}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAP */}
                <div id='content-wrapper'>
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                {jenisTabunganParams !== panggilan                           
                                ?<div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>We Nabung ({jenisTabunganParams})</h4>
                                        </div>
                                    </div>
                                    <div className='row mb-5 pb-2'>
                                        <div className='col-12 col-lg-6 mt-2'>
                                            <div className='row'>
                                                <div className='col-12 mb-4'>
                                                    <div className='box pt-4 px-3 pb-0' style={{width: '100%',minHeight:'80px'}}>
                                                        <div className="container">
                                                            <div className='row'>
                                                                <div className='col-12 col-md-6 mb-4'>
                                                                    <button className="btn btn-webew-product" onClick={handleClickBack} type="button" style={{marginTop:"-2px"}}><i className="bi bi-arrow-left-circle"></i> Pilih Jenis Tabungan</button>
                                                                </div>
                                                                <div className='col-12 col-md-6 mb-4'>
                                                                    <button type='button' className='btn btn-webew-product' data-bs-toggle='modal' data-bs-target='#staticBackdropTambahTabungan' style={{marginTop:"-2px"}}><i className="bi bi-pencil-square"></i> update Tabungan Ku</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='box p-3' style={{width: '100%',minHeight:'19px'}}>
                                                        <div className="container">
                                                            <div className='row'>
                                                                <div className='col-12 col-md-6'>
                                                                    <h6 className='mt-3'>Total Tabungan:</h6>
                                                                    <h4 className='fw-bold' style={{marginTop:"-10px"}}><FormatRupiah value={totalNominal}/></h4>
                                                                </div>
                                                                <div className='col-12 col-md-6'>
                                                                    <h6 className='mt-3'>Target Tabungan:</h6>
                                                                    <h4 className='fw-bold' style={{marginTop:"-10px"}}><FormatRupiah value={target}/></h4>
                                                                </div>
                                                                <div className='col-12 mb-4'>
                                                                    <h6 className='mt-3'>Progress Tabungan:</h6>
                                                                    <div className="progress" style={{ height: '23px',borderRadius:"30px" }}>
                                                                        <div className="progress-bar"role="progressbar"style={{ width: `${progress}%` }}aria-valuenow={progress}aria-valuemin="0"aria-valuemax="100">
                                                                            {progress.toFixed(2)}%
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12 mb-3'>
                                                    <div className='box p-3' style={{width: '100%',minHeight:'100px'}}>
                                                        <div className="container">
                                                            <div className='row'>
                                                                <div className='col-12 col-md-6'>
                                                                    <h6 className='mt-3'>Total Pemasukan:</h6>
                                                                    <h4 className='fw-bold' style={{marginTop:"-10px"}}><FormatRupiah value={uangMasuk}/></h4>
                                                                </div>
                                                                <div className='col-12 col-md-6'>
                                                                    <h6 className='mt-3'>Total Pengeluaran:</h6>
                                                                    <h4 className='fw-bold' style={{marginTop:"-10px"}}><FormatRupiah value={uangKeluar}/></h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12 col-lg-6 mt-2'>
                                            <div className='box py-2 px-0' style={{overflow:"auto",width: '100%',minHeight:'418px',maxHeight:'518px'}}>
                                                <h6 className='text-center my-2 fw-bold'>Rincian Tabungan</h6>
                                                <table style={{width:"1000px"}}>
                                                    <thead>
                                                        <tr style={{borderBottom:'2px solid #f5f5f5',borderTop:'2px solid #f5f5f5'}}>
                                                            <td className='fw-bold py-2' style={{color:'rgb(108, 108, 108)'}}>No.</td>
                                                            <td className='fw-bold py-2' style={{color:'rgb(108, 108, 108)'}}>Nominal</td>
                                                            <td className='fw-bold py-2' style={{color:'rgb(108, 108, 108)'}}>Keterangan</td>
                                                            <td className='fw-bold py-2' style={{color:'rgb(108, 108, 108)'}}>Uang Masuk / Keluar</td>
                                                            <td className='fw-bold py-2' style={{color:'rgb(108, 108, 108)'}}>Jenis Tabungan</td>
                                                            <td className='fw-bold py-2' style={{color:'rgb(108, 108, 108)'}}>Waktu</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataWeNabungByJenisTabungan.filter(user => user.nominal !== 0).map((user,index)=>(
                                                            <tr key={dataWeNabungByJenisTabungan.id} style={{borderBottom:'2px solid #f5f5f5'}}>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{index + 1}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}><FormatRupiah value={user.nominal}/></td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.keterangan}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.uangMasukKeluar}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.jenisTabungan}</td>
                                                                <td className='p-2 fw-semibold text-center' style={{textAlign:'left',fontSize:'10pt',color:'rgb(108, 108, 108)'}}>{user.createdAt}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :<div className="container-fluid">
                                    <div className="row mt-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>We Nabung</h4>
                                            <p className='text-center fw-semibold'>Catat Keuanganmu Dan Lihat Progress Nabungmu Dengan We Nabung</p>
                                        </div>
                                    </div>
                                    <div className='row mb-5 pb-1'>
                                        <div className='col-12'>
                                            <div className='row justify-content-center'>
                                                <div className='col-12 mb-4'>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-md-6 col-lg-4">
                                                            <div className='box p-3 mb-4'>
                                                                <button type='button' className='btn btn-webew-product' data-bs-toggle='modal' data-bs-target='#staticBackdropJenisTabungan' style={{marginTop:"-2px"}}><i className="bi bi-plus-circle"></i> Tambah Jenis Tabungan</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row justify-content-center">
                                                        <div className="col-12 col-lg-10">
                                                            <div className="box px-3 pt-3 pb-2">
                                                                <div className="container">
                                                                    <h5 className='text-center mb-3' style={{fontWeight:'700'}}>Tabungan Kamu</h5>
                                                                    <div className='row justify-content-center'>
                                                                        {dataWeNabung.map((user,index)=>(
                                                                            <div key={dataWeNabung.id} className='col-6 col-md-4 mb-4'>
                                                                                <button className="btn btn-webew-product box p-3 w-100 fw-semibold" onClick={()=> handleClickNext(user.jenisTabungan)} type="button" style={{height:"auto",color:"white"}}><i className="bi bi-cash-coin" style={{fontSize:"30px",color:"white"}}></i><br/>{user.jenisTabungan}</button>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                }
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAP */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE DASHBOARD
// EKSPOR
export default MenuWeNabung