// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE PERNIKAHAN
const MenuWePernikahan = () => { // KURUNG BUKA MENU WE PERNIKAHAN
    // MEMBUAT VARIABEL WE PERNIKAHAN
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-pernikahan", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = [
        { createLink: `/menu-we-pernikahan-form/${panggilan}/aglaonema`, viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/agapanthus`, viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/alamanda`, viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/alamanda.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/amaryllis`, viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwI`, viewLink: '/we-pernikahan/bnwI', nama: 'Bnw I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw1.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwII`, viewLink: '/we-pernikahan/bnwII', nama: 'Bnw II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw2.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwIII`, viewLink: '/we-pernikahan/bnwIII', nama: 'Bnw III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw3.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bnwIV`, viewLink: '/we-pernikahan/bnwIV', nama: 'Bnw IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw4.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blegold`, viewLink: '/we-pernikahan/blegold', nama: 'BleGold', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blegold.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blepink`, viewLink: '/we-pernikahan/blepink', nama: 'BlePink', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blepink.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/bletosca`, viewLink: '/we-pernikahan/bletosca', nama: 'BleTosca', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bletosca.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/blewhite`, viewLink: '/we-pernikahan/blewhite', nama: 'BleWhite', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blewhite.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/cute`, viewLink: '/we-pernikahan/cute', nama: 'Cute', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cute.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/adorable`, viewLink: '/we-pernikahan/adorable', nama: 'Adorable', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/adorable.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/beauty`, viewLink: '/we-pernikahan/beauty', nama: 'Beauty', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/beauty.jpg', alt: 'Undangan Digital Pernikahan' },
        { createLink: `/menu-we-pernikahan-form/${panggilan}/cool`, viewLink: '/we-pernikahan/cool', nama: 'Cool', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cool.jpg', alt: 'Undangan Digital Pernikahan' }
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center fw-bold'>We Pernikahan</h4>
                                            <p className='text-center fw-semibold'>Buat Undangan Pernikahan Digital Dengan We Pernikahan</p>
                                        </div>
                                    </div>
                                    <div className="row mb-5 pb-1">
                                    {jenisWePernikahan.map((data, index) => (
                                            <div key={index.id} className='col-6 col-lg-4 col-xl-3 mb-4' id='pernikahan'>
                                                <div className='card' style={{ width: '100%' }}>
                                                    <h5 className='card-title text-center mt-2 fw-bold'>{data.nama}</h5>
                                                    <img src={data.imgSrc} className='mx-auto d-block mt-1' style={{ width: '125px', minHeight: '175px' }} alt={data.alt} />
                                                    <div className='card-body'>
                                                        <p className='card-text-harga'>Harga Saat Ini</p>
                                                        <p className='card-text-rupiah'>Rp 45.000</p>
                                                        <div className='row'>
                                                            <div className='col-12 col-sm-6'>
                                                                <a href={`${data.viewLink}`} className='btn btn-webew-product mt-1 mb-3' aria-label='Produk webew.id'>Lihat</a>
                                                            </div>
                                                            <div className='col-12 col-sm-6'>
                                                                <a href={`${data.createLink}`} className='btn btn-webew-product mt-1 mb-1' aria-label='Produk webew.id'>Bikin</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN
// EKSPOR
export default MenuWePernikahan