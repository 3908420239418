// IMPORT REACT
import React,{ useRef, useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate } from "react-router-dom"
// 
const MenuWePernikahanFormMandiri = () => { // KURUNG BUKA MENU WE PERNIKAHAN FORM
    // MEMBUAT VARIABEL MENU WE PERNIKAHAN FORM
    const { panggilanParams } = useParams();
    const { jenisWePernikahanParams } = useParams();
    const [displayLoader, setDisplayLoader] = useState("none")
    const [msg, setMsg] = useState("")

    const [btnColorCD, setBtnColorCD] = useState('')
    const [btnColor, setBtnColor] = useState('')
    const [fntColor, setFntColor] = useState('')
    const [borderBtn, setBorderBtn] = useState('')
    const [borderBoxLeft, setBorderBoxLeft] = useState('none')
    const [borderBoxRight, setBorderBoxRight] = useState('none')
    const [bgCover, setBgCover] = useState('')
    const [bgImageCover, setBgImageCover] = useState('')
    const [bgImageCover_1, setBgImageCover_1] = useState('')
    const [bgImageTop, ] = useState('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg')
    const [bgColor, setBgColor] = useState('')
    const [bgImageBox1, setBgImageBox1] = useState('')

    const [imageGallery1_1, setImageGallery1_1] = useState('')
    const [imageGallery2_1, setImageGallery2_1] = useState('')
    const [imageGallery3_1, setImageGallery3_1] = useState('')
    const [imageGallery4_1, setImageGallery4_1] = useState('')
    const [imageGallery5_1, setImageGallery5_1] = useState('')
    const [imageGallery6_1, setImageGallery6_1] = useState('')
    
    const [imageGallery1, setImageGallery1] = useState('')
    const [imageGallery2, setImageGallery2] = useState('')
    const [imageGallery3, setImageGallery3] = useState('')
    const [imageGallery4, setImageGallery4] = useState('')
    const [imageGallery5, setImageGallery5] = useState('')
    const [imageGallery6, setImageGallery6] = useState('')

    const [imagePengantin1_1, setImagePengantin1_1] = useState('')
    const [imagePengantin2_1, setImagePengantin2_1] = useState('')
    const [imagePengantin1, setImagePengantin1] = useState('')
    const [imagePengantin2, setImagePengantin2] = useState('')
    const [namaPanggilanPengantin1, setNamaPanggilanPengantin1] = useState('')
    const [namaPanggilanPengantin2, setNamaPanggilanPengantin2] = useState('')
    const [namaPengantin1, setNamaPengantin1] = useState('')
    const [namaSosmedPengantin1, setNamaSosmedPengantin1] = useState('')
    const [namaPengantin2, setNamaPengantin2] = useState('')
    const [namaSosmedPengantin2, setNamaSosmedPengantin2] = useState('')
    const [kelaminPengantin1, setKelaminPengantin1] = useState('')
    const [kelaminPengantin2, setKelaminPengantin2] = useState('')
    const [urutanPengantin1, setUrutanPengantin1] = useState('')
    const [urutanPengantin2, setUrutanPengantin2] = useState('')
    const [namaBapakPengantin1, setNamaBapakPengantin1] = useState('')
    const [namaBapakPengantin2, setNamaBapakPengantin2] = useState('')
    const [namaIbuPengantin1, setNamaIbuPengantin1] = useState('')
    const [namaIbuPengantin2, setNamaIbuPengantin2] = useState('')

    const [bgColorBox2, setBgColorBox2] = useState('')

    const [hariAkad, setHariAkad] = useState('')
    const [tanggalAkad, setTanggalAkad] = useState('')
    const [jamMulaiAkad, setJamMulaiAkad] = useState('')
    const [jamAkhirAkad, setJamAkhirAkad] = useState('')
    const [hariResepsi, setHariResepsi] = useState('')
    const [tanggalResepsi, setTanggalResepsi] = useState('')
    const [jamMulaiResepsi, setJamMulaiResepsi] = useState('')
    const [jamAkhirResepsi, setJamAkhirResepsi] = useState('')
    const [zonaWaktuAkad, setZonaWaktuAkad] = useState('')
    const [zonaWaktuResepsi, setZonaWaktuResepsi] = useState('')
    const [kalenderAkad, setKalenderAkad] = useState('')
    const [kalenderResepsi, setKalenderResepsi] = useState('')
    
    const [bgImageBox2, setBgImageBox2] = useState('')
    
    const [alamatAkad, setAlamatAkad] = useState('')
    const [linkMapsAlamatAkad, setLinkMapsAlamatAkad] = useState('')
    const [alamatResepsi, setAlamatResepsi] = useState('')
    const [linkMapsAlamatResepsi, setLinkMapsAlamatResepsi] = useState('')
    
    const [ayat, setAyat] = useState('')
    const [surah, setSurah] = useState('')    
    const [isCheckedAnnisa, setIsCheckedAnnisa] = useState('')
    const [isCheckedArrum, setIsCheckedArrum] = useState('')
    const [isCheckedAnnur, setIsCheckedAnnur] = useState('')
    const [isCheckedAnnahl, setIsCheckedAnnahl] = useState('')
    const [isCheckedAlfathir, setIsCheckedAlfathir] = useState('')
    const [isCheckedAzzariyat, setIsCheckedAzzariyat] = useState('')
    
    const [namaBank1, setNamaBank1] = useState('')
    const [namaPemilikBank1, setNamaPemilikBank1] = useState('')
    const [norekBank1, setNorekBank1] = useState('')
    const [namaBank2, setNamaBank2] = useState('')
    const [namaPemilikBank2, setNamaPemilikBank2] = useState('')
    const [norekBank2, setNorekBank2] = useState('')

    const [musik, setMusik] = useState('')
    
    const [rotate, setRotate] = useState('')
    
    const [pemilik1, setPemilik1] = useState('')
    const [link1, setLink1] = useState('')
    const [pemilik2, setPemilik2] = useState('')
    const [link2, setLink2] = useState('')
    const [pemilik3, setPemilik3] = useState('')
    const [link3, setLink3] = useState('')
    const [link4, setLink4] = useState('')
    
    const [bukaUndangan, ] = useState('')
    const [isShow, setIsShow] = useState(true);
    
    const inputNoRek1 = useRef(null);
    const inputNoRek2 = useRef(null);

    const targetDate = `${tanggalAkad}T${jamMulaiAkad.replace(/^(\d{2}).*$/, '$1')}:00:00`;
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);

    // EVENT COUNTDOWN
    function calculateTimeLeft() {
        const difference = new Date(targetDate) - new Date();
        if (difference > 0) {
          return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / (1000 * 60)) % 60),
            seconds: Math.floor((difference / 1000) % 60),
          };
        }
        return null;
    }
    // Update waktu setiap detik
    useEffect(() => {
    const timer = setInterval(() => {
        const newTimeLeft = calculateTimeLeft();
        if (newTimeLeft) {
            setTimeLeft(newTimeLeft);
        } else {
            clearInterval(timer);
        }
    }, 1000);
    // Membersihkan interval saat komponen dilepas
    return () => clearInterval(timer);
    }, [targetDate]);
    // USEEFFECT
    useEffect(()=>{
        const tanggalAwalAkad = tanggalAkad + "T" + jamMulaiAkad + "00"
        const tanggalAkhirAkad = tanggalAkad + "T" + jamAkhirAkad + "00"
        setKalenderAkad(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${namaPanggilanPengantin1} dan ${namaPanggilanPengantin2}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Akad) kami ${namaPanggilanPengantin1} dan ${namaPanggilanPengantin2} pada hari ${hariAkad}, ${tanggalAkad}, dari jam ${jamMulaiAkad} s/d ${jamAkhirAkad}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalAkad}/${tanggalAkhirAkad}&location=Indonesia`)
        const tanggalAwalResepsi = tanggalResepsi + "T" + jamMulaiResepsi + "00"
        const tanggalAkhirResepsi = tanggalResepsi + "T" + jamAkhirResepsi + "00"
        setKalenderResepsi(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Pernikahan ${namaPanggilanPengantin1} dan ${namaPanggilanPengantin2}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami yang berbahagia ini yaitu pernikahan (Resepsi) kami ${namaPanggilanPengantin1} dan ${namaPanggilanPengantin2} pada hari ${hariResepsi}, ${tanggalResepsi}, dari jam ${jamMulaiResepsi} s/d ${jamAkhirResepsi}. Ditunggu kehadirannya yaaa&dates=${tanggalAwalResepsi}/${tanggalAkhirResepsi}&location=Indonesia`)
        if(jenisWePernikahanParams === "agapanthus"){
            setBtnColorCD("#0081b0")
            setBtnColor("#f3fbff")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #0081b0")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/8845952_4006891.png")
            // Box 2
            setBgColorBox2("#4fd0ff15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/14309031_5470466.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/summer-pattern-background-zoom_8845952.htm#page=5&query=purple%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/gradient-tropical-leaves-background_14309031.htm#page=5&query=purple%20leaf%20background&position=46&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "aglaonema"){
            setBtnColorCD("green")
            setBtnColor("#f4fff3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid green")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#fafff82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/13900114_5388788.png")
            // Box 2
            setBgColorBox2("#5eff4f15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/13991173_5386317.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/hand-painted-watercolor-tropical-leaves-background_13900114.htm#query=tropical%20background&position=3&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/realistic-tropical-leaves-background_13991173.htm#page=2&query=tropical&position=3&from_view=search&track=sph")
            setPemilik2("pikisuperstar")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "alamanda"){
            setBtnColorCD("#b05500")
            setBtnColor("#fffaf3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #b05500")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#fffcf82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/9260611_4132255.png")
            // Box 2
            setBgColorBox2("#ffa44f15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/5113181_2638116.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/realistic-autumn-background_9260611.htm#page=6&query=yellow%20leaf&position=16&from_view=search&track=ais")
            setPemilik1("pikisuperstar")
            setLink2("https://www.freepik.com/free-vector/flat-autumn-forest-leaves-background_5113181.htm#page=3&query=yellow%20leaf%20background&position=20&from_view=search&track=ais")
            setPemilik2("freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "amaryllis"){
            setBtnColorCD("#b00000")
            setBtnColor("#fff3f3")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid #b00000")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#fff8f82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flat-lay-maple-autumn-leaves-border.png")
            // Box 2
            setBgColorBox2("#ff4f4f15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/5020958_2649322.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5045257.htm#page=3&query=read%20leaf%20background&position=14&from_view=search&track=ais")
            setPemilik1("freepik")
            setLink2("https://www.freepik.com/free-vector/autumn-leaves-background-flat-style_5020958.htm#page=7&query=red%20leaf%20background&position=34&from_view=search&track=ais")
            setPemilik2("freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "adorable"){
            setBtnColorCD("#00b07e")
            setBtnColor("#f3fff8")
            setFntColor("rgb(0, 58, 41)")
            setBorderBtn("3px solid #00b07e")
            setBgCover("linear-gradient(to bottom,rgb(221, 255, 237),rgb(221, 255, 237)")
            // Cover Top
            setBgColor("#f8fffd2f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#4fffc115")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "cute"){
            setBtnColorCD("rgb(176, 0, 135)")
            setBtnColor("#fff3fe")
            setFntColor("rgb(61, 0, 47)")
            setBorderBtn("3px solid rgb(176, 0, 135)")
            setBgCover("linear-gradient(to bottom, #f1c9fe,rgb(245, 214, 255)")
            // Cover Top
            setBgColor("#fff8fc2f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#ff4fb915")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "beauty"){
            setBtnColorCD("rgb(176, 59, 0)")
            setBtnColor("#fff7f3")
            setFntColor("rgb(68, 26, 0)")
            setBorderBtn("3px solid rgb(176, 59, 0)")
            setBgCover("linear-gradient(to bottom, #fed5c9,rgb(255, 225, 215)")
            // Cover Top
            setBgColor("#fffbf82f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#ffa44f15")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "cool"){
            setBtnColorCD("rgb(0, 147, 176)")
            setBtnColor("#f3fcff")
            setFntColor("rgb(0, 46, 55)")
            setBorderBtn("3px solid rgb(0, 147, 176)")
            setBgCover("linear-gradient(to bottom, rgb(212, 240, 255),rgb(212, 240, 255)")
            // Cover Top
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("")
            // Box 2
            setBgColorBox2("#4fdcff15")
            setBgImageBox2("")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "blegold"){
            setBtnColorCD("rgb(176, 135, 0)")
            setBtnColor("#392f00")
            setFntColor("rgb(255, 204, 0)")
            setBorderBtn("3px solid rgb(176, 135, 0)")
            setBorderBoxLeft("3px solid rgb(255, 204, 0)")
            setBorderBoxRight("3px solid rgb(255, 204, 0)")
            setBgCover("linear-gradient(to bottom,rgb(77, 67, 0),rgb(0, 0, 0)")
            // Cover Top
            setBgColor("#fffef82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2G.png")
            // Box 2
            setBgColorBox2("#ffed4f15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5G.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "blepink"){
            setBtnColorCD("rgb(255, 44, 209)")
            setBtnColor("rgb(67, 0, 62)")
            setFntColor("rgb(255, 169, 249)")
            setBorderBtn("3px solid rgb(255, 190, 237)")
            setBorderBoxLeft("3px solid rgb(255, 190, 237)")
            setBorderBoxRight("3px solid rgb(255, 190, 237)")
            setBgCover("linear-gradient(to bottom,rgb(74, 0, 77),rgb(0, 0, 0)")
            // Cover Top
            setBgColor("#fff8fd2f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2P.png")
            // Box 2
            setBgColorBox2("#ff4fd015")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5P.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bletosca"){
            setBtnColorCD("rgb(0, 251, 255)")
            setBtnColor("#004748")
            setFntColor("rgb(169, 254, 255)")
            setBorderBtn("3px solid rgb(0, 251, 255)")
            setBorderBoxLeft("3px solid rgb(0, 247, 255)")
            setBorderBoxRight("3px solid rgb(0, 247, 255)")
            setBgCover("linear-gradient(to bottom,rgb(0, 76, 77),rgb(0, 0, 0)")
            // Cover Top
            setBgColor("#f8ffff2f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2T.png")
            // Box 2
            setBgColorBox2("#4ffcff15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5T.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "blewhite"){
            setBtnColorCD("rgb(255, 255, 255)")
            setBtnColor("rgb(121, 121, 121)")
            setFntColor("rgb(255, 255, 255)")
            setBorderBtn("3px solid rgb(255, 255, 255)")
            setBorderBoxLeft("3px solid rgb(255, 255, 255)")
            setBorderBoxRight("3px solid rgb(255, 255, 255)")
            setBgCover("linear-gradient(to bottom,rgb(121, 121, 121),rgb(0, 0, 0)")
            // Cover Top
            setBgColor("#ffffff2f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b2W.png")
            // Box 2
            setBgColorBox2("#ffffff15")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bingkai/b5W.png")
            // Link Foto & Lagu
            setLink1("https://www.freepik.com/free-vector/vector-vintage-page-decor-with-crowns-arrows-floral-elements_10700704.htm#query=border&position=23&from_view=search&track=sph")
            setPemilik1("macrovector")
            setLink2("https://webew.id")
            setPemilik2("Webew.id")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnw1"){
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb1.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb2.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/elegant-wedding-decorative-floral-background_9853423.htm#query=black%20and%20white%20flower%20background&position=10&from_view=search&track=ais")
            setPemilik2("Harryarts")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnw2"){
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb5.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb4.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/beautiful-hand-drawn-wedding-ornament-floral-background_9853397.htm#page=3&query=black%20and%20white%20flower%20background&position=3&from_view=search&track=ais")
            setPemilik2("Harryarts")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnw3"){
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb8.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb3.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/hand-drawn-spring-background_12806202.htm#query=black%20and%20white%20flower%20background&position=38&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }else if(jenisWePernikahanParams === "bnw4"){
            setBtnColorCD("rgb(108, 108, 108)")
            setBtnColor("white")
            setFntColor("rgb(108, 108, 108)")
            setBorderBtn("3px solid rgb(108, 108, 108)")
            setBgCover("url('http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/bg-min.webp')")
            // Cover Top
            setBgColor("#e8e8e82f")
            // Box 1
            setBgImageBox1("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb10.png")
            // Box 2
            setBgColorBox2("#43434315")
            setBgImageBox2("http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/flowerBnw/fb11.png")
            // Link Foto & Lagu
            setLink1("https://webew.id")
            setPemilik1("Webew.id")
            setLink2("https://www.freepik.com/free-vector/floral-background-with-sketchy-style_2416402.htm#page=5&query=black%20and%20white%20flower%20background&position=13&from_view=search&track=ais")
            setPemilik2("Freepik")
            setLink3("https://www.freepik.com/free-photo/bride-groom-having-their-wedding-beach_16515421.htm")
            setPemilik3("Freepik")
            
            if(musik === "All Of Me - John Legend"){
                setLink4("https://youtu.be/450p7goxZqg?si=m-dm3xFpXkfvfDoe")
            }else if(musik === "A Thousand Years - Christina Perri"){
                setLink4("https://youtu.be/rtOvBOTyX00?si=qS0OyTA0NulVqMbY")
            }else if(musik === "Beautiful In White - Shane Filan"){
                setLink4("https://youtu.be/06-XXOTP3Gc?si=o8PFNv1MZx6PcIwx")
            }else if(musik === "Because You Loved Me - Celine Dion"){
                setLink4("https://youtu.be/fpl4if07ics?si=1ztr2kvh4Kco0pcb")
            }else if(musik === "Cant Help Falling In Love - Elvis Presley"){
                setLink4("https://youtu.be/vGJTaP6anOU?si=X2gRgT9kuIPh1oU6")
            }else if(musik === "Cant Help Falling In Love - Alexandra Porat Cover"){
                setLink4("https://youtu.be/Zsdrqbl-gWw?si=spDaAaxfUE4qy9Fk")
            }else if(musik === "Close To You - Carpenters"){
                setLink4("https://youtu.be/HYnV_pkO-Rw?si=n6Beo4tZPzGc2UZB")
            }else if(musik === "Endless Love - Luther Vandross"){
                setLink4("https://youtu.be/nScV1qu-MZQ?si=rqC8fBzKQS54XrQG")
            }else if(musik === "Everything I Do - Bryan Adams"){
                setLink4("https://youtu.be/aQh8Bi3aEm8?si=4crXE11gWPp0m614")
            }else if(musik === "I Love You - Celine Dion"){
                setLink4("https://youtu.be/JAeUm1kJrlI?si=rExJy0WhpPmKTIYU")
            }else if(musik === "I Wanna Grow Old With You - Westlife"){
                setLink4("https://youtu.be/1WCIrw85zbQ?si=BXlIOdQ1ABsY5cmN")
            }else if(musik === "Lucky - Jason Mraz"){
                setLink4("https://youtu.be/wbPM93Oz67A?si=ClNXmBvp0SOhOJ87")
            }else if(musik === "Make You Feel My Love - Adele"){
                setLink4("https://youtu.be/9IzKueQ2ZxY?si=Me-67Yk05cWM1bYi")
            }else if(musik === "Marry You - Bruno Mars"){
                setLink4("https://youtu.be/dElRVQFqj-k?si=G2PLVk5UQaI7-9Yf")
            }else if(musik === "My Love - Westlife"){
                setLink4("https://youtu.be/ulOb9gIGGd0?si=1u3b_KRwdvAGpvAK")
            }else if(musik === "Perfect - Ed Sheeran"){
                setLink4("https://youtu.be/2Vv-BfVoq4g?si=tzNZXzz5BR4ht7ro")
            }else if(musik === "Since I Found You - Christian Bautista"){
                setLink4("https://youtu.be/cFJ7B1wNIFg?si=pPD9LVh0AXSAboh-")
            }else if(musik === "The Power Of Love - Celine Dion"){
                setLink4("https://youtu.be/Y8HOfcYWZoo?si=iaFIAENw0yNwdeM4")
            }else if(musik === "The Way You Look Me - Christian Bautista"){
                setLink4("https://youtu.be/9_9ADpx5FHE?si=_0__lJP8HLOV2OaB")
            }else if(musik === "Thinking Out Loud - Ed Sheeran"){
                setLink4("https://youtu.be/lp-EO5I60KA?si=ht5ngvrqkZQLmzAI")
            }else if(musik === "When You Tell Me That You Love Me - Westlife Ft Diana Ross"){
                setLink4("https://youtu.be/MwSL-57pNqM?si=F7YP9GslUHH8-f1E")
            }else if(musik === "You Are The Reason - Calum Scott"){
                setLink4("https://youtu.be/ShZ978fBl6Y?si=a8c0sDaYsVq9I6gE")
            }else if(musik === "Akad - Payung Teduh"){
                setLink4("https://youtu.be/BRPaF-O-3rE?si=MWSPLddYLfmSjOtX")
            }else if(musik === "Akhirnya Kumenemukanmu - Naff"){
                setLink4("https://youtu.be/2d2TW4fKFG4?si=rmycJYHWJ4a8EIf-")
            }else if(musik === "Aku Dan Dirimu - Arilaso Ft BCL"){
                setLink4("https://youtu.be/cxnCppV3eXk?si=gp2RTPA6If4XzeA9")
            }else if(musik === "Aku Makin Cinta - Vina Panduwinata"){
                setLink4("https://youtu.be/TH-PFEwktns?si=aP4XdUPVgUaUK3DK")
            }else if(musik === "Berawal Dari Tatap - Yura Yunita"){
                setLink4("https://youtu.be/Yx6TFSQQF1Y?si=PyIo5q-AhJny4Aub")
            }else if(musik === "Berdua Saja - Payung Teduh"){
                setLink4("https://youtu.be/Tct7qR2bhHA?si=frS324dylx1mJfzz")
            }else if(musik === "Bukan Cinta Biasa - Afgan"){
                setLink4("https://youtu.be/nshdkoeu_fY?si=JiIFrYfMi29lGfax")
            }else if(musik === "Cinta luar Biasa - Andmesh Kamaleng"){
                setLink4("https://youtu.be/FcOctsNXyjk?si=iqd0zisjV5Ub4F7s")
            }else if(musik === "Cinta Pertama Dan Terakhir - Sherina"){
                setLink4("https://youtu.be/DRY81pGMABc?si=FyqTpgd1WRL2bWRz")
            }else if(musik === "Cinta Sejati - BCL"){
                setLink4("https://youtu.be/Bgt9g1q-xj0?si=Ps1kOa9mTzOLpNIF")
            }else if(musik === "Cinta Terakhir - Arilaso"){
                setLink4("https://youtu.be/koZK67e1C-U?si=vBG4DR5XcmbeHN64")
            }else if(musik === "Jani Suci - Yovie & Nuno"){
                setLink4("https://youtu.be/hQ-wYw4hP84?si=e5LO-hqLEL1G3cVR")
            }else if(musik === "Kamulah Takdirku - Raffi Ahmad & Nagita Slavina"){
                setLink4("https://youtu.be/gGySiXyIzTU?si=NXIWBiCmEMWEbDeA")
            }else if(musik === "Karena Cinta - Glenn Fredly"){
                setLink4("https://youtu.be/rTiJw9V4F84?si=te9u84KkYypTVdCr")
            }else if(musik === "Kasih Putih - Glenn Fredly"){
                setLink4("https://youtu.be/CVEPAN2eLYg?si=BYHt4v5I6nHmuLsq")
            }else if(musik === "Kesempurnaan Cinta - Rizky Febian"){
                setLink4("https://youtu.be/XyHhr2XbaGc?si=ZImyb3S1yww91k0a")
            }else if(musik === "Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto"){
                setLink4("https://youtu.be/ZeFpigRaXbI?si=H5P-46i5AyOXdd-c")
            }else if(musik === "Malaikat Juga Tahu - Glenn Fredly"){
                setLink4("https://youtu.be/kpibQ_IdSYM?si=wyPAJjcQBauF4yo8")
            }else if(musik === "Menikahimu - Kahitna"){
                setLink4("https://youtu.be/XCasOuavjLc?si=q2TZIfQlGPJ49l3D")
            }else if(musik === "Sedalam Cinta - Indra Lesmana Ft Nania"){
                setLink4("https://youtu.be/jhcmraki6OU?si=V-lGmSPzcsYGdfTW")
            }else if(musik === "Teman Hidup - Tulus"){
                setLink4("https://youtu.be/dt4Ueda_h6Y?si=wTgtknrxUOMUixdF")
            }else if(musik === "Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee"){
                setLink4("https://youtu.be/cCw1fH1IwTM?si=otstCP969pnWt1RQ")
            }
            setRotate("rotate(-180deg)")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // ACAK HURUF DAN ANGKA
    const generateRandomString = (length) => {
        const characters = `${namaPengantin1+namaPengantin2+hariAkad+jenisWePernikahanParams+1234567890}`;
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
    };
    // PROSES MENGAMBIL FILE FOTO DARI FORM
    function handleUploadChangeLatar(e){
        let uploaded = e.target.files[0]
        setBgImageCover_1(uploaded)
        setBgImageCover(URL.createObjectURL(uploaded))
    }
    function handleUploadChangePengantin1(e){
        let uploaded = e.target.files[0]
        setImagePengantin1_1(uploaded)
        setImagePengantin1(URL.createObjectURL(uploaded))
    }
    function handleUploadChangePengantin2(e){
        let uploaded = e.target.files[0]
        setImagePengantin2_1(uploaded)
        setImagePengantin2(URL.createObjectURL(uploaded))
    }
    function handleUploadChangeGallery1(e){
        let uploaded = e.target.files[0]
        setImageGallery1_1(uploaded)
        setImageGallery1(URL.createObjectURL(uploaded))
    }
    function handleUploadChangeGallery2(e){
        let uploaded = e.target.files[0]
        setImageGallery2_1(uploaded)
        setImageGallery2(URL.createObjectURL(uploaded))
    }
    function handleUploadChangeGallery3(e){
        let uploaded = e.target.files[0]
        setImageGallery3_1(uploaded)
        setImageGallery3(URL.createObjectURL(uploaded))
    }
    function handleUploadChangeGallery4(e){
        let uploaded = e.target.files[0]
        setImageGallery4_1(uploaded)
        setImageGallery4(URL.createObjectURL(uploaded))
    }
    function handleUploadChangeGallery5(e){
        let uploaded = e.target.files[0]
        setImageGallery5_1(uploaded)
        setImageGallery5(URL.createObjectURL(uploaded))
    }
    function handleUploadChangeGallery6(e){
        let uploaded = e.target.files[0]
        setImageGallery6_1(uploaded)
        setImageGallery6(URL.createObjectURL(uploaded))
    }
    // PROSES MENYIMPAN WE PRENIKAHAN KE DB
    const createWePernikahan = async (e) => {
        setIsShow(false)

        const randomString = generateRandomString(100)

        e.preventDefault()
        const formData = new FormData()

        formData.append("bgImageCover", bgImageCover_1)
        formData.append("imagePengantin1", imagePengantin1_1)
        formData.append("imagePengantin2", imagePengantin2_1)
        formData.append("imageGallery1", imageGallery1_1)
        formData.append("imageGallery2", imageGallery2_1)
        formData.append("imageGallery3", imageGallery3_1)
        formData.append("imageGallery4", imageGallery4_1)
        formData.append("imageGallery5", imageGallery5_1)
        formData.append("imageGallery6", imageGallery6_1)

        formData.append("namaPanggilanPengantin1", namaPanggilanPengantin1)
        formData.append("namaPanggilanPengantin2", namaPanggilanPengantin2)
        formData.append("namaPengantin1", namaPengantin1)
        formData.append("namaSosmedPengantin1", namaSosmedPengantin1)
        formData.append("namaPengantin2", namaPengantin2)
        formData.append("namaSosmedPengantin2", namaSosmedPengantin2)
        formData.append("kelaminPengantin1", kelaminPengantin1)
        formData.append("kelaminPengantin2", kelaminPengantin2)
        formData.append("urutanPengantin1", urutanPengantin1)
        formData.append("urutanPengantin2", urutanPengantin2)
        formData.append("namaBapakPengantin1", namaBapakPengantin1)
        formData.append("namaBapakPengantin2", namaBapakPengantin2)
        formData.append("namaIbuPengantin1", namaIbuPengantin1)
        formData.append("namaIbuPengantin2", namaIbuPengantin2)

        formData.append("hariAkad", hariAkad)
        formData.append("tanggalAkad", tanggalAkad)
        formData.append("jamMulaiAkad", jamMulaiAkad)
        formData.append("jamAkhirAkad", jamAkhirAkad)
        formData.append("zonaWaktuAkad", zonaWaktuAkad)
        formData.append("hariResepsi", hariResepsi)
        formData.append("tanggalResepsi", tanggalResepsi)
        formData.append("jamMulaiResepsi", jamMulaiResepsi)
        formData.append("jamAkhirResepsi", jamAkhirResepsi)
        formData.append("zonaWaktuResepsi", zonaWaktuResepsi)

        formData.append("alamatAkad", alamatAkad)
        formData.append("linkMapsAlamatAkad", linkMapsAlamatAkad)
        formData.append("alamatResepsi", alamatResepsi)
        formData.append("linkMapsAlamatResepsi", linkMapsAlamatResepsi)

        formData.append("ayat", ayat)
        formData.append("surah", surah)

        formData.append("namaBank1", namaBank1)
        formData.append("namaPemilikBank1", namaPemilikBank1)
        formData.append("norekBank1", norekBank1)
        formData.append("namaBank2", namaBank2)
        formData.append("namaPemilikBank2", namaPemilikBank2)
        formData.append("norekBank2", norekBank2)

        formData.append("musik", musik)

        formData.append("jenisUndanganDigitalPernikahan", jenisWePernikahanParams)
        formData.append("panggilan", "ADMIN"+randomString)
        try{
            setDisplayLoader("")
            await axios.patch("http://localhost:5000/createWePernikahanBE", formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
            navigate(`/menu-we-pernikahan-hasil-mandiri/ADMIN${randomString}/${jenisWePernikahanParams}`, { replace: true })
        } catch(error){
            if(error.response){
                setDisplayLoader("none")
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES INPUT AYAT SUCI ANNISA
    const inputAyatSuciAnnisa = async() => {
        setIsCheckedAnnisa(!isCheckedAnnisa)
        isCheckedAnnisa ? setAyat("") : setAyat("“Hai manusia, bertakwalah kepada Tuhan-mu Yang menciptakan kamu dari satu jiwa, dan darinya Dia menciptakan jodohnya, dan mengembang-biakan dari keduanya banyak laki-laki dan perempuan; dan bertakwalah kepada Allah swt. yang dengan nama-Nya kamu saling bertanya, terutama mengenai hubungan tali kekerabatan. Sesungguhnya Allah swt. adalah pengawas atas kamu.”")
        isCheckedAnnisa ? setSurah("") : setSurah("QS An Nisa Ayat 1")
        isCheckedAnnisa ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAnnisa ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAnnisa ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAnnisa ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedAnnisa ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ARRUM
    const inputAyatSuciArrum = async() => {
        setIsCheckedArrum(!isCheckedArrum)
        isCheckedArrum ? setAyat("") : setAyat("“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”")
        isCheckedArrum ? setSurah("") : setSurah("QS Ar Rum Ayat 21")
        isCheckedArrum ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedArrum ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedArrum ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedArrum ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedArrum ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ANNUR
    const inputAyatSuciAnnur = async() => {
        setIsCheckedAnnur(!isCheckedAnnur)
        isCheckedAnnur ? setAyat("") : setAyat("“Dan nikahkanlah orang-orang yang masih membujang di antara kamu, dan juga orang-orang yang layak (menikah) dari hamba-hamba sahayamu yang laki-laki dan perempuan. Jika mereka miskin, Allah akan memberi kemampuan kepada mereka dengan karunia-Nya. Dan Allah Mahaluas (pemberian-Nya) lagi Maha Mengetahui.”")
        isCheckedAnnur ? setSurah("") : setSurah("QS An Nur Ayat 32")
        isCheckedAnnur ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAnnur ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAnnur ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAnnur ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedAnnur ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ANNAHL
    const inputAyatSuciAnnahl = async() => {
        setIsCheckedAnnahl(!isCheckedAnnahl)
        isCheckedAnnahl ? setAyat("") : setAyat("“Dan Allah menjadikan bagimu pasangan (suami atau istri) dari jenis kamu sendiri, menjadikan anak dan cucu bagimu dari pasanganmu, serta memberimu rezeki dari yang baik-baik. Mengapa mereka beriman kepada yang batil dan mengingkari nikmat Allah?”")
        isCheckedAnnahl ? setSurah("") : setSurah("QS An Nahl Ayat 72")
        isCheckedAnnahl ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAnnahl ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAnnahl ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAnnahl ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
        isCheckedAnnahl ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI ALFATHIR
    const inputAyatSuciAlfathir = async() => {
        setIsCheckedAlfathir(!isCheckedAlfathir)
        isCheckedAlfathir ? setAyat("") : setAyat("“Dan Allah menciptakan kamu dari tanah kemudian dari air mani, kemudian Dia menjadikan kamu berpasang-pasangan (laki-laki dan perempuan). Tidak ada seorang perempuan pun yang mengandung dan melahirkan melainkan dengan sepengetahuan-Nya. Dan tidak dipanjangkan umur seseorang dan tidak pula dikurangi umurnya, melainkan (sudah ditetapkan) dalam Kitab (Lauh Mahfuzh). Sesungguhnya yang demikian itu mudah bagi Allah.”")
        isCheckedAlfathir ? setSurah("") : setSurah("QS Al Fathir Ayat 11")
        isCheckedAlfathir ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAlfathir ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAlfathir ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAlfathir ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAlfathir ? setIsCheckedAzzariyat("") : setIsCheckedAzzariyat("")
    }
    // PROSES INPUT AYAT SUCI AZZARIYAT
    const inputAyatSuciAzzariyat = async() => {
        setIsCheckedAzzariyat(!isCheckedAzzariyat)
        isCheckedAzzariyat ? setAyat("") : setAyat("“Dan segala sesuatu Kami ciptakan berpasang-pasangan supaya kamu mengingat kebesaran Allah.”")
        isCheckedAzzariyat ? setSurah("") : setSurah("QS Az-Zariyat Ayat 49")
        isCheckedAzzariyat ? setIsCheckedAnnisa("") : setIsCheckedAnnisa("")
        isCheckedAzzariyat ? setIsCheckedArrum("") : setIsCheckedArrum("")
        isCheckedAzzariyat ? setIsCheckedAnnur("") : setIsCheckedAnnur("")
        isCheckedAzzariyat ? setIsCheckedAnnahl("") : setIsCheckedAnnahl("")
        isCheckedAzzariyat ? setIsCheckedAlfathir("") : setIsCheckedAlfathir("")
    }
    // PROSES SALIN REKENING 1
    function salinRek1(e) {
        inputNoRek1.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // PROSES SALIN REKENING 2
    function salinRek2(e) {
        inputNoRek2.current.select()
        document.execCommand('copy')
        e.target.focus()
    }
    // BUTTON SHOW HIDE
    const handleShow = () => {
        setIsShow(true)
    }
    const handleHide = () => {
        setIsShow(false)
    }
    // Musik
    const handlePlay = () => {
        audioRef.current.play()
        setIsPlaying(true)
    }
    const handlePause = () => {
        audioRef.current.pause()
        setIsPlaying(false)
    }
    const handlePlayPause = () => {
        if (isPlaying) {
            handlePause()
        } else {
            handlePlay()
        }
    }
    // Fungsi untuk memformat tanggal ke gaya Indonesia
    function formatDateToIndonesian(date) {
        const months = [
            "Januari", "Februari", "Maret", "April", "Mei", "Juni",
            "Juli", "Agustus", "September", "Oktober", "November", "Desember"
          ];
          if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
            return "13 Mei 2022";
          }
          const [year, month, day] = date.split("-");
          const monthName = months[parseInt(month, 10) - 1];
          return `${parseInt(day, 10)} ${monthName} ${year}`;
    };
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid mt-3">
                                    <div className="row justify-content-center mb-5 py-4">
                                        <div className="col-12 col-lg-4 mb-4">
                                            <div className="col-12">
                                                <div className="box pt-3 pb-4 px-3">
                                                    <a href={`/menu-we-pernikahan-mandiri/${panggilanParams}`} className="btn btn-webew-product" style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                                    <h3 className="title-webew-home" style={{marginTop:"10px"}}>Form We Pernikahan Mandiri</h3>
                                                    <p className="text-webew-home">Isi data kalian dibawah ini ya</p>
                                                    <a href={`/menu-we-pernikahan-hasil-mandiri/${panggilanParams}/${jenisWePernikahanParams}`} className="btn btn-webew-product mx-auto d-block">Lihat Punya Saya</a>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="box mt-4">
                                                    <img src="http://localhost:3000/img/banner.png" alt="banner webew.id" className='mx-auto d-block' style={{width:"100%",maxHeight:"35.4vh",borderRadius:"20px"}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-8">
                                            <div className="box">
                                                <form onSubmit={createWePernikahan}>
                                                    {isShow?
                                                        <div style={{position: "sticky", top: "0", zIndex:"4"}}>
                                                            <button type='button' className='py-2' style={{width:"100%",backgroundColor: "#007bff",border: "none",borderRadius: "10px 10px 0px 0px",position: "relative",display:"inline-block"}} onClick={handleHide}><h6 className='p-2 fw-bold' style={{color: "white",margin: "0"}}>Klik Untuk Melihat Hasil <i className="bi bi-eye" style={{color: "white"}}></i></h6></button>
                                                        </div>
                                                        :<div style={{position: "sticky", top: "0", zIndex:"4"}}>
                                                            <button type='button' className='py-2' style={{width:"100%",backgroundColor: "red",border: "none",borderRadius: "10px 10px 0px 0px",position: "relative",display:"inline-block"}} onClick={handleShow}><h6 className='p-2 fw-bold' style={{color: "white",margin: "0"}}>Klik Untuk Edit <i className="bi bi-pencil-square" style={{color: "white"}}></i></h6></button>
                                                        </div>
                                                    }
                                                    <section>
                                                        {/* COVER */}
                                                        <section style={{width:"100%",height:"100vh",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                            <div style={{width:"100%",height:"100vh",position:"relative"}}>
                                                                <img src={bgImageCover || bgImageTop}alt="Background yang diunggah"style={{width: "100%",height: "100%",objectFit: "cover",position:"absolute",zIndex:"1"}}/>
                                                                <div style={{background: `linear-gradient(to top, ${btnColorCD} -100%, ${bgColor}) 100%`,width:"100%",height:"100vh",position:"relative",zIndex:"2"}}>
                                                                    {isShow ? 
                                                                        <div>
                                                                            <input type="file"id="ImageCover" className="form-control form" accept="image/*" onChange={handleUploadChangeLatar}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"10px"}}/>
                                                                            <label htmlFor="ImageCover" className='fw-bold p-2 w-100 text-center' style={{position:"absolute",display: "inline-block",backgroundColor: [bgImageCover ? "green" : "red"],color: "#fff",cursor: "pointer",top:"25%",fontSize: "16px",zIndex:"3",borderRadius:"30px",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}}><i className="bi bi-image-fill"></i> Klik Untuk Ganti Foto</label>
                                                                        </div>
                                                                        :""
                                                                    }
                                                                    <div style={{width:"100%",fontFamily:"initial",bottom:"0%",marginBottom:"115px",fontVariant:"small-caps",position:"absolute",zIndex:"1",textAlign:"center",textShadow:`0 0 20px ${btnColorCD}`}}>
                                                                        <h1 className='fw-bold m-0' style={{color:"white"}}>
                                                                            {isShow?<input type="text" id="namaPanggilanPengantin1" className="form-control form" maxLength="40" value={namaPanggilanPengantin1} onChange={(e)=>setNamaPanggilanPengantin1(e.target.value)} style={{width:"100%",textAlign:"center",fontWeight:"700",color:"grey",fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} placeholder='Nama Panggilan Pengantin 1' required/>:namaPanggilanPengantin1||"Romeo"} 
                                                                            {" & "}
                                                                            {isShow?<input type="text" id="namaPanggilanPengantin1" className="form-control form" maxLength="40" value={namaPanggilanPengantin2} onChange={(e)=>setNamaPanggilanPengantin2(e.target.value)} style={{width:"100%",textAlign:"center",fontWeight:"700",color:"grey",fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} placeholder='Nama Panggilan Pengantin 2' required/>:namaPanggilanPengantin2||"Juliet"}
                                                                        </h1>
                                                                        <h3 className='fw-bold m-0' style={{color:"white"}}>{hariAkad || "Sabtu"}, {formatDateToIndonesian(tanggalAkad) || "13 Januari 2022"}</h3>
                                                                    </div>
                                                                    {timeLeft ? (
                                                                        <div className='px-1' style={{ zIndex: "1", position: "absolute", bottom: "0%", width: "100%", marginBottom:"18px" }}>
                                                                            <table style={{ textAlign: "center", width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr style={{ textAlign: "center", width: "100%" }}>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>{timeLeft.days}</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                                                        </td>
                                                                                        <td style={{ width: "2%" }}></td>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>{timeLeft.hours}</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                                                        </td>
                                                                                        <td style={{ width: "2%" }}></td>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>{timeLeft.minutes}</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                                                        </td>
                                                                                        <td style={{ width: "2%" }}></td>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>{timeLeft.seconds}</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='px-1' style={{ zIndex: "1", position: "absolute", bottom: "0%", width: "100%", marginBottom:"18px" }}>
                                                                            <table style={{ textAlign: "center", width: "100%" }}>
                                                                                <tbody>
                                                                                    <tr style={{ textAlign: "center", width: "100%" }}>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>0</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Hari</div>
                                                                                        </td>
                                                                                        <td style={{ width: "2%" }}></td>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>0</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Jam</div>
                                                                                        </td>
                                                                                        <td style={{ width: "2%" }}></td>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>0</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Menit</div>
                                                                                        </td>
                                                                                        <td style={{ width: "2%" }}></td>
                                                                                        <td className="p-3"style={{color: [fntColor],textAlign: "center",width: "23%",backgroundColor: [btnColor],borderRadius: "30px",boxShadow: `inset 0 0 0 5px ${btnColorCD}`,fontWeight: "700",fontFamily: "initial",}}>
                                                                                            <div style={{ fontSize: "20pt" }}>0</div>
                                                                                            <div style={{ fontSize: "10pt", marginTop:"-8px" }}>Detik</div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <div style={{display:bukaUndangan,position:"relative"}}>
                                                            {/* BOX 1 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                                                                <h1 className='text-center fw-bold' style={{color:[fntColor],fontFamily:"initial"}}>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h1>
                                                                <h6 className='text-center fw-normal my-4' style={{color:[fntColor],fontFamily:"initial"}}>Dengan memohon rahmat dan ridha Allah SWT, insyaAllah kami akan menyelenggarakan acara pernikahan</h6>
                                                                <table>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td style={{width:"45%"}}>
                                                                                {isShow?
                                                                                    <div>
                                                                                        <input type="file"id="ImagePengantin1" className="form-control form" accept="image/*" onChange={handleUploadChangePengantin1}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                        <label htmlFor="ImagePengantin1" className='fw-bold p-2' style={{backgroundColor: [imagePengantin1 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"0px 40px 40px 0px",boxShadow:`10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Pengantin 1 <i className="bi bi-image-fill"></i></label>
                                                                                    </div>
                                                                                    :<img src={imagePengantin1 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg"} className='img-fluid' style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e",borderLeft:[borderBoxLeft],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}} alt="calon pengantin 1" />
                                                                                }
                                                                            </td>
                                                                            <td style={{width:"5%",boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}><div className="text-center bi bi-suit-heart-fill" style={{fontSize:"25px",color:[fntColor],fontFamily:"initial"}}></div></td>
                                                                            <td style={{width:"45%"}}>
                                                                                {isShow?
                                                                                    <div>
                                                                                        <input type="file"id="ImagePengantin2" className="form-control form" accept="image/*" onChange={handleUploadChangePengantin2}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                        <label htmlFor="ImagePengantin2" className='fw-bold p-2' style={{backgroundColor: [imagePengantin2 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"40px 0px 0px 40px",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Pengantin 2 <i className="bi bi-image-fill"></i></label>
                                                                                    </div>
                                                                                    :<img src={imagePengantin2 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg"} className='img-fluid' style={{width:"250px",borderRadius:"30px",boxShadow:"-10px 10px 25px -10px #8e8e8e",borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}} alt="calon pengantin 2" />
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                                <div className="container-fluid">
                                                                    <div className="row justify-content-center mt-4">
                                                                        <div className="col-12 col-md-5">
                                                                            <h4 className='text-center fw-bold' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                {isShow?
                                                                                    <input type="text" id="namaPengantin1" className="form-control form" maxLength="100" value={namaPengantin1} onChange={(e)=>setNamaPengantin1(e.target.value)} style={{width:"100%",textAlign:"center",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} placeholder='Nama Pengantin 1' required/>
                                                                                    :namaPengantin1 || "Romeo Romeano"
                                                                                }
                                                                            </h4>
                                                                            <h6 className='text-center fw-bold' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                {isShow?
                                                                                    <input type="text" id="namaSosmedPengantin1" className="form-control form" maxLength="100" value={namaSosmedPengantin1} onChange={(e)=>setNamaSosmedPengantin1(e.target.value)} style={{width:"100%",textAlign:"center",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} placeholder='Username Instagram' required/>
                                                                                    :<div><i className="bi bi-instagram"style={{color:[fntColor],fontSize:"9pt"}}></i> {namaSosmedPengantin1 || "romeo_"}</div>
                                                                                }
                                                                            </h6>
                                                                            {isShow?
                                                                                <div>
                                                                                    <select id="kelaminPengantin1" className="form-control form" value={kelaminPengantin1} onChange={(e)=>setKelaminPengantin1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                        <option value="">Klik Pilih Jenis Kelamin</option>
                                                                                        <option value="Putra">Putra</option>
                                                                                        <option value="Putri">Putri</option>
                                                                                    </select>
                                                                                    <select id="urutanPengantin1" className="form-control form" value={urutanPengantin1} onChange={(e)=>setUrutanPengantin1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                        <option value="">Anak ke Berapa ?</option>
                                                                                        <option value="Tunggal">Tunggal</option>
                                                                                        <option value="Pertama">Pertama</option>
                                                                                        <option value="Kedua">Kedua</option>
                                                                                        <option value="Ketiga">Ketiga</option>
                                                                                        <option value="Keempat">Keempat</option>
                                                                                        <option value="Kelima">Kelima</option>
                                                                                        <option value="Keenam">Keenam</option>
                                                                                        <option value="Kelima">Kelima</option>
                                                                                        <option value="Ketujuh">Ketujuh</option>
                                                                                        <option value="Kedelapan">Kedelapan</option>
                                                                                        <option value="Kesembilan">Kesembilan</option>
                                                                                        <option value="Kesepuluh">Kesepuluh</option>
                                                                                        <option value="Kesebelas">Kesebelas</option>
                                                                                        <option value="Keduabelas">Keduabelas</option>
                                                                                        <option value="Ketigabelas">Ketigabelas</option>
                                                                                        <option value="Keempatbelas">Keempatbelas</option>
                                                                                        <option value="Kelimabelas">Kelimabelas</option>
                                                                                        <option value="Keenambelas">Keenambelas</option>
                                                                                        <option value="Ketujuhbelas">Ketujuhbelas</option>
                                                                                        <option value="Kedelapanbelas">Kedelapanbelas</option>
                                                                                        <option value="Kesembilanbelas">Kesembilanbelas</option>
                                                                                        <option value="Keduapuluh">Keduapuluh</option>
                                                                                    </select>
                                                                                    <input type="text" id="namaBapakPengantin1" className="form-control form" maxLength="100" value={namaBapakPengantin1} onChange={(e)=>setNamaBapakPengantin1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Bapak Pengantin 1' required/>
                                                                                    <input type="text" id="namaIbuPengantin1" className="form-control form mt-2" maxLength="100" value={namaIbuPengantin1} onChange={(e)=>setNamaIbuPengantin1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Ibu Pengantin 1' required/>       
                                                                                </div>
                                                                                :<h6 className='text-center fw-normal' style={{color:[fntColor],fontFamily:"initial"}}>{kelaminPengantin1 || "Putra"} {urutanPengantin1 || "Pertama"} dari<br/>Bapak {namaBapakPengantin1 || "Abdul Aziz"} & Ibu {namaIbuPengantin1 || "Nuraini"}</h6>
                                                                            }
                                                                        </div>
                                                                        <div className="col-12 col-md-1"><div className="text-center fw-bold" style={{fontSize:"25px",color:[fntColor],fontFamily:"initial"}}>&</div></div>
                                                                        <div className="col-12 col-md-5">
                                                                            <h4 className='text-center fw-bold' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                {isShow?
                                                                                    <input type="text" id="namaPengantin2" className="form-control form" maxLength="100" value={namaPengantin2} onChange={(e)=>setNamaPengantin2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Pengantin 2' required/>
                                                                                    :namaPengantin2 || "Juliet Julieto"
                                                                                }
                                                                            </h4>
                                                                            <h6 className='text-center fw-bold' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                {isShow?
                                                                                    <input type="text" id="namaSosmedPengantin2" className="form-control form" maxLength="100" value={namaSosmedPengantin2} onChange={(e)=>setNamaSosmedPengantin2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Username Instagram' required/>
                                                                                    :<div><i className="bi bi-instagram"style={{color:[fntColor],fontSize:"9pt"}}></i> {namaSosmedPengantin2 || "juliet13"}</div>
                                                                                }
                                                                            </h6>
                                                                            {isShow?
                                                                                <div>
                                                                                    <select id="kelaminPengantin2" className="form-control form" value={kelaminPengantin2} onChange={(e)=>setKelaminPengantin2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                        <option value="">Klik Pilih Jenis Kelamin</option>
                                                                                        <option value="Putra">Putra</option>
                                                                                        <option value="Putri">Putri</option>
                                                                                    </select>
                                                                                    <select id="urutanPengantin2" className="form-control form" value={urutanPengantin2} onChange={(e)=>setUrutanPengantin2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                        <option value="">Anak ke Berapa ?</option>
                                                                                        <option value="Tunggal">Tunggal</option>
                                                                                        <option value="Pertama">Pertama</option>
                                                                                        <option value="Kedua">Kedua</option>
                                                                                        <option value="Ketiga">Ketiga</option>
                                                                                        <option value="Keempat">Keempat</option>
                                                                                        <option value="Kelima">Kelima</option>
                                                                                        <option value="Keenam">Keenam</option>
                                                                                        <option value="Kelima">Kelima</option>
                                                                                        <option value="Ketujuh">Ketujuh</option>
                                                                                        <option value="Kedelapan">Kedelapan</option>
                                                                                        <option value="Kesembilan">Kesembilan</option>
                                                                                        <option value="Kesepuluh">Kesepuluh</option>
                                                                                        <option value="Kesebelas">Kesebelas</option>
                                                                                        <option value="Keduabelas">Keduabelas</option>
                                                                                        <option value="Ketigabelas">Ketigabelas</option>
                                                                                        <option value="Keempatbelas">Keempatbelas</option>
                                                                                        <option value="Kelimabelas">Kelimabelas</option>
                                                                                        <option value="Keenambelas">Keenambelas</option>
                                                                                        <option value="Ketujuhbelas">Ketujuhbelas</option>
                                                                                        <option value="Kedelapanbelas">Kedelapanbelas</option>
                                                                                        <option value="Kesembilanbelas">Kesembilanbelas</option>
                                                                                        <option value="Keduapuluh">Keduapuluh</option>
                                                                                    </select>
                                                                                    <input type="text" id="namaBapakPengantin2" className="form-control form" maxLength="100" value={namaBapakPengantin2} onChange={(e)=>setNamaBapakPengantin2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Bapak Pengantin 2' required/>
                                                                                    <input type="text" id="namaIbuPengantin2" className="form-control form mt-2" maxLength="100" value={namaIbuPengantin2} onChange={(e)=>setNamaIbuPengantin2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Ibu Pengantin 2' required/>       
                                                                                </div>
                                                                                :<h6 className='text-center fw-normal' style={{color:[fntColor],fontFamily:"initial"}}>{kelaminPengantin2 || "Putri"} {urutanPengantin2 || "Pertama"} dari<br/>Bapak {namaBapakPengantin2 || "Aziz Abdul"} & Ibu {namaIbuPengantin2 || "Aininuroh"}</h6>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <img src={bgImageBox1} className='w-100'/>
                                                            </section>
                                                            {/* BOX 2 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                                                    <div className="container-fluid">
                                                                        <div className="row justify-content-center p-4">
                                                                            <div className="col-12 col-md-5 my-4">
                                                                                <div className='box' style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                                                    <div className="px-3 pt-3">
                                                                                        <div className='bi bi-calendar2-event text-center' style={{fontSize:"30px",color:[fntColor],fontFamily:"initial"}}></div>
                                                                                        <h4 className='text-center fw-bold pt-1' style={{color:[fntColor],fontFamily:"initial"}}>Akad Nikah</h4>
                                                                                        <h5 className='text-center' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                            {isShow ?
                                                                                                <select id="hariAkad" className="form-control form" value={hariAkad} onChange={(e)=>setHariAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                                    <option value="">Pilih Hari Akad</option>
                                                                                                    <option value="Sabtu">Sabtu</option>
                                                                                                    <option value="Minggu">Minggu</option>
                                                                                                    <option value="Senin">Senin</option>
                                                                                                    <option value="Selasa">Selasa</option>
                                                                                                    <option value="Rabu">Rabu</option>
                                                                                                    <option value="Kamis">Kamis</option>
                                                                                                    <option value="Jumat">Jumat</option>
                                                                                                </select>
                                                                                                :hariAkad || "Sabtu"
                                                                                            }
                                                                                        </h5>
                                                                                        <h5 className='text-center' style={{marginTop:"-8px",color:[fntColor],fontFamily:"initial"}}>
                                                                                            {isShow ?
                                                                                                <input type="date" id="tanggalAkad" className="form-control form mt-3" maxLength="40" value={tanggalAkad} onChange={(e)=>setTanggalAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Tanggal (01 Januari 2022)' required/>
                                                                                                :formatDateToIndonesian(tanggalAkad)
                                                                                            }
                                                                                        </h5>
                                                                                        {isShow ?
                                                                                            <div>
                                                                                                <input type="text" id="jamMulaiAkad" className="form-control form" maxLength="5" value={jamMulaiAkad} onChange={(e)=>setJamMulaiAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Jam Mulai (09.00)' required/>
                                                                                                <input type="text" id="jamAkhirAkad" className="form-control form" maxLength="5" value={jamAkhirAkad} onChange={(e)=>setJamAkhirAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Jam Akhir (11.00)' required/>
                                                                                                <select id="jamAkhirAkad" className="form-control form mt-2" value={zonaWaktuAkad} onChange={(e)=>setZonaWaktuAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                                    <option value="">Pilih Zona Waktu</option>
                                                                                                    <option value="WIB">WIB</option>
                                                                                                    <option value="WITA">WITA</option>
                                                                                                    <option value="WIT">WIT</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            :<h5 className='text-center pb-2' style={{marginTop:"-10px",color:[fntColor],fontFamily:"initial"}}>{jamMulaiAkad || "09.00"} sd {jamAkhirAkad || "11.00"} {zonaWaktuAkad || "WIB"}</h5>
                                                                                        }
                                                                                        <a href={kalenderAkad} aria-label="saveCalendar">
                                                                                            <button type='button' className="form form-control fw-bold mb-4 mt-3 px-3" style={{backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],fontFamily:"initial",border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                                                        </a>
                                                                                    </div>
                                                                                    <img src={bgImageBox2} className='w-100' />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-md-1"></div>
                                                                            <div className="col-12 col-md-5 my-4">
                                                                                <div className='box' style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                                                    <div className="px-3 pt-3">
                                                                                        <div className='bi bi-calendar2-event text-center' style={{fontSize:"30px",color:[fntColor],fontFamily:"initial"}}></div>
                                                                                        <h4 className='text-center fw-bold pt-1' style={{color:[fntColor],fontFamily:"initial"}}>Resepsi Nikah</h4>
                                                                                        <h5 className='text-center' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                            {isShow ?
                                                                                                <select id="hariResepsi" className="form-control form" value={hariResepsi} onChange={(e)=>setHariResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                                    <option value="">Pilih Hari Resepsi</option>
                                                                                                    <option value="Sabtu">Sabtu</option>
                                                                                                    <option value="Minggu">Minggu</option>
                                                                                                    <option value="Senin">Senin</option>
                                                                                                    <option value="Selasa">Selasa</option>
                                                                                                    <option value="Rabu">Rabu</option>
                                                                                                    <option value="Kamis">Kamis</option>
                                                                                                    <option value="Jumat">Jumat</option>
                                                                                                </select>
                                                                                                :hariResepsi || "Sabtu"
                                                                                            }
                                                                                        </h5>
                                                                                        <h5 className='text-center' style={{marginTop:"-8px",color:[fntColor],fontFamily:"initial"}}>
                                                                                            {isShow ?
                                                                                                <input type="date" id="tanggalResepsi" className="form-control form mt-3" maxLength="40"  value={tanggalResepsi} onChange={(e)=>setTanggalResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Tanggal (01 Januari 2022)' required/>
                                                                                                :formatDateToIndonesian(tanggalResepsi)
                                                                                            }
                                                                                        </h5>
                                                                                        {isShow ?
                                                                                            <div>
                                                                                                <input type="text" id="jamMulaiResepsi" className="form-control form" maxLength="5" value={jamMulaiResepsi} onChange={(e)=>setJamMulaiResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Jam Mulai (11.00)' required/>
                                                                                                <input type="text" id="jamAkhirResepsi" className="form-control form mt-2" maxLength="5" value={jamAkhirResepsi} onChange={(e)=>setJamAkhirResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Jam Akhir (13.00)' required/>
                                                                                                <select id="jamAkhirResepsi" className="form-control form mt-2" value={zonaWaktuResepsi} onChange={(e)=>setZonaWaktuResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} required>
                                                                                                    <option value="">Pilih Zona Waktu</option>
                                                                                                    <option value="WIB">WIB</option>
                                                                                                    <option value="WITA">WITA</option>
                                                                                                    <option value="WIT">WIT</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            :<h5 className='text-center pb-2' style={{marginTop:"-10px",color:[fntColor],fontFamily:"initial"}}>{jamMulaiAkad || "09.00"} sd {jamAkhirAkad || "11.00"} {zonaWaktuResepsi || "WIB"}</h5>
                                                                                        }
                                                                                        <a href={kalenderResepsi} aria-label="saveCalendar">
                                                                                            <button type='button' className="form form-control fw-bold mb-4 mt-3 px-3" style={{backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],fontFamily:"initial",border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-calendar-heart"></i> Simpan Kalender</button>
                                                                                        </a>
                                                                                    </div>
                                                                                    <img src={bgImageBox2} className='w-100' />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {/* BOX 3 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                                                                <div className='bi bi-map text-center' style={{fontSize:"30px",color:[fntColor],fontFamily:"initial"}}></div>
                                                                <h4 className='text-center fw-bold pt-1' style={{color:[fntColor],fontFamily:"initial"}}>Alamat Nikah</h4>
                                                                <div className="container-fluid">
                                                                    <div className="row justify-content-center">
                                                                        <div className="col-12 col-md-5 mt-4 mb-3">
                                                                            <h5 className='text-center fw-bolder' style={{color:[fntColor],fontFamily:"initial"}}>Akad Nikah</h5>
                                                                            <h6 className='text-center fw-normal mb-3' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                {isShow ?<div>
                                                                                        <input type="text" id="alamatAkad" className="form-control form" maxLength="100" value={alamatAkad} onChange={(e)=>setAlamatAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Alamat Akad' required/>
                                                                                        <input type="text" id="linkMapsAlamatAkad" className="form-control form mt-2" maxLength="500" value={linkMapsAlamatAkad} onChange={(e)=>setLinkMapsAlamatAkad(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Link Google Maps' required/>
                                                                                    </div>
                                                                                    :alamatAkad || "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia"
                                                                                }
                                                                            </h6>
                                                                            <a href={linkMapsAlamatAkad || "https://www.google.com"} aria-label="Google Maps">
                                                                                <button type='button' className="form form-control fw-bold mb-4 mt-3 px-3" style={{backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],fontFamily:"initial",border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                                                            </a>
                                                                        </div>
                                                                        <div className="col-12 col-md-1"></div>
                                                                        <div className="col-12 col-md-5 mt-4 mb-3">
                                                                            <h5 className='text-center fw-bolder' style={{color:[fntColor],fontFamily:"initial"}}>Resepsi Nikah</h5>
                                                                            <h6 className='text-center fw-normal mb-3' style={{color:[fntColor],fontFamily:"initial"}}>
                                                                                {isShow ?<div>
                                                                                        <input type="text" id="alamatResepsi" className="form-control form" maxLength="100" value={alamatResepsi} onChange={(e)=>setAlamatResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Alamat Resepsi' required/>
                                                                                        <input type="text" id="linkMapsAlamatResepsi" className="form-control form mt-2" maxLength="500" value={linkMapsAlamatResepsi} onChange={(e)=>setLinkMapsAlamatResepsi(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Link  Google Maps' required/>
                                                                                    </div>
                                                                                    :alamatResepsi || "Taman Bunga Indah, Jl. Buah Anggrek Blok FE.33 No.101, RT.010 RW.112, Kecamatan Mana, Kabupaten Mana, Provinsi Mana, Indonesia"
                                                                                }
                                                                            </h6>
                                                                            <a href={linkMapsAlamatResepsi || "https://www.google.com"} aria-label="Google Maps">
                                                                                <button type='button' className="form form-control fw-bold mb-4 mt-3 px-3" style={{backgroundColor:[btnColor],width:"auto",display:"block",margin:"auto",textAlign:"center",padding:"5px",boxShadow:`-10px 10px 25px -14px ${btnColorCD}`,color:[fntColor],fontFamily:"initial",border:[borderBtn],fontSize:"12pt"}}><i className="bi bi-geo-alt"></i> Lihat Maps</button>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <img src={bgImageBox1} className='w-100'/>
                                                            </section>
                                                            {/* BOX 4 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                                                    <div className="container-fluid">
                                                                        <div className="row justify-content-center p-4">
                                                                            <div className="col-12 col-md-6 my-4">
                                                                                <div className='box' style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                                                    <div className="p-3">
                                                                                        {isShow ?
                                                                                            <div className="row">
                                                                                                <div className="col-12 col-sm-6 mt-3">
                                                                                                    <input className="form-check-input form" type="checkbox" id="isCheckedAnnisa" checked={isCheckedAnnisa} onChange={inputAyatSuciAnnisa}/>
                                                                                                    <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAnnisa">
                                                                                                        <h6 className='mx-2' style={{color:[fntColor]}}>QS An Nisa Ayat 1</h6>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6 mt-3">
                                                                                                    <input className="form-check-input form" type="checkbox" id="isCheckedArrum" checked={isCheckedArrum} onChange={inputAyatSuciArrum}/>
                                                                                                    <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedArrum">
                                                                                                        <h6 className='mx-2' style={{color:[fntColor]}}>QS Ar Rum Ayat 21</h6>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6 mt-3">
                                                                                                    <input className="form-check-input form" type="checkbox" id="isCheckedAnnur" checked={isCheckedAnnur} onChange={inputAyatSuciAnnur}/>
                                                                                                    <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAnnur">
                                                                                                        <h6 className='mx-2' style={{color:[fntColor]}}>QS An Nur Ayat 32</h6>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6 mt-3">
                                                                                                    <input className="form-check-input form" type="checkbox" id="isCheckedAnnahl" checked={isCheckedAnnahl} onChange={inputAyatSuciAnnahl}/>
                                                                                                    <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAnnahl">
                                                                                                        <h6 className='mx-2' style={{color:[fntColor]}}>QS An Nahl Ayat 72</h6>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6 mt-3">
                                                                                                    <input className="form-check-input form" type="checkbox" id="isCheckedAlfathir" checked={isCheckedAlfathir} onChange={inputAyatSuciAlfathir}/>
                                                                                                    <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAlfathir">
                                                                                                        <h6 className='mx-2' style={{color:[fntColor]}}>QS Al Fathir Ayat 11</h6>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12 col-sm-6 mt-3">
                                                                                                    <input className="form-check-input form" type="checkbox" id="isCheckedAzzariyat" checked={isCheckedAzzariyat} onChange={inputAyatSuciAzzariyat}/>
                                                                                                    <label style={{fontSize:"10pt"}} className="form-check-label pt-1" htmlFor="isCheckedAzzariyat">
                                                                                                        <h6 className='mx-2' style={{color:[fntColor]}}>QS Az-Zariyat Ayat 49</h6>
                                                                                                    </label>
                                                                                                </div>
                                                                                                <div className="col-12">
                                                                                                    <input type="text" className='form-disable' value={surah} onChange={(e)=>setSurah(e.target.value)}/>
                                                                                                    <textarea className='form-control form my-3' defaultValue={ayat} style={{boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}} readOnly></textarea>
                                                                                                </div>
                                                                                            </div>
                                                                                            :<div>
                                                                                                <h6 className='text-center fw-normal mt-2' style={{color:[fntColor],fontFamily:"initial"}}>{ayat || "“Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri, agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang. Sungguh, pada demikian itu benar-benar terdapat tanda-tanda (kebesaran Allah) bagi kaum yang berpikir.”"}</h6>
                                                                                                <h5 className='text-center fw-bold pt-1' style={{color:[fntColor],fontFamily:"initial"}}>({surah || "(QS. Ar-Rum ayat 21)"})</h5>
                                                                                            </div> 
                                                                                        }
                                                                                    </div>
                                                                                    <img src={bgImageBox2} className='w-100' style={{marginTop:"-10px"}}/>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {/* BOX 5 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                                                                <div className="container">
                                                                    <div className="row justify-content-center">
                                                                        <div className='col-12 col-lg-10 mb-5'>
                                                                            <div className='bi bi-images text-center' style={{fontSize:"30px",color:[fntColor],fontFamily:"initial"}}></div>
                                                                            <h4 className='text-center fw-bolder mb-4' style={{color:[fntColor],fontFamily:"initial"}}>Gallery Foto</h4>
                                                                            <div className="container-fluid">
                                                                                <div className='rowes justify-content-center'>
                                                                                    <div className="column">
                                                                                        {isShow ?
                                                                                            <div>
                                                                                                <input type="file"id="imageGallery1" className="form-control form" accept="image/*" onChange={handleUploadChangeGallery1}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                                <label htmlFor="imageGallery1" className='fw-bold p-2 mb-2' style={{backgroundColor: [imageGallery1 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"10px",boxShadow:`10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Gallery 1 <i className="bi bi-image-fill"></i></label>
                                                                                                <input type="file"id="imageGallery2" className="form-control form" accept="image/*" onChange={handleUploadChangeGallery2}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                                <label htmlFor="imageGallery2" className='fw-bold p-2 mb-2' style={{backgroundColor: [imageGallery2 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"10px",boxShadow:`10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Gallery 2 <i className="bi bi-image-fill"></i></label>
                                                                                                <input type="file"id="imageGallery3" className="form-control form" accept="image/*" onChange={handleUploadChangeGallery3}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                                <label htmlFor="imageGallery3" className='fw-bold p-2 mb-2' style={{backgroundColor: [imageGallery3 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"10px",boxShadow:`10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Gallery 3 <i className="bi bi-image-fill"></i></label>
                                                                                            </div>
                                                                                            :<div>
                                                                                                <a href={imageGallery1 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg"} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery1 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple0-min.jpg"} className="img-fluid" width="100%" alt="Foto" style={{borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                                                                <a href={imageGallery2 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg"} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery2 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple4-min.jpg"} className="img-fluid" width="100%" alt="Foto" style={{borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                                                                <a href={imageGallery3 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg"} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery3 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple2-min.jpg"} className="img-fluid" width="100%" alt="Foto" style={{borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`-10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                    <div className="column">
                                                                                        {isShow ?
                                                                                            <div>
                                                                                                <input type="file"id="imageGallery4" className="form-control form" accept="image/*" onChange={handleUploadChangeGallery4}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                                <label htmlFor="imageGallery4" className='fw-bold p-2 mb-2' style={{backgroundColor: [imageGallery4 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"10px",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Gallery 4 <i className="bi bi-image-fill"></i></label>
                                                                                                <input type="file"id="imageGallery5" className="form-control form" accept="image/*" onChange={handleUploadChangeGallery5}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                                <label htmlFor="imageGallery5" className='fw-bold p-2 mb-2' style={{backgroundColor: [imageGallery5 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"10px",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Gallery 5 <i className="bi bi-image-fill"></i></label>
                                                                                                <input type="file"id="imageGallery6" className="form-control form" accept="image/*" onChange={handleUploadChangeGallery6}style={{position: "absolute",opacity: 0,width: "2%",zIndex: -1,marginTop:"0px"}} />
                                                                                                <label htmlFor="imageGallery6" className='fw-bold p-2 mb-2' style={{backgroundColor: [imageGallery6 ? "green" : "red"],color: "#fff",cursor: "pointer",fontSize: "16px",borderRadius:"10px",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}}>Klik Ganti Foto Gallery 6 <i className="bi bi-image-fill"></i></label>
                                                                                            </div>
                                                                                            :<div>
                                                                                                <a href={imageGallery4 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg"} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery4 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple6-min.jpg"} className="img-fluid" width="100%" alt="Foto" style={{borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                                                                <a href={imageGallery5 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg"} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery5 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple1-min.jpg"} className="img-fluid" width="100%" alt="Foto" style={{borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                                                                <a href={imageGallery6 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"} aria-label="gallery" data-toggle="lightbox" data-gallery="gallery"><img src={imageGallery6 || "http://localhost:3000/img/undanganDigital/UndanganDigitalPernikahan/couple7-min.jpg"} className="img-fluid" width="100%" alt="Foto" style={{borderRadius:"10px",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight],boxShadow:`10px 10px 25px -18px ${btnColorCD}`}}/></a>
                                                                                            </div>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <img src={bgImageBox1} className='w-100' />
                                                            </section>
                                                            {/* BOX 6 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <div style={{backgroundColor:`${bgColorBox2}`,width:"100%",height:"auto"}}>
                                                                    <div className="container-fluid">
                                                                        <div className="row justify-content-center py-4 px-2">
                                                                            <div className="col-12 col-md-10 my-4">
                                                                                <div className='box' style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                                                    <div className="p-3">
                                                                                        <div className='bi bi-credit-card text-center' style={{fontSize:"30px",color:[fntColor],fontFamily:"initial"}}></div>
                                                                                        <h4 className='text-center fw-bold pt-1 pb-3' style={{color:[fntColor],fontFamily:"initial"}}>Beri Kado Pernikahan</h4>
                                                                                        <div className="row justify-content-center">
                                                                                            <div className="col-12 mb-4">
                                                                                                <div>
                                                                                                    <div className="box p-4" style={{background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,boxShadow:`10px 10px 20px -15px ${fntColor}`,boxShadow:`10px 10px 25px -17px ${btnColorCD}`}}>
                                                                                                        <p className='fw-bold' style={{color:[fntColor],fontFamily:"initial",textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {isShow?<input type="text" id="namaBank1" className="form-control form" maxLength="20" value={namaBank1} onChange={(e)=>setNamaBank1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Bank 1' required/>:namaBank1 || "Mandiri"}</p>
                                                                                                        <div className="row mb-4">
                                                                                                            <div className="col-2"><i className="bi bi-cpu" style={{color:[fntColor],fontFamily:"initial",fontSize:"40px"}}></i></div>
                                                                                                            <div className="col-10"><p className='fw-bold mt-3 px-1' style={{color:[fntColor],fontFamily:"initial"}}>{isShow?<input type="text" id="namaPemilikBank1" className="form-control form" maxLength="100" value={namaPemilikBank1} onChange={(e)=>setNamaPemilikBank1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center",marginTop:"-5px"}} placeholder='Nama Pemilik Tabungan 1' required/>:namaPemilikBank1 || "An. Romeo"}</p></div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-10">
                                                                                                                {isShow ?
                                                                                                                    <input type="text" id="norekBank1" className="form-control form" maxLength="20" value={norekBank1} onChange={(e)=>setNorekBank1(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center",marginTop:"-5px"}} placeholder='Nomor Rekening Bank 1' required/>
                                                                                                                    :<input type="text" id='noRek1' className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontFamily:"initial",fontSize:"14pt"}}ref={inputNoRek1} defaultValue={norekBank1 || "1234567891234567"}/>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontFamily:"initial"}}><i className="bi bi-copy fw-bold"onClick={salinRek1}></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 mb-4">
                                                                                                <div>
                                                                                                    <div className="box p-4" style={{background: `linear-gradient(-45deg, ${btnColorCD} -200%, ${btnColor} 70%)`,boxShadow:`10px 10px 20px -15px ${fntColor}`,boxShadow:`10px 10px 25px -17px ${btnColorCD}`}}>
                                                                                                        <p className='fw-bold' style={{color:[fntColor],fontFamily:"initial",textAlign:"right",fontSize:"15pt",fontStyle:"italic"}}><i className="bi bi-bank"></i> Bank {isShow?<input type="text" id="namaBank2" className="form-control form" maxLength="20" value={namaBank2} onChange={(e)=>setNamaBank2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center"}} placeholder='Nama Bank 2' required/>:namaBank2 || "BCA"}</p>
                                                                                                        <div className="row mb-4">
                                                                                                            <div className="col-2"><i className="bi bi-cpu" style={{color:[fntColor],fontFamily:"initial",fontSize:"40px"}}></i></div>
                                                                                                            <div className="col-10"><p className='fw-bold mt-3 px-1' style={{color:[fntColor],fontFamily:"initial"}}>{isShow?<input type="text" id="namaPemilikBank2" className="form-control form" maxLength="100" value={namaPemilikBank2} onChange={(e)=>setNamaPemilikBank2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center",marginTop:"-5px"}} placeholder='Nama Pemilik Tabungan 2' required/>:namaPemilikBank2 || "An. Juliet"}</p></div>
                                                                                                        </div>
                                                                                                        <div className="row">
                                                                                                            <div className="col-10">
                                                                                                                {isShow ?
                                                                                                                    <input type="text" id="norekBank2" className="form-control form" maxLength="20" value={norekBank2} onChange={(e)=>setNorekBank2(e.target.value)} style={{width:"100%",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`,textAlign:"center",marginTop:"-5px"}} placeholder='Nomor Rekening Bank 2' required/>
                                                                                                                    :<input type="text" id='noRek2' className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontFamily:"initial",fontSize:"14pt"}}ref={inputNoRek2} defaultValue={norekBank2 || "1234567891234567"}/>
                                                                                                                }
                                                                                                            </div>
                                                                                                            <div className="col-2">
                                                                                                                <button type="button" className="fw-bold" style={{backgroundColor:"transparent",border:"none",color:[fntColor],fontFamily:"initial"}}><i className="bi bi-copy fw-bold"onClick={salinRek2}></i></button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>  
                                                                                    </div>
                                                                                    <img src={bgImageBox2} className='w-100' />
                                                                                </div>
                                                                            </div>
                                                                            
                                                                            <div className="col-12 col-md-10 my-4">
                                                                                <div className='box' style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",boxShadow:"none",borderLeft:[borderBoxLeft],borderRight:[borderBoxRight]}}>
                                                                                    <div className="p-3">
                                                                                        <div className='bi bi-file-earmark-music text-center' style={{fontSize:"30px",color:[fntColor],fontFamily:"initial"}}></div>
                                                                                        <h4 className='text-center fw-bold pt-1' style={{color:[fntColor],fontFamily:"initial"}}>Pilih Musik</h4>
                                                                                        <select className="form-select form my-4" id="pilihanMusik" aria-label="Pilihan Musik" value={musik} onChange={(e)=>setMusik(e.target.value)} style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} required>
                                                                                            <option>Pilih Musik (Inggris, Indonesia, Islami)</option>
                                                                                            <optgroup label="Pilihan Musik Inggris :">
                                                                                                <option value="All Of Me - John Legend">- All Of Me - John Legend</option>
                                                                                                <option value="A Thousand Years - Christina Perri">- A Thousand Years - Christina Perri</option>
                                                                                                <option value="Beautiful In White - Shane Filan">- Beautiful In White - Shane Filan</option>
                                                                                                <option value="Because You Loved Me - Celine Dion">- Because You Loved Me - Celine Dion</option>
                                                                                                <option value="Cant Help Falling In Love - Elvis Presley">- Cant Help Falling In Love - Elvis Presley</option>
                                                                                                <option value="Cant Help Falling In Love - Alexandra Porat Cover">- Cant Help Falling In Love - Alexandra Porat Cover</option>
                                                                                                <option value="Close To You - Carpenters">- Close To You - Carpenters</option>
                                                                                                <option value="Endless Love - Luther Vandross">- Endless Love - Luther Vandross</option>
                                                                                                <option value="Everything I Do - Bryan Adams">- Everything I Do - Bryan Adams</option>
                                                                                                <option value="I Love You - Celine Dion">- I Love You - Celine Dion</option>
                                                                                                <option value="I Wanna Grow Old With You - Westlife">- I Wanna Grow Old With You - Westlife</option>
                                                                                                <option value="Lucky - Jason Mraz">- Lucky - Jason Mraz</option>
                                                                                                <option value="Make You Feel My Love - Adele">- Make You Feel My Love - Adele</option>
                                                                                                <option value="Marry You - Bruno Mars">- Marry You - Bruno Mars</option>
                                                                                                <option value="My Love - Westlife">- My Love - Westlife</option>
                                                                                                <option value="Perfect - Ed Sheeran">- Perfect - Ed Sheeran</option>
                                                                                                <option value="Since I Found You - Christian Bautista">- Since I Found You - Christian Bautista</option>
                                                                                                <option value="The Power Of Love - Celine Dion">- The Power Of Love - Celine Dion</option>
                                                                                                <option value="The Way You Look Me - Christian Bautista">- The Way You Look Me - Christian Bautista</option>
                                                                                                <option value="Thinking Out Loud - Ed Sheeran">- Thinking Out Loud - Ed Sheeran</option>
                                                                                                {/* <option value="When You Tell Me That You Love Me - Westlife Ft Diana Ross">- When You Tell Me That You Love Me - Westlife Ft Diana Ross</option> */}
                                                                                                <option value="You Are The Reason - Calum Scott">- You Are The Reason - Calum Scott</option>
                                                                                            </optgroup>
                                                                                            <optgroup label="Pilihan Musik Indonesia :"> 
                                                                                                <option value="Akad - Payung Teduh">- Akad - Payung Teduh</option>
                                                                                                <option value="Akhirnya Kumenemukanmu - Naff">- Akhirnya Kumenemukanmu - Naff</option>
                                                                                                <option value="Aku Dan Dirimu - Arilaso Ft BCL">- Aku Dan Dirimu - Arilaso Ft BCL</option>
                                                                                                <option value="Aku Makin Cinta - Vina Panduwinata">- Aku Makin Cinta - Vina Panduwinata</option>
                                                                                                <option value="Berawal Dari Tatap - Yura Yunita">- Berawal Dari Tatap - Yura Yunita</option>
                                                                                                <option value="Berdua Saja - Payung Teduh">- Berdua Saja - Payung Teduh</option>
                                                                                                <option value="Bukan Cinta Biasa - Afgan">- Bukan Cinta Biasa - Afgan</option>
                                                                                                <option value="Cinta luar Biasa - Andmesh Kamaleng">- Cinta luar Biasa - Andmesh Kamaleng</option>
                                                                                                <option value="Cinta Pertama Dan Terakhir - Sherina">- Cinta Pertama Dan Terakhir - Sherina</option>
                                                                                                <option value="Cinta Sejati - BCL">- Cinta Sejati - BCL</option>
                                                                                                <option value="Cinta Terakhir - Arilaso">- Cinta Terakhir - Arilaso</option>
                                                                                                <option value="Jani Suci - Yovie & Nuno">- Jani Suci - Yovie & Nuno</option>
                                                                                                <option value="Kamulah Takdirku - Raffi Ahmad & Nagita Slavina">- Kamulah Takdirku - Raffi Ahmad & Nagita Slavina</option>
                                                                                                <option value="Karena Cinta - Glenn Fredly">- Karena Cinta - Glenn Fredly</option>
                                                                                                <option value="Kasih Putih - Glenn Fredly">- Kasih Putih - Glenn Fredly</option>
                                                                                                <option value="Kesempurnaan Cinta - Rizky Febian">- Kesempurnaan Cinta - Rizky Febian</option>
                                                                                                <option value="Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto">- Lagu Pernikahan Kita - Tiara Andini Ft Arsy Widianto</option>
                                                                                                <option value="Malaikat Juga Tahu - Glenn Fredly">- Malaikat Juga Tahu - Glenn Fredly</option>
                                                                                                <option value="Menikahimu - Kahitna">- Menikahimu - Kahitna</option>
                                                                                                <option value="Sedalam Cinta - Indra Lesmana Ft Nania">- Sedalam Cinta - Indra Lesmana</option>
                                                                                                <option value="Teman Hidup - Tulus">- Teman Hidup - Tulus</option>
                                                                                            </optgroup>
                                                                                            <optgroup label="Pilihan Musik Islami :">
                                                                                                <option value="Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee">- Ketika Cinta Bertasbih - Melly Goeslaw Ft Amee</option>
                                                                                            </optgroup>
                                                                                        </select>
                                                                                        <div className="row justify-content-center">
                                                                                            <div className="col-2">
                                                                                                <audio ref={audioRef} src={`/song/${musik}.mp3`} autoPlay loop preload='none'/>
                                                                                                <button type='button' onClick={handlePlayPause} style={{border:"none",backgroundColor:"transparent"}}>
                                                                                                    <span>
                                                                                                    {isPlaying ? <i className="bi bi-pause-circle" style={{fontSize:"33px",color:[fntColor]}}></i> :<i className="bi bi-play-circle" style={{fontSize:"33px",color:[fntColor]}}></i>}
                                                                                                    </span>
                                                                                                </button>
                                                                                            </div>
                                                                                            <div className="col-10">
                                                                                                <input type="text" id='musik' className='form form-control mb-2' maxLength="200" defaultValue={musik} style={{fontFamily:"initial",boxShadow:`-10px 10px 25px -16px ${btnColorCD}`}} readOnly required/>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <img src={bgImageBox2} className='w-100' />
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </section>
                                                            {/* BOX 7 */}
                                                            <section style={{backgroundImage:[bgCover],width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                                                                <img src={bgImageBox1} className='w-100' style={{transform:`${rotate}`}} />
                                                                <h3 className='text-center fw-bolder mt-4 mb-5' style={{color:[fntColor],fontFamily:"initial"}}>Website By Webew.id</h3>
                                                                <h6 className='text-center fw-bolder mt-4' style={{color:[fntColor],fontFamily:"initial",marginBottom:"-2px"}}>Image On Freepik</h6>
                                                                <p style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "auto" }}>
                                                                    <a href={link1}aria-label="By"style={{textAlign: "center",color: fntColor,fontFamily: "initial",fontSize: "10pt",textDecoration: "none",}}>{pemilik1}</a>
                                                                    <a href={link2}aria-label="By"style={{textAlign: "center",color: fntColor,fontFamily: "initial",fontSize: "10pt",textDecoration: "none",}}>{pemilik2}</a>
                                                                    <a href={link3}aria-label="By"style={{textAlign: "center",color: fntColor,fontFamily: "initial",fontSize: "10pt",textDecoration: "none",}}>{pemilik3}</a>
                                                                </p>
                                                                <h6 className='text-center fw-bolder mt-4' style={{color:[fntColor],fontFamily:"initial",marginBottom:"-2px"}}>Song On Youtube</h6>
                                                                <a href={link4} className="mb-4" aria-label="By" style={{display:"block",margin:"auto",textAlign:"center",color:[fntColor],fontFamily:"initial",fontSize:"10pt"}}>{musik}</a>
                                                                <img src={bgImageBox1} className='w-100'/>
                                                            </section>
                                                        </div>
                                                    </section>
                                                    <h6 style={{textAlign:"center",marginTop:"20px",marginBottom:"-20px",color:"red"}}>{msg}</h6>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <input type="submit" className="btn btn-webew-product my-4 py-4" value="Simpan Undangan"/>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                            </div>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE PERNIKAHAN FORM
// EKSPOR
export default MenuWePernikahanFormMandiri