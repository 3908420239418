// IMPORT REACT
import React, { useState } from "react";
// IMPORT AXIOS
import axios from "axios";
// IMPORT NAVBAR BOTTOM
import NavbarBot from "../../Layout/NavbarBot.jsx";
// IMPORT MODAL IKLAN
import Modal from "../../Layout/Modal.jsx";

// WE KONVERSI
const WeKonversi = () => {
    const [selectedOption, setSelectedOption] = useState("jpegPng"); // Pilihan konversi default
    const [displayLoader, setDisplayLoader] = useState("none");
    const [convertedFiles, setConvertedFiles] = useState([]);
    const [msg, setMsg] = useState("");

    // PROSES HANDLE FILE UPLOAD
    const handleFileUpload = async (event) => {
        const conversionMapping = {
            jpegPng: "jpegToPng",
            jpegWebp: "jpegToWebp",
            jpegAvif: "jpegToAvif",
            jpegTiff: "jpegToTiff",
            // 
            pngJpeg: "pngToJpeg",
            pngWebp: "pngToWebp",
            pngAvif: "pngToAvif",
            pngTiff: "pngToTiff",
            // 
            webpJpeg: "webpToJpeg",
            webpPng: "webpToPng",
            webpAvif: "webpToAvif",
            webpTiff: "webpToTiff",
            // 
            gifJpeg: "gifToJpeg",
            gifPng: "gifToPng",
            gifWebp: "gifToWebp",
            gifAvif: "gifToAvif",
            gifTiff: "gifToTiff",
            // 
            svgJpeg: "svgToJpeg",
            svgPng: "svgToPng",
            svgWebp: "svgToWebp",
            svgAvif: "svgToAvif",
            svgTiff: "svgToTiff",
            // 
            tiffJpeg: "tiffToJpeg",
            tiffPng: "tiffToPng",
            tiffWebp: "tiffToWebp",
            tiffAvif: "tiffToAvif",
            // 
            rawJpeg: "rawToJpeg",
            rawPng: "rawToPng",
            rawWebp: "rawToWebp",
            rawAvif: "rawToAvif",
            rawTiff: "rawToTiff",
        };
        const conversionType = conversionMapping[selectedOption];
        
        const files = event.target.files;
        const formData = new FormData();
        Array.from(files).forEach((file) => {
            formData.append("file", file);
        });
        formData.append("conversionType", conversionType)
        setDisplayLoader(""); // Tampilkan loader
        try {
            const url = `https://server13.webew.id/convertImagesBE`; // Endpoint backend
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data && Array.isArray(response.data.images)) {
                const convertedUrls = response.data.images.map((fileData) =>
                    URL.createObjectURL(
                        new Blob(
                            [new Uint8Array(atob(fileData).split("").map((c) => c.charCodeAt(0)))],
                            { type: getMimeType(selectedOption) }
                        )
                    )
                );
                setConvertedFiles(convertedUrls);
            }
            setDisplayLoader("none"); // Hilangkan loader
        } catch (error) {
            setDisplayLoader("none"); // Hilangkan loader jika terjadi error
            setMsg(error.response ? error.response.data.msg : "Terjadi kesalahan.");
        }
    };

    // FUNGSI UNTUK MENGAMBIL MIME TYPE BERDASARKAN KONVERSI
    const getMimeType = (conversionType) => {
        switch (conversionType) {
        case "jpegPng": return "image/png";
        case "jpegWebp": return "image/webp";
        case "jpegAvif": return "image/avif";
        case "jpegTiff": return "image/tiff";
        // 
        case "pngJpeg": return "image/jpeg";
        case "pngWebp": return "image/webp";
        case "pngAvif": return "image/avif";
        case "pngTiff": return "image/tiff";
        // 
        case "webpJpeg": return "image/jpeg";
        case "webpPng": return "image/png";
        case "webpAvif": return "image/avif";
        case "webpTiff": return "image/tiff";
        // 
        case "gifJpeg": return "image/jpeg";
        case "gifPng": return "image/png";
        case "gifWebp": return "image/webp";
        case "gifAvif": return "image/avif";
        case "gifTiff": return "image/tiff";
        // 
        case "svgJpeg": return "image/jpeg";
        case "svgPng": return "image/png";
        case "svgWebp": return "image/webp";
        case "svgAvif": return "image/avif";
        case "svgTiff": return "image/tiff";
        // 
        case "tiffJpeg": return "image/jpeg";
        case "tiffPng": return "image/png";
        case "tiffWebp": return "image/webp";
        case "tiffAvif": return "image/avif";
        // 
        case "rawJpeg": return "image/jpeg";
        case "rawPng": return "image/png";
        case "rawWebp": return "image/webp";
        case "rawAvif": return "image/avif";
        case "rawTiff": return "image/tiff";
        // 
        default: return "application/octet-stream";
        }  
    };

    // PROSES DOWNLOAD FILE
    const downloadConvertedFiles = () => {
        convertedFiles.forEach((file, index) => {
            const link = document.createElement("a");
            link.href = file;
            link.download = `converted_file_${index + 1}.${getMimeType(selectedOption).split("/")[1]}`;
            link.click();
        });
    };

    // PROSES UNTUK KEMBALI UPLOAD
    const handleButtonUploadLagi = () => {
        window.location.reload();
    };

    // RETURN
    return (
        <div className="background-home" style={{ marginTop: "55px" }}>
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            <Modal />
            {/* INFO */}
            <section id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-6 px-3">
                            <h2 className="title-webew-home">We Konversi</h2>
                            <p className="text-webew-home fw-normal">Ubah format foto JPEG, PNG, WEBP, GIF, SVG, TIFF, dan RAW dengan menggunakan We Konversi</p>
                            <a href={"/"} className="btn btn-webew-product"><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                        </div>
                        <div className="col-12 col-lg-6">
                            <img src="https://webew.id/img/25559969_7094814.webp"className="img-webew-home mx-auto d-block mt-4"style={{ width: "100%", minHeight: "200px" }}alt="Illustration"/>
                        </div> 
                    </div>
                </div>
            </section>
            {/* TUTUP INFO */}
            <img src="https://webew.id/img/bg-lengkung.png"className="img-bg-lengkung"style={{ width: "100%", minHeight: "360px" }}alt="Background"/>
            {/* BOX WE KONVERSI */}
            <section>
                <div className="container">
                    <div className="row justify-content-center">
                        {convertedFiles.length === 0 ?(
                            <>
                            {/* FORM PILIH KONVERSI */}
                            <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-4">
                                <div className="box px-4 py-2">
                                    <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                    <h6 className="text-center fw-normal mb-3">Pilih Jenis Konversi</h6>
                                    <select className="form form-select mb-4"value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                        <optgroup label="Konversi dari JPEG">
                                            <option value="jpegPng">JPEG ke PNG</option>
                                            <option value="jpegWebp">JPEG ke WebP</option>
                                            <option value="jpegAvif">JPEG ke AVIF</option>
                                            <option value="jpegTiff">JPEG ke TIFF</option>
                                        </optgroup>
                                        <optgroup label="Konversi dari PNG">
                                            <option value="pngJpeg">PNG ke JPEG</option>
                                            <option value="pngWebp">PNG ke WebP</option>
                                            <option value="pngAvif">PNG ke AVIF</option>
                                            <option value="pngTiff">PNG ke TIFF</option>
                                        </optgroup>
                                        <optgroup label="Konversi dari WebP">
                                            <option value="webpJpeg">WebP ke JPEG</option>
                                            <option value="webpPng">WebP ke PNG</option>
                                            <option value="webpAvif">WebP ke AVIF</option>
                                            <option value="webpTiff">WebP ke TIFF</option>
                                        </optgroup>
                                        <optgroup label="Konversi dari GIF">
                                            <option value="gifJpeg">GIF ke JPEG</option>
                                            <option value="gifPng">GIF ke PNG</option>
                                            <option value="gifWebp">GIF ke WebP</option>
                                            <option value="gifAvif">GIF ke AVIF</option>
                                            <option value="gifTiff">GIF ke TIFF</option>
                                        </optgroup>
                                        <optgroup label="Konversi dari SVG">
                                            <option value="svgJpeg">SVG ke JPEG</option>
                                            <option value="svgPng">SVG ke PNG</option>
                                            <option value="svgWebp">SVG ke WebP</option>
                                            <option value="svgAvif">SVG ke AVIF</option>
                                            <option value="svgTiff">SVG ke TIFF</option>
                                        </optgroup>
                                        <optgroup label="Konversi dari TIFF">
                                            <option value="tiffJpeg">TIFF ke JPEG</option>
                                            <option value="tiffPng">TIFF ke PNG</option>
                                            <option value="tiffWebp">TIFF ke WebP</option>
                                            <option value="tiffAvif">TIFF ke AVIF</option>
                                        </optgroup>
                                        <optgroup label="Konversi dari RAW">
                                            <option value="rawJpeg">RAW ke JPEG</option>
                                            <option value="rawPng">RAW ke PNG</option>
                                            <option value="rawWebp">RAW ke WebP</option>
                                            <option value="rawAvif">RAW ke AVIF</option>
                                            <option value="rawTiff">RAW ke TIFF</option>
                                        </optgroup>
                                    </select>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                <div className="box px-4 py-2">
                                    <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                    <h6 className="text-center fw-normal mb-3">Upload Maksimal 10 File</h6>
                                    <input type="file"multiple className="form form-control mb-4"onChange={handleFileUpload}style={{backgroundImage: "linear-gradient(to top, rgb(11, 97, 255) 20%, rgb(99, 154, 255) 100%)",color: "white",}}/>
                                </div>
                            </div>
                            </>
                        ):(
                            <>
                            {/* HASIL KONVERSI */}
                            <div className="col-12 col-md-11 col-lg-10 mb-4">
                                <div className="box p-4">
                                    <div className="row mb-4">
                                        <div className="col-12 col-sm-6 mb-3">
                                            <button onClick={handleButtonUploadLagi}className="btn btn-webew-product"><i className="bi bi-arrow-left-circle-fill"></i> Konversi lagi</button>
                                        </div>
                                        <div className="col-12 col-sm-6">
                                            <button onClick={downloadConvertedFiles}className="btn btn-webew-product"><i className="bi bi-download"></i> Download Semua</button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mt-4">
                                        {convertedFiles.map((file, index) => (
                                            <div className="col-6 col-sm-4 col-lg-2" key={index}>
                                                <img src={file}alt={`Converted ${index + 1}`}className="rounded"style={{ maxWidth: "100%", marginBottom: "10px" }}                                                />
                                                <p className="text-center mt-2">File {index + 1}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
            {/* TUTUP BOX WE KOMPRES */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/hand-drawn-flat-design-api-illustration_25559969.htm#page=4&query=programming&position=7&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Freepik di Freepik'>
                                    <div className='card'  style={{width: '100%',minHeight:'180px'}}>
                                        <img src='https://webew.id/img/credit/25559969_7094814.webp' className='mx-auto d-block pt-4' style={{width: '100%',minHeight:'90px'}} alt='by Vectorjuice on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Freepik on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE KOMPRES
// EKSPOR
export default WeKonversi;
