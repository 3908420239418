// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE NAVIGATE, LINK
import { useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE WEBSITE
const MenuWeWebsite = () => { // KURUNG BUKA MENU WE WEBSITE
    // MEMBUAT VARIABEL WE WEBSITE
    const [displayLoader, setDisplayLoader] = useState("")
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-website", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES LOOP JENIS WEBSITE CARD BODY
    const jenisWeWebsite = [
        {
          id: 'website',
          title: 'Web Porto I',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g1.png',
          alt: 'Website portofolio G1',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoI`,
        },
        {
          id: 'website',
          title: 'Web Porto II',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g2.png',
          alt: 'Website portofolio G2',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoII`,
        },
        {
          id: 'website',
          title: 'Web Porto III',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g3.png',
          alt: 'Website portofolio G3',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoIII`,
        },
        {
          id: 'website',
          title: 'Web Porto IV',
          imgSrc: 'https://webew.id/img/desainWebsite/web-porto-g4.png',
          alt: 'Website portofolio G4',
          price: 'Rp 5.000',
          tech: [
            {
              src: 'https://webew.id/img/desainWebsite/HTML 5.png',
              alt: 'Logo HTML 5',
            },
            {
              src: 'https://webew.id/img/desainWebsite/css3-logo-png-transparent.png',
              alt: 'Logo CSS 3',
            },
            {
              src: 'https://webew.id/img/desainWebsite/bootstrap-logo.png',
              alt: 'Logo Bootstrap 5',
            },
          ],
          link: `/menu-we-website-download/${panggilan}/WebPortoIV`,
        },
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
                <div className="loader-page-bg" style={{display:displayLoader}}>
                    <div className="loader-page"></div>
                </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                      <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                        <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                          <div className="container-fluid">
                              <div className="row my-3">
                                  <div className="col-12 p-2">
                                      <h4 className='text-center fw-bold'>We Website</h4>
                                      <p className='text-center fw-semibold'>Download Source Code Website Dengan We Website</p>
                                  </div>
                              </div>
                              <div className="row mb-5 pb-1">
                                {jenisWeWebsite.map((data, index) => (
                                  <div key={index} className="col-6 col-lg-4 col-xl-3 mb-4" id="we website">
                                      <div className="card" style={{ width: '100%' }}>
                                          <h5 className="card-title text-center mt-2 fw-bold">{data.title}</h5>
                                          <a href={data.imgSrc} aria-label='website' data-toggle='lightbox' data-gallery='cv'>
                                              <img src={data.imgSrc}className='mx-auto d-block mt-2'style={{ width: '130px', minHeight: '175px' }}alt={data.alt}/>
                                          </a>
                                          <div className="card-body">
                                            <table>
                                              <tbody>
                                                <tr>
                                                  {data.tech.map((tech, techIndex) => (
                                                    <td key={techIndex} width="33.3%">
                                                      <img src={tech.src} className="img-fluid mx-auto d-block" style={{ width: '40px', minHeight: '40px' }} alt={tech.alt}/>
                                                    </td>
                                                  ))}
                                                </tr>
                                              </tbody>
                                            </table>
                                            <p className="card-text-harga mt-3">Harga Saat Ini</p>
                                            <p className="card-text-rupiah">{data.price}</p>
                                            <a href={data.link} className="btn btn-webew-product mt-1 mb-1" aria-label="Produk webew.id">Lihat</a>
                                          </div>
                                      </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* NAVBOT */}
                          <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE WEBSITE
// EKSPOR
export default MenuWeWebsite