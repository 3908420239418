// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT USE PARAMS
import { useParams, Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx';
// WE WEBSITE CONTOH
const WeWebsiteContoh = () => { // BUKA KURUNG WE WEBSITE CONTOH
    // MEMBUAT VARIABEL WE WEBSITE CONTOH
    const [image, setImage] = useState('')
    const { jenisWeWebsiteParams } = useParams();
    // USEEFFECT
    useEffect(()=>{
        if(jenisWeWebsiteParams === 'WebPortoI'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG1.webp')
        }else if(jenisWeWebsiteParams === 'WebPortoII'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG2.webp')
        }else if(jenisWeWebsiteParams === 'WebPortoIII'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG3.webp')
        }else if(jenisWeWebsiteParams === 'WebPortoIV'){
            setImage('https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG4.webp')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // RETURN
    return ( //BUKA KURUNG RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='weWebsite'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="box p-4 mt-4">
                                            <Link to={'/we-website'} className='btn btn-webew-product mb-2' aria-label='Kembali webew.id' style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</Link>
                                            <h3 className='text-center fw-bold'>{jenisWeWebsiteParams}</h3>
                                            <p className='text-center fw-normal my-2'>Website portofolio yang memiliki 1 halaman dan sudah responsive di segala device dan juga terdapat Animate On Scroll.</p>
                                            <Link to={'https://webew.id/login/menu-we-website'} className='btn btn-webew-product mt-2' aria-label='Produk webew.id'><i className='bi bi-download'></i> Download</Link>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="box mt-4">
                                            <img src="https://webew.id/img/banner.png" alt="banner webew.id" className='mx-auto d-block' style={{width:"100%",maxHeight:"35.4vh",borderRadius:"20px"}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <div className="box my-4"style={{maxHeight: "80vh",overflow: "auto",borderRadius: "30px",border:"30px solid white"}}>
                                    <img src={image} className="img-fluid mx-auto d-block" width="480px" alt="website" style={{borderRadius:"0px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG WE WEBSITE CONTOH
// EKSPOR
export default WeWebsiteContoh