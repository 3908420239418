// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE LINK
import { useParams, useNavigate } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../../Layout/MenuDonasi.jsx'
// MENU WE WEBSITE DOWNLOAD
const MenuWeWebsiteDownload = () => { // KURUNG BUKA MENU WE WEBSITE DOWNLOAD
    // MEMBUAT VARIABEL MENU WE WEBSITE DOWNLOAD
    const { jenisWeWebsiteParams } = useParams();
    const { panggilanParams } = useParams();
    const [name, setName] = useState('')
    const [kodeOrder, setKodeOrder] = useState('')
    const [total,] = useState('5000')
    const [order_id, setOrder_id] = useState('')
    const [tokenTransaksi, setTokenTransaksi] = useState('')
    const [linkDownload, setLinkDownload] = useState("")
    const [imageWeb, setImageWeb] = useState("")
    const [displayPembayaran, setDisplayPembayaran] = useState('')
    const [displayLoader, setDisplayLoader] = useState('')
    const [panggilan, setPanggilan] = useState('')
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USENAVIGATE
    const navigate = useNavigate()
    // USEEFECT
    useEffect(()=>{
        refreshToken()
        selectTransaksi()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
            setDisplayPembayaran("none")
            setDisplayLoader("none")
            const Seconds = new Date().getSeconds()
            const result = Math.random().toString(36).substring(2,12);
            setOrder_id(jenisWeWebsiteParams + result + Seconds)
            if(jenisWeWebsiteParams === "WebPortoI"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG1.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG1.zip")
            }else if(jenisWeWebsiteParams === "WebPortoII"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG2.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG2.zip")
            }else if(jenisWeWebsiteParams === "WebPortoIII"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG3.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG3.zip")
            }else if(jenisWeWebsiteParams === "WebPortoIV"){
                setImageWeb("https://webew.id/img/desainWebsite/ImgWebsiteG/PortoG4.webp")
                setLinkDownload("https://webew.id/img/desainWebsite/AssetWebsiteG/webPortofolioG4.zip")
            }
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-website", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setName(decoded.name)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES TRANSAKSI
    const transaksi = async (e) => {
        e.preventDefault()
        try{
            const data = {
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilan
            }
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            }
            const response = await axios.post("https://server13.webew.id/prosesTransaksiBE",data,config)
            setTokenTransaksi(response.data.tokenTransaksi)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENYIMPAN TRANSAKSI KE DB
    const transaksiBerhasil = async (e) => {
        e.preventDefault()
        try{
            setDisplayLoader("")
            await axios.post("https://server13.webew.id/transaksiBerhasilBE",{
                nama: name,
                order_id: order_id,
                total: total,
                panggilan: panggilan
            })
            window.location.reload()
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // USEEFFECT TRANSAKSI
    useEffect(()=>{
        if(tokenTransaksi){
            window.snap.pay(tokenTransaksi, {
                onSuccess: (result) => {
                    setDisplayPembayaran("")
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onPending: (result) => {
                    window.location.reload()
                    localStorage.setItem("Pembayaran", JSON.stringify(result))
                    setTokenTransaksi("")
                },
                onError: (error) => {
                    window.location.reload()
                    if(error.response){
                        setMsg(error.response.data.msg)
                    }
                    setTokenTransaksi("")
                },
                onClose: () => {
                    window.location.reload()
                    setMsg("Anda belum menyelesaikan pembayaran")
                    setTokenTransaksi("")
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenTransaksi])
    useEffect(()=>{
        const midtransUrl = "https://app.midtrans.com/snap/snap.js"
        let scriptTag = document.createElement("script")
        scriptTag.src = midtransUrl
        const midtransClientKey = "Mid-client-D6LOR1Eecoo9s-wv"
        scriptTag.setAttribute("data-client-key", midtransClientKey)
        document.body.appendChild(scriptTag)
        return() => {
            document.body.removeChild(scriptTag)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN DATA TRANSAKSI BERDASARKAN PANGGILAN BY PARAMS
    const selectTransaksi = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectTransaksiBE/${panggilanParams}`)
            let kodeOrderId = response.data.order_id
            setKodeOrder(kodeOrderId.substring(0, 3))
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div id="wrapper">
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* MODAL MESSAGE*/}
                <div style={{position:"fixed",zIndex:"9999",backgroundColor:"#282a3a8b",height:"100vh",width:"100%",display:displayPembayaran}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <div style={{backgroundColor:"white", width:"100%", padding:"20px",borderRadius:"20px",marginTop:"28vh",boxShadow:"-10px 10px 25px -15px #7f7f7f"}}>
                                    <h6 className='card-text-rupiah mb-2' style={{textAlign:"center",fontWeight:"700"}}>Hore Pembayaran Berhasil<br /><p style={{margin:"20px 0 0 0",fontSize:"12pt",fontWeight:"500"}}>Silahkan tekan tombol oke, jika sudah lalu tekan tombol "Unduh" untuk mengunduh Desain Website nya, terimakasih<br /></p></h6>
                                    <form onSubmit={transaksiBerhasil}>
                                        <input type="text" className="form-disable" value={name} readOnly/>
                                        <input type="text" className="form-disable" value={order_id} readOnly/>
                                        <input type="text" className="form-disable" value={total} readOnly/>
                                        <input type="text" className="form-disable" value={panggilan} readOnly/>
                                        <input type="submit" className="btn btn-webew-product" value="Okeee" style={{marginTop:"18px"}}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* TUTUP MODAL MESSAGE */}
            {/* SIDEBAR */}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAPPER */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid mt-3">
                                    <div className="row mb-5 py-4">
                                        <div className="col-12 col-lg-6 mb-4">
                                            <div className="col-12">
                                                <div className="box p-4 pb-2">
                                                    <a href={`/menu-we-website/${panggilan}`} className="btn btn-webew-product" aria-label="Kembali webew.id" style={{width:"auto"}}><i className="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                                                    <h3 className="text-center fw-bold" style={{marginTop:"10px"}}>Website Tema {jenisWeWebsiteParams}</h3>
                                                    <p className="text-center fw-normal">Website portofolio yang memiliki 1 halaman dan sudah responsive di segala device dan juga terdapat Animate On Scroll.</p>
                                                    <div className="row justify-content-center">
                                                        <div className="col-6 mb-4">
                                                            {kodeOrder === jenisWeWebsiteParams
                                                                ?<a href={`menu-we-website-download/${panggilan}`} className="btn btn-webew-product"><i className="bi bi-check-lg" style={{color:"white"}}></i> Sudah Dibeli</a> 
                                                                :<form onSubmit={transaksi}>
                                                                    <input type="text" className="form-disable" value={name} readOnly/>
                                                                    <input type="text" className="form-disable" value={order_id} readOnly/>
                                                                    <input type="text" className="form-disable" value={total} readOnly/>
                                                                    <input type="text" className="form-disable" value={panggilan} readOnly/>
                                                                    <input type="submit" className="btn btn-webew-product" value="Beli Rp 5.000"/>
                                                                </form>
                                                            }
                                                        </div>
                                                        <div className="col-6 mb-4">
                                                            {kodeOrder === jenisWeWebsiteParams
                                                                ?<a href={linkDownload} className="btn btn-webew-product"><i className="bi bi-download" style={{color:"white"}}></i> Unduh</a>
                                                                :<a href={`/menu-we-website-download/${panggilan}`} className="btn btn-webew-product"><i className="bi bi-lock-fill" style={{color:"white"}}></i> Unduh</a> 
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="box mt-4">
                                                    <img src="https://webew.id/img/banner.png" alt="banner webew.id" className='mx-auto d-block' style={{width:"100%",maxHeight:"35.4vh",borderRadius:"20px"}}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 ">
                                            <div className="box"style={{maxHeight: "88vh",overflow: "auto",borderRadius: "10px",border:"30px solid white"}}>
                                                <img src={imageWeb} className="img-fluid mx-auto d-block" width="480px" alt="website porto g1"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // TUTUP KURUNG RETURN
} // TUTUP KURUNG MENU WE WEBSITE DOWNLOAD
// EKSPOR
export default MenuWeWebsiteDownload