// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT JWT DECODE
import jwt_decode from 'jwt-decode'
// IMPORT USE PARAMS, USE NAVIGATE
import { useParams, useNavigate } from 'react-router-dom'
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU WE KOMPRES
const MenuWeRemoveBg = () => { // KURUNG BUKA MENU WE KOMPRES
    // MEMBUAT VARIABEL WE KOMPRES
    const { panggilanParams } = useParams()
    const [selectedOption, setSelectedOption] = useState('image') // Menyimpan pilihan (image atau pdf)
    const [displayLoader, setDisplayLoader] = useState('')
    const [compressedImage, setCompressedImage] = useState([])
    const [compressedFile, setCompressedFile] = useState([])
    const [email, setEmail] = useState('')
    const [panggilan, setPanggilan] = useState('')
    // MEMBUAT VARIABEL EXPIRE TOKEN
    const [expire, setExpire] = useState('')
    // MEMBUAT VARIABEL MESSAGE
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL TOKEN
    const [, setToken] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/tokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setEmail(decoded.email)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader('none')
        } catch (error) {
            if(error.response){
                navigate('/login/menu-we-kompres', { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/tokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setEmail(decoded.email)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES HANDLE IMAGE DAN PDF UPLOAD
    const handleFileUpload = async (event) => {
        const files = event.target.files
        const formData = new FormData()
        Array.from(files).forEach((file) => {
            formData.append(file.type.includes('image') ? "foto" : "file", file) // Menambahkan file sesuai tipe (gambar atau PDF)
        })
    
        setDisplayLoader("") // Menampilkan loader
        try {
            const isImage = files[0].type.includes('image') // Mengecek tipe file pertama untuk menentukan apakah gambar atau PDF
            const url = isImage ? "https://server13.webew.id/compressImageBE" : "https://server13.webew.id/compressPdfBE" // Menentukan URL berdasarkan tipe file
    
            const response = await axios.post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
    
            if (response.data) {
                if (isImage && Array.isArray(response.data.images)) {
                    // Menangani respons kompresi gambar
                    const imageUrls = response.data.images.map((imageData) =>
                        URL.createObjectURL(new Blob([new Uint8Array(atob(imageData).split("").map(c => c.charCodeAt(0)))], { type: 'image/jpeg' }))
                    )
                    setCompressedImage(imageUrls)
                } else if (!isImage && Array.isArray(response.data.pdfs)) {
                    // Menangani respons kompresi PDF
                    const pdfUrls = response.data.pdfs.map((pdfData) =>
                        URL.createObjectURL(new Blob([new Uint8Array(atob(pdfData).split("").map(c => c.charCodeAt(0)))], { type: 'application/pdf' }))
                    )
                    setCompressedFile(pdfUrls)
                }
            }
            setDisplayLoader("none") // Menghilangkan loader
        } catch (error) {
            setDisplayLoader("none") // Menghilangkan loader jika terjadi error
            if (error.response) {
                setMsg(`${error.response.data.msg}`)
            } else if (error.request) {
                setMsg("Tidak ada respons dari server, coba lagi nanti.")
            } else {
                setMsg(`Terjadi kesalahan: ${error.message}`)
            }
        }
    }    
    // PROSES DOWNLOAD FOTO DAN PDF
    const downloadCompressedFiles = (type) => {
        const files = type === 'image' ? compressedImage : compressedFile
        const extension = type === 'image' ? 'jpg' : 'pdf'
    
        files.forEach((file, index) => {
            const link = document.createElement("a")
            link.href = file
            link.download = `${type === 'image' ? 'compressed_image' : 'compressed_file'}_${index + 1}.${extension}`
            link.click()
        })
    }
    // PROSES UNTUK KEMBALI UPLOAD
    const handleButtonUploadLagi = () => {
        window.location.reload()
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id='wrapper'>
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR*/}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAP */}
                <div id='content-wrapper'>
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP NAVBAR */}
                    {/* KONTEN */}
                        <div className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center' style={{fontWeight:'700'}}>We Kompres</h4>
                                            <p className='text-center fw-semibold m-0'>Perkecil Size Foto Dan PDF Dengan We Kompres</p>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mb-5 pb-4">
                                        {/* Pilihan untuk memilih jenis file yang akan dikompres */}
                                        {compressedImage.length === 0 && compressedFile.length === 0 && (
                                            <div className="col-12 col-md-6 col-lg-5 col-xl-4 mb-4">
                                                <div className="box px-4 py-2">
                                                    <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                                    <h6 className="text-center fw-normal mb-3">Pilih Jenis File Yang Akan Di Kompres</h6>
                                                    <select className="form form-select mb-4" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                                                        <option value="image">Kompres Foto</option>
                                                        <option value="pdf">Kompres PDF</option>
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        {/* Form upload untuk Foto */}
                                        {selectedOption === 'image' && (
                                            compressedImage.length === 0 ? (
                                                <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                                    <div className="box px-4 py-2">
                                                        <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                                        <h6 className="text-center fw-normal mb-3">Upload Maksimal 10 File Foto</h6>
                                                        <input type="file" accept="image/*" multiple className="form form-control mb-4" onChange={handleFileUpload} style={{ backgroundImage: "linear-gradient(to top,rgb(11, 97, 255) 20%,rgb(99, 154, 255) 100%)", color: "white" }} />
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-11 col-lg-10 mb-4">
                                                        <div className="box p-4" style={{ width: '100%' }}>
                                                            <div className="row mb-4">
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <button onClick={handleButtonUploadLagi} className="btn btn-webew-product">
                                                                        <i className="bi bi-arrow-left-circle-fill"></i> Upload Foto Lagi
                                                                    </button>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <button onClick={() => downloadCompressedFiles('image')} className="btn btn-webew-product">
                                                                        <i className="bi bi-download"></i> Download Semua
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                {compressedImage.map((image, index) => (
                                                                    <div className="col-6 col-sm-4 col-lg-2" key={index}>
                                                                        <img src={image} className="rounded" alt={`Compressed ${index + 1}`} style={{ maxWidth: "100%", marginBottom: "10px" }} />
                                                                        <p className="text-center mt-2">File Terkompres {index + 1}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                        {/* Form upload untuk PDF */}
                                        {selectedOption === 'pdf' && (
                                            compressedFile.length === 0 ? (
                                                <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                                                    <div className="box px-4 py-2">
                                                        <p className="text-center" style={{ color: "red" }}>{msg}</p>
                                                        <h6 className="text-center fw-normal mb-3">Upload Maksimal 10 File PDF</h6>
                                                        <input type="file" accept="application/pdf" multiple className="form form-control mb-4" onChange={handleFileUpload} style={{ backgroundImage: "linear-gradient(to top, rgb(11, 97, 255) 20%, rgb(99, 154, 255) 100%)", color: "white" }}/>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-11 col-lg-10 mb-4">
                                                        <div className="box p-4" style={{ width: '100%' }}>
                                                            <div className="row mb-4">
                                                                <div className="col-12 col-sm-6 mb-3">
                                                                    <button onClick={handleButtonUploadLagi} className="btn btn-webew-product">
                                                                        <i className="bi bi-arrow-left-circle-fill"></i> Upload File Lagi
                                                                    </button>
                                                                </div>
                                                                <div className="col-12 col-sm-6">
                                                                    <button onClick={() => downloadCompressedFiles('file')} className="btn btn-webew-product">
                                                                        <i className="bi bi-download"></i> Download Semua
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="row justify-content-center">
                                                                {compressedFile.map((file, index) => (
                                                                    <div className="col-6 col-sm-4 col-lg-2" key={index}>
                                                                        <img src='http://localhost:3000/img/pdf_logo.jpg' className='mx-auto d-block' style={{width: '100%',minHeight:'90px'}} alt='Logo PDF'/>
                                                                        <p className="text-center mt-2">File Terkompres {index + 1}</p>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAP */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU WE KOMPRES
// EKSPOR
export default MenuWeRemoveBg