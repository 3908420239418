import React,{ useState, useEffect } from 'react'
import axios from "axios"
import jwt_decode from "jwt-decode"
import { useNavigate, Link } from "react-router-dom"

const MenuHistoryProposal = () => {
    // Variabel sistem utama
    const [panggilan, setPanggilan] = useState('')
    const [proposal, setProposal] = useState([])
    const [msg, setMsg] = useState("")
    const [displayLoader, setDisplayLoader] = useState("")
    const [expire, setExpire] = useState('')
    const [, setToken] = useState('')
    const navigate = useNavigate()
    // Sistem utama
    useEffect(()=>{
        refreshToken()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(()=>{
        selectAllDataProposal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const refreshToken = async() => {
        try {
            const response = await axios.get('https://server13.webew.id/MapTokenBE')
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/menu-login-map", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get('https://server13.webew.id/MapTokenBE')
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    const Logout = async() =>{
        try {
            await axios.delete('https://server13.webew.id/MapLogoutBE')
            navigate("/menu-login-map", { replace: true })
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    const selectAllDataProposal = async() =>{
        try{
            const response = await axios.get('https://server13.webew.id/SelectAllDataProposalBE')
            setProposal(response.data)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // Halaman
    return (
        <div id="wrapper">
            {/* LOADER */}
            <div className="loader-page-bg" style={{display:displayLoader}}>
                <div className="loader-page"></div>
            </div>
            {/* SIDEBAR */}
            <nav className="navbar-expand-sm bg-navbar">
                <div className="dropdown ml-2"></div>
                <div className="collapse navbar-collapse" id="sidebar">
                    <ul className="navbar-nav sidebar" id="accordionSidebar">
                        <div className="navbar-brand d-flex align-items-center justify-content-center">
                            <img src="https://webew.id/img/map/newLogoMap.png" alt="logo map" className="d-block mx-auto" style={{width:"100px",marginTop:"5px"}}/>
                        </div>
                        <div className="sidebar-heading" style={{marginTop:"10px"}}>Menu Utama</div>
                        <li className="nav-item nav-itemm">
                            <Link className="nav-link nav-linkk" to={'/menu-dashboard-promap'}><i className="bi bi-columns-gap"></i><span>Dashboard</span></Link>
                        </li>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#proposal" aria-expanded="false" aria-controls="proposal">
                            Buat Proposal <i className="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div className="collapse" id="proposal">
                            <div className="card card-body p-0 pb-3 pt-1">
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TA/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Tax Audit</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TC/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Tax Compliance</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TR/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Tax Review</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-TP/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>TP Doc</span></Link>
                                </li>
                                <li className="nav-item nav-itemm">
                                    <Link className="nav-link nav-linkk" to={`/menu-bikin-proposal-PP/${panggilan}`}><i className="bi bi-file-earmark-text-fill"></i><span>Pendamping Pajak</span></Link>
                                </li>
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#perjanjian" aria-expanded="false" aria-controls="perjanjian">
                            Buat Kontrak <i className="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div className="collapse" id="perjanjian">
                            <div className="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#bapp" aria-expanded="false" aria-controls="bapp">
                        {/* Berita Acara Penyelesaian Pekerjaan */}
                            Buat BAPP <i className="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div className="collapse" id="bapp">
                            <div className="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#invoice" aria-expanded="false" aria-controls="invoice">
                            Buat Invoice <i className="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div className="collapse" id="invoice">
                            <div className="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                        <button className="sidebar-heading pb-2 col-12" style={{marginTop:"10px",border:"none",backgroundColor:"white",color:"rgb(108, 108, 108)"}} type="button" data-bs-toggle="collapse" data-bs-target="#pelunasan" aria-expanded="false" aria-controls="pelunasan">
                            Buat Pelunasan <i className="bi bi-caret-down-fill" style={{color:"rgb(108, 108, 108)"}}></i>
                        </button>
                        <div className="collapse" id="pelunasan">
                            <div className="card card-body p-0 pb-3 pt-1">
                                
                            </div>
                        </div>
                    </ul>
                </div>
            </nav>
            {/* KONTEN WRAPPER */}
            <div id="content-wrapper">
                {/* NAVBAR */}
                <nav className="navbar navbar-expand-sm sticky-top nav-bar py-3">
                    <div className="container">
                        <div className="dropdown ml-2">
                            <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation" style={{border:"none"}}>
                                <i className="bi bi-grid-fill" style={{fontSize:"22px"}}></i>
                            </button>
                        </div>
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
                        <div className="d-flex" role="search">
                            <button onClick={Logout} className="btn btn-logout mx-3"><i className="bi bi-power" style={{color:"white",fontSize:"22px"}}></i></button>
                        </div>
                    </div>
                </nav>
                {/* KONTEN */}
                <div className="container-fluid" style={{overflow:"auto",display:"block",width:"100%",height:"88vh"}}>
                    <section id="home">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-10 px-1 mt-4">
                                    <h2 className="title-webew-home" style={{marginTop:"-1px"}}>History Proposal</h2>
                                    <p className="text-webew-home">Berikut adalah tabel dari history pembuatan proposal</p>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <div className="box p-4 mb-1" style={{maxHeight:"350px",overflow:"auto"}}>
                                        <table style={{width:"1000px"}}>
                                            <thead>
                                                <tr className='mt-2'>
                                                    <td style={{width:"15%",border:"2px solid rgb(108, 108, 108)"}}>
                                                        <p className='pt-3' style={{textAlign:"center",fontSize:"15px",fontWeight:"800"}}>Nomor</p>
                                                    </td>
                                                    <td style={{width:"30%",border:"2px solid rgb(108, 108, 108)"}}>
                                                        <p className='pt-3' style={{textAlign:"center",fontSize:"15px",fontWeight:"800"}}>Perusahaan</p>
                                                    </td>
                                                    <td style={{width:"30%",border:"2px solid rgb(108, 108, 108)"}}>
                                                        <p className='pt-3' style={{textAlign:"center",fontSize:"15px",fontWeight:"800"}}>Jenis Proposal</p>
                                                    </td>
                                                    <td style={{width:"25%",border:"2px solid rgb(108, 108, 108)"}}>
                                                        <p className='pt-3' style={{textAlign:"center",fontSize:"15px",fontWeight:"800"}}>Aksi</p>
                                                    </td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {proposal.map((user,index)=>(
                                                    <tr key={proposal.id} className='mt-2'>
                                                        <td style={{width:"20%",border:"2px solid rgb(108, 108, 108)"}}>
                                                            <p className='pt-3' style={{textAlign:"center",fontSize:"14px",fontWeight:"600"}}>{user.nomor}</p>
                                                        </td>
                                                        <td style={{width:"35%",border:"2px solid rgb(108, 108, 108)"}}>
                                                            <p className='pt-3' style={{textAlign:"center",fontSize:"14px",fontWeight:"600"}}>{user.perusahaanPenerima}</p>
                                                        </td>
                                                        <td style={{width:"30%",border:"2px solid rgb(108, 108, 108)"}}>
                                                            <p className='pt-3' style={{textAlign:"center",fontSize:"14px",fontWeight:"600"}}>{user.jenisProposal}</p>
                                                        </td>
                                                        <td style={{width:"15%",border:"2px solid rgb(108, 108, 108)"}} className="pt-3 px-3">
                                                            <Link to={`/menu-bikin-proposal-${user.kode}/${user.panggilan}`} className="btn btn-webew-product" style={{marginTop:"-20px"}}>Lihat</Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </section>
                </div>
            </div>
        </div>
    )
}

export default MenuHistoryProposal