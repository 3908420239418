// IMPORT REACT
import React from 'react'
// IMPORT LINK
import { Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// WE PERNIKAHAN
const WePernikahan = () => { // KURUNG BUKA WE PERNIKAHAN
    // PROSES LOOP JENIS WE PERNIKAHAN
    const jenisWePernikahan = [
        { viewLink: '/we-pernikahan/aglaonema', nama: 'Aglaonema', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/aglaonema.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/agapanthus', nama: 'Agapanthus', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/agapanthus.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/alamanda', nama: 'Alamanda', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/alamanda.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/amaryllis', nama: 'Amaryllis', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/amaryllis.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwI', nama: 'Bnw I', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw1.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwII', nama: 'Bnw II', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw2.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwIII', nama: 'Bnw III', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw3.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bnwIV', nama: 'Bnw IV', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bnw4.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blegold', nama: 'BleGold', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blegold.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blepink', nama: 'BlePink', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blepink.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/bletosca', nama: 'BleTosca', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/bletosca.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/blewhite', nama: 'BleWhite', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/blewhite.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/cute', nama: 'Cute', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cute.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/adorable', nama: 'Adorable', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/adorable.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/beauty', nama: 'Beauty', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/beauty.jpg', alt: 'Undangan Digital Pernikahan' },
        { viewLink: '/we-pernikahan/cool', nama: 'Cool', imgSrc: 'https://webew.id/img/undanganDigital/UndanganDigitalPernikahan/cool.jpg', alt: 'Undangan Digital Pernikahan' }
    ]
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className='background-home' style={{marginTop:'55px'}}>
            {/* INFO */}
                <section id='home'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 col-lg-6 px-3'>
                                <h2 className='title-webew-home'>We Pernikahan</h2>
                                <p className='text-webew-home fw-normal'>Buat undangan digital disini, tinggal pilih tema undangan digital, isi form data dengan benar, dan undangan digital sudah jadi</p>
                                <a href={'/'} className='btn btn-webew-home' aria-label='Kembali webew.id'><i class="bi bi-arrow-left-circle-fill"></i> Kembali</a>
                            </div>
                            <div className='col-12 col-lg-6'>
                                <img src='https://webew.id/img/19212427_Business people arranging appointment in digital booking app.webp' className='img-webew-home mx-auto d-block mt-4' style={{width: '100%',minHeight:'200px'}} alt='by Freepik on freepik'/>
                            </div>
                        </div>
                    </div>    
                </section>
            {/* TUTUP INFO */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* JENIS WE PERNIKAHAN */}
                <section>
                    <div className='container'>
                        <div className="row justify-content-center">
                            <div className="col-10 col-sm-12 col-md-10 col-lg-8">
                                <div className="card mb-4 pt-3 px-2" style={{width:"100%"}}>
                                    <h5 className='card-title fw-bold text-center mt-2 mb-3'>Fitur harga Rp45.000</h5>
                                    <div style={{backgroundColor:"grey", width:"100%", height:"1px"}}></div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12 col-sm-6">
                                                <p><i class="bi bi-check2-circle"></i> Halaman Penerimaan Tamu</p>
                                                <p><i class="bi bi-check2-circle"></i> Countdown Acara Pernikahan</p>
                                                <p><i class="bi bi-check2-circle"></i> Profil Pasangan Dengan Detail</p>
                                                <p><i class="bi bi-check2-circle"></i> Social Media Pengantin</p>
                                                <p><i class="bi bi-check2-circle"></i> Simpan Kalender Acara</p>
                                                <p><i class="bi bi-check2-circle"></i> Rute Google Maps</p>
                                                <p><i class="bi bi-check2-circle"></i> Pilihan Ayat Al-Quran</p>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <p><i class="bi bi-check2-circle"></i> Gallery Pengantin (Max 6 Foto)</p>
                                                <p><i class="bi bi-check2-circle"></i> Kado Pengantin (Cashless)</p>
                                                <p><i class="bi bi-check2-circle"></i> Ucapan Dan Doa Live</p>
                                                <p><i class="bi bi-check2-circle"></i> Musik Indonesia, Inggris, Islami</p>
                                                <p><i class="bi bi-check2-circle"></i> Isi Data Sendiri</p>
                                                <p className='fw-bold'><i class="bi bi-check2-circle"></i> Masa AktiF Selamanya</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            {jenisWePernikahan.map((data, index) => (
                                <div key={index} className='col-12 col-sm-6 col-md-4 col-lg-3'>
                                    <div className='card mb-4 pt-3 pb-2 px-2' style={{ width: '100%' }}>
                                        <h5 className='card-title fw-bold text-center mt-2'>{data.nama}</h5>
                                        <img src={data.imgSrc} className='mx-auto d-block mt-2' style={{ maxHeight: '250px' }} alt={data.alt} />
                                        <div className='card-body'>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <a href={data.viewLink} className='btn btn-webew-product my-1' aria-label='Produk webew.id'>Lihat</a>
                                                </div>
                                                <div className='col-6'>
                                                    <a href={'https://webew.id/login/menu-we-pernikahan'} className='btn btn-webew-product my-1' aria-label='Produk webew.id'>Buat</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            {/* TUTUP JENIS WE PERNIKAHAN */}
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            {/* CREDIT IMAGE */}
                <section id='credit-image'>
                    <div className='container mb-4'>
                        <h3 className='judul-credit text-center'>Image By</h3>
                        <div className='row justify-content-center'>
                            <div className='col-8 col-sm-6 col-md-4 col-lg-3 mb-3'>
                                <a href='https://www.freepik.com/free-vector/business-people-arranging-appointment-digital-booking-app_19212427.htm#page=4&query=app&position=22&from_view=author' aria-label='Link mengarah ke pembuat image yaitu Pch-vector Freepik'>
                                    <div className='card' style={{width: '100%',minHeight:'150px'}}>
                                        <img src='https://webew.id/img/credit/19212427_Business people arranging appointment in digital booking app.webp' className='mx-auto d-block'  style={{width: '100%',minHeight:'120px'}} alt='by Pch-vector on Freepik'/>
                                        <div className='card-body'>
                                            <p className='text-center'>Pch-vector on Freepik</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            {/* TUTUP CREDIT IMAGE */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP WE PERNIKAHAN
// EKSPOR
export default WePernikahan