// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from 'axios'
// IMPORT USE NAVIGATE, LINK
import { useParams, useNavigate, Link } from 'react-router-dom'
// IMPORT NAVBAR BOTTOM
import NavbarBot from '../../Layout/NavbarBot.jsx'
// REGISTER
const Register = () => { // KURUNG BUKA REGISTER
    const {jenisAplikasiParams} = useParams()
    // MEMBUAT VARIABEL REGISTER
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confPassword, setConfPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [pertanyaan, setPertanyaan] = useState('')
    const [jawaban, setJawaban] = useState('')
    // MEMBUAT VARIABEL MESSAGE DARI BACKEND
    const [msg, setMsg] = useState('')
    // MEMBUAT VARIABEL LOADER HALAMAN
    const [displayLoader, setDisplayLoader] = useState('')
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USE EFFECT
    useEffect(()=>{
        setDisplayLoader('none')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES REGISTER
    const register = async(e)=>{
        e.preventDefault()
        try {
            setDisplayLoader('')
            await axios.post('https://server13.webew.id/usersBE',{
                name: name,
                email: email,
                password: password,
                confPassword: confPassword,
                pertanyaan: pertanyaan,
                jawaban: jawaban
            })
            navigate(`/login/${jenisAplikasiParams}`, { replace: true })
            window.location.reload()
        } catch (error) {
            if(error.response){
                setDisplayLoader('none')
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES HANDLE PASSWORD VISIBILITY
    const handlePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    }
    // RETURN
    return ( // BUKA KURUNG RETURN
        <div>
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,marginTop:"-110px",backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* BOX REGISTER */}
                <div className='mb-3' style={{marginTop:'25px'}}>
                    {/* MODAL PERTANYAAN */}
                        <div className='modal fade' id='staticBackdropDaftar' data-bs-backdrop='static' data-bs-keyboard='false' tabIndex='-1' aria-labelledby='staticBackdropLabel' aria-hidden='true'>
                            <div className='modal-dialog modal-dialog-centered'>
                                <div className='modal-content'>
                                    <div className='modal-header'>
                                        <button type='button' className='btn btn-logout mx-auto py-1 px-3' data-bs-dismiss='modal' aria-label='Kembali'>Kembali</button>
                                    </div>
                                    <div className='modal-body'>
                                        <form onSubmit={register}>
                                            <h6 style={{textAlign:'center',marginTop:'-8px',marginBottom:'0px',color:'red'}}>{msg}</h6>
                                            <div className='row justify-content-center'>
                                                <input type='email' id='emailModal' className='form-disable' placeholder='Masukan email' value={email} onChange={(e) => setEmail(e.target.value)} required autoFocus/>
                                                <input type='text' id='namaModal' className='form-disable' placeholder='Masukan Nama' value={name} onChange={(e) => setName(e.target.value)} required/>
                                                <input type='password' id='passwordModal' className='form-disable' placeholder='Buat password baru' value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                                <input type='password' id='confPasswordModal' className='form-disable' placeholder='Ulangi password' value={confPassword} onChange={(e) => setConfPassword(e.target.value)} required/>
                                                <p className='text-center mt-3'>Isi pertanyaan dan jawaban dibawah ini, ini akan digunakan jika lupa password, jadi jangan sampe lupa yaa</p>
                                                <div className='row'>
                                                    <div className='col-12 col-sm-6 mb-4'>
                                                        <select className='form-select form' id='pertanyaanModal' aria-label='pertanyaan' value={pertanyaan} onChange={(e)=>setPertanyaan(e.target.value)} required>
                                                            <option defaultValue='Lainnya'>Pilih Pertanyaan</option>
                                                            <option defaultValue='Nama ibu kandung kamu ?'>Nama ibu kandung kamu ?</option>
                                                            <option defaultValue='Nama peliharaan kamu ?'>Nama peliharaan kamu ?</option>
                                                            <option defaultValue='Makanan favorit kamu ?'>Makanan favorit kamu ?</option>
                                                            <option defaultValue='Minuman favorit kamu ?'>Minuman favorit kamu ?</option>
                                                            <option defaultValue='Tempat favorit kamu ?'>Tempat favorit kamu ?</option>
                                                            <option defaultValue='Apa hobi kamu ?'>Apa hobi kamu ?</option>
                                                            <option defaultValue='Lainnya'>Lainnya</option>
                                                        </select>
                                                    </div>
                                                    <div className='col-12 col-sm-6'>
                                                        <input type='jawaban' id='jawabanModal' className='form-control form mb-4' placeholder='Jawaban kamu' value={jawaban} onChange={(e) => setJawaban(e.target.value)} required/>
                                                    </div>
                                                </div>
                                            </div>
                                            <input type='submit' className='btn btn-webew-login mb-4 mt-2' value='Daftar'/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* TUTUP MODAL PERTANYAAN */}
                    {/* FORM REGISTER */}
                        <div className='container mb-3' style={{marginTop:'110px'}}>
                            <div className='row justify-content-center'>
                                <div className='col-12 col-xl-10'>
                                    <div className='box p-4'>
                                        <div className='row justify-content-center'>
                                            <div className='col-11 col-md-6 col-lg-6 order-1 order-md-0'>
                                                <h4 className='text-center mb-4' style={{fontWeight:'700',marginTop:'10px'}}>Silahkan Daftar</h4>
                                                <h6 style={{textAlign:'center',marginTop:'-8px',marginBottom:'0px',color:'red'}}>{msg}</h6>
                                                <div className='row justify-content-center'>
                                                    <div className='col-12 col-sm-6'>
                                                        <input type='email' id='email' className='form-control form mb-3 mt-4' placeholder='Masukan email' value={email} onChange={(e) => setEmail(e.target.value)} required autoFocus/>
                                                    </div>
                                                    <div className='col-12 col-sm-6'>
                                                        <input type='text' id='nama' className='form-control form mb-3 mt-4' placeholder='Masukan Nama' value={name} onChange={(e) => setName(e.target.value)} required/>
                                                    </div>
                                                    <div className='col-12 col-sm-6'>
                                                        <div className="password-container" style={{ position: 'relative' }}>
                                                            <input type={showPassword ? 'text' : 'password'} id='password' className='form-control form mb-3 mt-4' placeholder='Buat password baru' value={password} onChange={(e) => setPassword(e.target.value)} required style={{ paddingRight: '40px' }}/>
                                                            <span onClick={handlePasswordVisibility} style={{position: 'absolute',right: '10px',top: '50%',transform: 'translateY(-50%)',cursor: 'pointer',color: 'gray'}}>{showPassword ? '👀' : '🙈'}</span>
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-sm-6'>
                                                        <div className="password-container" style={{ position: 'relative' }}>
                                                            <input type={showPassword ? 'text' : 'password'} id='confPassword' className='form-control form mb-3 mt-4' placeholder='Ulangi password' value={confPassword} onChange={(e) => setConfPassword(e.target.value)} required style={{ paddingRight: '40px' }}/>
                                                            <span onClick={handlePasswordVisibility} style={{position: 'absolute',right: '10px',top: '50%',transform: 'translateY(-50%)',cursor: 'pointer',color: 'gray'}}>{showPassword ? '👀' : '🙈'}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                { password.length === 0 ? <p></p> : password.length >= 8 ? <p></p> : <p style={{marginLeft:'10px',marginTop:'-15px',marginBottom:'-5px',color:'red',fontSize:'10pt'}}>Password minimal 8 karakter</p>}
                                                { password.length >= 8 ? <p style={{marginLeft:'10px',marginTop:'-30px',marginBottom:'10px',color:'blue',fontSize:'10pt'}}>Password sudah 8 karakter</p> : <p></p>}
                                                {password.length <= 7 ?<button type='button' className='btn btn-webew-login mb-4 mt-2'>Lanjut</button>:<button type='button' className='btn btn-webew-login mb-4 mt-2' data-bs-toggle='modal' data-bs-target='#staticBackdropDaftar'>Lanjut</button>}
                                                <a href={`/login/${jenisAplikasiParams}`} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Sudah punya akun ? login disini</a>
                                                <a href={`/lupa-password/${jenisAplikasiParams}`} className='fw-semibold' style={{color:'rgb(0, 86, 247)', margin:'auto', display:'block', textAlign:'center', fontSize:'10pt'}}>Lupa Password ? klik disini</a>
                                            </div>
                                            <div className='col-11 col-md-6 col-lg-5 order-0 order-md-1 mt-2 mb-3'>
                                                <img src='https://webew.id/img/4957136_4957136.jpg' alt='login' style={{width:'100%',marginTop:'0px'}}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* TUTUP FORM REGISTER */}
                </div>
            {/* TUTUP BOX REGISTER */}
            {/* NAVBAR BOTTOM */}
                <NavbarBot/>
            {/* TUTUP NAVBAR BOTTOM */}
            {/* LINK CREDIT FOTO */}
                <a href={'https://www.freepik.com/free-vector/mobile-login-concept-illustration_4957136.htm#query=login%20vector&position=3&from_view=search&track=ais&uuid=3af634d7-f497-4f14-9cc2-9bfe74419701'}><p className='text-center fw-semibold' style={{fontSize:'9pt',color:'#d1d1d1',marginBottom:'0px'}}>Image by storyset</p></a>
            {/* TUTUP LINK CREDIT FOTO */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP REGISTER
// EKSPOR
export default Register