// IMPORT REACT
import React,{ useState, useEffect } from "react"
// IMPORT AXIOS
import axios from "axios"
// IMPORT JWT DECODE
import jwt_decode from "jwt-decode"
// IMPORT USE PARAMS, USE NAVIGATE, LINK
import { useParams, useNavigate, Link } from "react-router-dom"
// IMPORT MENU SIDEBAR
import MenuSidebar from '../Layout/MenuSideBar.jsx'
// IMPORT MENU NAVBAR
import MenuNavbar from '../Layout/MenuNavbar.jsx'
// IMPORT MENU NAVBAR BOTTOM
import MenuNavbarBot from '../Layout/MenuNavbarBot.jsx'
// IMPORT MENU DONASI
import MenuDonasi from '../Layout/MenuDonasi.jsx'
// MENU DASHBOARD
const MenuDashboard = () => { // KURUNG BUKA MENU DASHBOARD
    // MEMBUAT VARIABEL DASHBOARD
    const { panggilanParams } = useParams();
    const [displayLoader, setDisplayLoader] = useState("")
    const [urlFoto, setUrlFoto] = useState("")
    const [name, setName] = useState("")
    const [kata, setKata] = useState("")
    const [jenisMotivasi, setJenisMotivasi] = useState("")
    const [panggilan, setPanggilan] = useState("")
    const [expire, setExpire] = useState("")
    const [motivasi, setMotivasi] = useState([])
    const [, setToken] = useState("")
    const [dataResponse1, setDataResponse1] = useState([])
    const [dataResponse2, setDataResponse2] = useState([])
    const [dataResponse3, setDataResponse3] = useState([])
    const [dataResponse4, setDataResponse4] = useState([])
    const [dataResponse5, setDataResponse5] = useState([])
    const [dataResponse6, setDataResponse6] = useState([])
    const [display, setDisplay] = useState("show")
    const [displayPendidikan, setDisplayPendidikan] = useState("")
    const [displayPekerjaan, setDisplayPekerjaan] = useState("")
    const [displayKehidupan, setDisplayKehidupan] = useState("")
    const [displayPercintaan, setDisplayPercintaan] = useState("")
    const [displayEmosional, setDisplayEmosional] = useState("")
    const [msg, setMsg] = useState("")
    // MEMBUAT VARIABEL NAVIGATE BY USE NAVIGATE
    const navigate = useNavigate()
    // USEEFFECT
    useEffect(()=>{
        refreshToken()
        selectWeMotivasi()
        selectWeUsers()
        selectWeMotivasiSaya()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // REFRESH TOKEN
    const refreshToken = async() => {
        try {
            const response = await axios.get("https://server13.webew.id/tokenBE")
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setName(decoded.name)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
            setDisplayLoader("none")
        } catch (error) {
            if(error.response){
                navigate("/login/menu-we-motivasi", { replace: true })
            }   
        }
    }
    const axiosJWT = axios.create()
    axiosJWT.interceptors.request.use(async(config) => {
        const currentDate = new Date()
        if(expire * 1000 < currentDate.getTime()){
            const response = await axios.get("https://server13.webew.id/tokenBE")
            config.headers.Authorization = `Bearer ${response.data.accessToken}`
            setToken(response.data.accessToken)
            const decoded = jwt_decode(response.data.accessToken)
            setName(decoded.name)
            setPanggilan(decoded.panggilan)
            setExpire(decoded.exp)
        }
        return config
    }, (error)=>{
        return Promise.reject(error)
    })
    // PROSES MENYIMPAN MOTIVASI
    const createWeMotivasi = async (e) => {
        e.preventDefault()
        try {
            await axios.post('https://server13.webew.id/createWeMotivasiBE',{
                urlFoto: urlFoto,
                name: name,
                kata: kata,
                jenisMotivasi: jenisMotivasi,
                panggilan: panggilan,
            })
            setKata("")
            selectWeMotivasi()
            selectWeUsers()
            selectWeMotivasiSaya()
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN DATA MOTIFASI BERDASARKAN JENIS MOTIVASI
    const selectWeMotivasi = async() =>{
        try{
            const response1 = await axios.get('https://server13.webew.id/selectAllWeMotivasiBE')
            const response2 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiPendidikan')
            const response3 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiPekerjaan')
            const response4 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiKehidupan')
            const response5 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiPercintaan')
            const response6 = await axios.get('https://server13.webew.id/selectWeMotivasiByJenisMotivasiBE/MotivasiEmosional')
            setDataResponse1(response1.data)
            setDataResponse2(response2.data)
            setDataResponse3(response3.data)
            setDataResponse4(response4.data)
            setDataResponse5(response5.data)
            setDataResponse6(response6.data)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN MOTIVASI BERDASARKAN PANGGILAN BY PARAMS
    const selectWeMotivasiSaya = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeMotivasiSayaBE/${panggilanParams}`)
            setMotivasi(response.data)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENAMPILKAN USERS BERDASARKAN PANGGILAN BY PARAMS
    const selectWeUsers = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeUsersBE/${panggilanParams}`)
            setUrlFoto(response.data.urlFoto)
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // PROSES MENGHAPUR MOTIVASI BERDASARKAN ID
    const deleteWeMotivasi = async (id) => {
        try {
            await axios.delete(`https://server13.webew.id/deleteWeMotivasiBE/${id}`)
            selectWeMotivasi()
            selectWeUsers()
            selectWeMotivasiSaya()
        } catch (error) {
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // BUTTON SHOW ALL MOTIVASI
    const btnMotivasi = () => {
        setDisplay("show")
        setDisplayPendidikan("")
        setDisplayPekerjaan("")
        setDisplayKehidupan("")
        setDisplayPercintaan("")
        setDisplayEmosional("")
    }
    // BUTTON SHOW MOTIVASI PENDIDIKAN
    const btnMotivasiPendidikan = () => {
        setDisplayPendidikan("show")
        setDisplay("")
        setDisplayPekerjaan("")
        setDisplayKehidupan("")
        setDisplayPercintaan("")
        setDisplayEmosional("")
    }
    // BUTTON SHOW MOTIVASI PEKERJAAN
    const btnMotivasiPekerjaan = () => {
        setDisplayPekerjaan("show")
        setDisplay("")
        setDisplayPendidikan("")
        setDisplayKehidupan("")
        setDisplayPercintaan("")
        setDisplayEmosional("")
    }
    // BUTTON SHOW MOTIVASI KEHIDUPAN
    const btnMotivasiKehidupan = () => {
        setDisplayKehidupan("show")
        setDisplay("")
        setDisplayPendidikan("")
        setDisplayPekerjaan("")
        setDisplayPercintaan("")
        setDisplayEmosional("")
    }
    // BUTTON SHOW MOTIVASI PERCINTAAN
    const btnMotivasiPercintaan = () => {
        setDisplayPercintaan("show")
        setDisplay("")
        setDisplayPendidikan("")
        setDisplayPekerjaan("")
        setDisplayKehidupan("")
        setDisplayEmosional("")
    }
    // BUTTON SHOW MOTIVASI EMOSIONAL
    const btnMotivasiEmosional = () => {
        setDisplayEmosional("show")
        setDisplay("")
        setDisplayPendidikan("")
        setDisplayPekerjaan("")
        setDisplayKehidupan("")
        setDisplayPercintaan("")
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div id="wrapper">
            {/* LOADER */}
            <div className="loader-page-bg-2" style={{display:displayLoader,backgroundColor:"#282a3a8b",backdropFilter: "blur(10px)"}}>
                <div className="loader-page-2" style={{border: "10px solid #ffffff",borderTop:"10px solid rgb(11, 97, 255)"}}></div>
                <p style={{fontSize:"20pt",color:"#ffffff",fontWeight:"700",marginLeft:"20px",marginTop:"13px",textAlign:"center"}}>Tunggu Yaa</p>
            </div>
            {/* TUTUP LOADER */}
            {/* MODAL DONASI */}
                <MenuDonasi/>
            {/* TUTUP MODAL DONASI */}
            {/* SIDEBAR*/}
                <MenuSidebar/>
            {/* TUTUP SIDEBAR */}
            {/* KONTEN WRAP */}
                <div id="content-wrapper">
                    {/* NAVBAR */}
                        <MenuNavbar/>
                    {/* TUTUP MENU NAVBAR */}
                    {/* KONTEN */}
                        <div  className="px-1"style={{overflow:'auto',width:'100%',minHeight:'97vh',maxHeight:'97vh'}}>
                            <div className='d-flex flex-column justify-content-center align-items-center'style={{width:'100%',minHeight:'100vh'}}>
                                <div className="container-fluid">
                                    <div className="row my-3">
                                        <div className="col-12 p-2">
                                            <h4 className='text-center' style={{fontWeight:'700'}}>We Motivasi</h4>
                                            <p className='text-center fw-semibold'>Buat Dan Lihat Kata Motivasi Dengan We Motivasi</p>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center mb-5 pb-1">
                                        <div className="col-12 col-md-6 order-1 order-md-0">
                                            <div className="dropdown-center mb-4">
                                                <button className="btn btn-webew-product" type="button" data-bs-toggle="dropdown" aria-expanded="false" style={{padding:"7px 0 7px 0"}}><i className="bi bi-filter" style={{color:"white"}}></i> Sortir</button>
                                                <ul className="dropdown-menu text-center mt-3">
                                                    <li><button onClick={btnMotivasi} className="dropdown-item" aria-label="Motivasi" style={{fontSize:"13pt"}}>Semua Motivasi</button></li>
                                                    <li><button onClick={btnMotivasiPendidikan} className="dropdown-item" aria-label="Motivasi" style={{fontSize:"13pt"}}>Motivasi Pendidikan</button></li>
                                                    <li><button onClick={btnMotivasiPekerjaan} className="dropdown-item" aria-label="Motivasi" style={{fontSize:"13pt"}}>Motivasi Pekerjaan</button></li>
                                                    <li><button onClick={btnMotivasiKehidupan} className="dropdown-item" aria-label="Motivasi" style={{fontSize:"13pt"}}>Motivasi Kehidupan</button></li>
                                                    <li><button onClick={btnMotivasiPercintaan} className="dropdown-item" aria-label="Motivasi" style={{fontSize:"13pt"}}>Motivasi Percintaan</button></li>
                                                    <li><button onClick={btnMotivasiEmosional} className="dropdown-item" aria-label="Motivasi" style={{fontSize:"13pt"}}>Motivasi Emosional</button></li>
                                                </ul>
                                            </div>
                                            <table style={{display:display}}>
                                                { display === "show"
                                                    ?<tbody>
                                                        {dataResponse1.map((user,index)=>(
                                                            <tr key={dataResponse1.id}>
                                                                <td style={{width:"100%"}}>
                                                                    <div className="box p-3 mb-4">
                                                                        { user.urlFoto === null
                                                                            ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                            : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        }
                                                                        <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :""
                                                }
                                                { displayPendidikan === "show"
                                                    ?<tbody>
                                                        {dataResponse2.map((user,index)=>(
                                                            <tr key={dataResponse2.id}>
                                                                <td style={{width:"100%"}}>
                                                                    <div className="box p-3 mb-4">
                                                                        { user.urlFoto === null
                                                                            ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                            : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        }
                                                                        <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :""
                                                }
                                                { displayPekerjaan === "show"
                                                    ?<tbody>
                                                        {dataResponse3.map((user,index)=>(
                                                            <tr key={dataResponse3.id}>
                                                                <td style={{width:"100%"}}>
                                                                    <div className="box p-3 mb-4">
                                                                        { user.urlFoto === null
                                                                            ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                            : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        }
                                                                        <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :""
                                                }
                                                { displayKehidupan === "show"
                                                    ?<tbody>
                                                        {dataResponse4.map((user,index)=>(
                                                            <tr key={dataResponse4.id}>
                                                                <td style={{width:"100%"}}>
                                                                    <div className="box p-3 mb-4">
                                                                        { user.urlFoto === null
                                                                            ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                            : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        }
                                                                        <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :""
                                                }
                                                { displayPercintaan === "show"
                                                    ?<tbody>
                                                        {dataResponse5.map((user,index)=>(
                                                            <tr key={dataResponse5.id}>
                                                                <td style={{width:"100%"}}>
                                                                    <div className="box p-3 mb-4">
                                                                        { user.urlFoto === null
                                                                            ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                            : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        }
                                                                        <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :""
                                                }
                                                { displayEmosional === "show"
                                                    ?<tbody>
                                                        {dataResponse6.map((user,index)=>(
                                                            <tr key={dataResponse6.id}>
                                                                <td style={{width:"100%"}}>
                                                                    <div className="box p-3 mb-4">
                                                                        { user.urlFoto === null
                                                                            ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                            : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        }
                                                                        <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                        <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    :""
                                                }
                                            </table>
                                        </div>
                                        <div className="col-12 col-md-6 order-0 order-md-1">
                                            <div className="box p-4">
                                                <h5 className="text-center mb-3" style={{fontWeight:"700"}}>Buat Motivasi</h5>
                                                <form onSubmit={createWeMotivasi}>
                                                    <div className="col-12 mb-3">
                                                        <label htmlFor="kata">Kata Motivasi</label>
                                                        <textarea type="text" id="kata" className="form-control form" value={kata} onChange={(e)=>setKata(e.target.value)} required></textarea>
                                                        <input type="text" id="urlFoto" className="form-disable" value={urlFoto} readOnly/>
                                                        <input type="text" id="name" className="form-disable" value={name} readOnly/>
                                                    </div>
                                                    <div className="col-12 mb-3">
                                                        <label htmlFor="jenisMotivasi">Jenis Motivasi</label>
                                                        <select className="form-select form" id="jenisMotivasi" aria-label="jenisMotivasi" value={jenisMotivasi} onChange={(e)=>setJenisMotivasi(e.target.value)} required>
                                                            <option value="MotivasiPendidikan">Motivasi Pendidikan</option>
                                                            <option value="MotivasiPekerjaan">Motivasi Pekerjaan</option>
                                                            <option value="MotivasiKehidupan">Motivasi Kehidupan</option>
                                                            <option value="MotivasiPercintaan">Motivasi Percintaan</option>
                                                            <option value="MotivasiEmosional">Motivasi Emosional</option>
                                                        </select>
                                                        <input type="text" id="panggilan" className="form-disable" value={panggilan} readOnly/>
                                                    </div>
                                                    <input type="submit" className="btn btn-webew-login mt-4" value="Upload Motivasi"/>
                                                </form>
                                            </div>
                                            <h5 className="text-center mt-4" style={{fontWeight:"700"}}>Motivasi Buatan Saya</h5>
                                            <table className="mt-4 mb-5">
                                                <tbody>
                                                    {motivasi.map((user,index)=>(
                                                        <tr key={motivasi.id} >
                                                            <td style={{width:"100%"}}>
                                                                <div className="box p-3 mb-4">
                                                                    { urlFoto === null
                                                                        ? <img src="https://webew.id/img/profil/default.jpg" className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                        : <img src={user.urlFoto} className="mx-auto d-block" alt="foto" style={{minWidth:"30px",maxWidth:"30px",borderRadius:"30px"}}/>
                                                                    }
                                                                    <p style={{backgroundColor:"white",textAlign:"center",borderRadius:"20px",padding:"5px 7px 5px 7px",fontSize:"13px",fontWeight:"700"}}>{user.name}</p>
                                                                    <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.kata}</p>
                                                                    <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"700"}}>#{user.jenisMotivasi}</p>
                                                                    <p style={{backgroundColor:"white",textAlign:"left",borderRadius:"20px",padding:"0px 10px 0px 10px",fontSize:"13px",fontWeight:"600"}}>{user.createdAt.substring(0,10)}</p>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <Link to={`/menu-we-motivasi-ubah/${user.panggilan}`} className="btn btn-webew-product mb-2">Ubah</Link>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <button type="button" onClick={()=> deleteWeMotivasi(user.id)} className="btn btn-webew-product mb-2">Hapus</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* NAVBOT */}
                            <MenuNavbarBot/>
                        </div>
                    {/* TUTUP KONTEN */}
                </div>
            {/* TUTUP KONTEN WRAPPER */}
        </div>
    ) // KURUNG TUTUP RETURN
} // KURUNG TUTUP MENU DASHBOARD
// EKSPOR
export default MenuDashboard