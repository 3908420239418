import React, {useState} from 'react'
import axios from 'axios'
import NavbarBot from '../../Layout/NavbarBot.jsx'

const Unlawful = () => {
    const [name,] = useState('webewers')
    const [saran, setSaran] = useState('')
    const [msg, setMsg] = useState('')
    const createSaran = async (e) => {
        e.preventDefault()
        try{
            await axios.post('https://server13.webew.id/createSaranBE',{
                saran: saran,
                nama: name
            })
            window.location.reload()
        } catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    return (
        <div className='background-home' style={{marginTop:'0px'}}>
            <section id='home'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1 className='title-webew-home'>Penanganan Unlawful Content</h1>
                        </div>
                    </div>
                </div>    
            </section>
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung'  style={{width: '100%',minHeight:'360px'}} alt='bg lengkung'/>
            <section id='about' style={{marginTop:'-70px'}}>
                <div className='container'>
                    <p className='text-webew-home px-4' style={{textAlign:"justify"}}>
                        <section className='mb-4'>
                            <h2 className='text-center'>BAB I: PENDAHULUAN</h2>
                            <h3>1.1. Latar Belakang</h3>
                            <p>
                                Kemajuan teknologi informasi dan komunikasi telah membawa banyak manfaat dalam berbagai aspek kehidupan manusia. Namun, di sisi lain, kemajuan ini juga membawa tantangan baru, salah satunya adalah munculnya konten ilegal atau “unlawful content” di dunia maya. Unlawful content merujuk pada segala bentuk konten yang melanggar hukum atau peraturan yang berlaku, termasuk namun tidak terbatas pada penyebaran hoaks, ujaran kebencian, pornografi, perjudian, pelanggaran hak cipta, dan aktivitas terlarang lainnya. Fenomena ini telah menjadi isu global yang mempengaruhi banyak negara, termasuk Indonesia.
                            </p>
                            <p>
                                Maraknya penyebaran unlawful content menimbulkan berbagai dampak negatif, baik secara sosial, ekonomi, maupun politik. Dalam konteks sosial, konten ilegal dapat memecah belah masyarakat, merusak moral generasi muda, dan memicu konflik. Secara ekonomi, konten semacam ini dapat mengurangi kepercayaan masyarakat terhadap ekosistem digital, yang pada akhirnya menghambat pertumbuhan ekonomi digital. Dari sisi politik, penyebaran konten ilegal dapat digunakan sebagai alat propaganda untuk menggoyahkan stabilitas negara.
                            </p>
                            <h3>1.2. Maksud dan Tujuan</h3>
                            <p>
                                Penelitian ini memiliki beberapa maksud dan tujuan sebagai berikut:
                            </p>
                            <ul>
                                <li>Memberikan pemahaman tentang apa yang dimaksud dengan unlawful content, baik secara hukum maupun praktis.</li>
                                <li>Mengidentifikasi jenis-jenis konten yang termasuk dalam kategori unlawful content.</li>
                                <li>Menganalisis dampak yang ditimbulkan oleh penyebaran unlawful content terhadap masyarakat dan negara.</li>
                                <li>Menjelaskan upaya dan strategi penanganan yang dapat dilakukan untuk mengatasi penyebaran unlawful content.</li>
                                <li>Memberikan rekomendasi yang konstruktif bagi pembuat kebijakan, aparat penegak hukum, serta masyarakat dalam menangani isu unlawful content.</li>
                            </ul>
                            <h3>1.3. Ruang Lingkup</h3>
                            <p>
                                Ruang lingkup penelitian ini mencakup beberapa aspek utama, yaitu:
                            </p>
                            <ul>
                                <li>Definisi dan karakteristik unlawful content, termasuk dasar hukum yang mengatur isu ini di Indonesia dan internasional.</li>
                                <li>Jenis-jenis unlawful content yang sering ditemui di dunia maya, seperti hoaks, ujaran kebencian, pornografi, dan sebagainya.</li>
                                <li>Studi kasus yang relevan dengan penyebaran dan penanganan unlawful content, baik di Indonesia maupun di negara lain.</li>
                                <li>Upaya penanganan unlawful content, termasuk peran pemerintah, platform digital, dan masyarakat dalam mengatasi permasalahan ini.</li>
                                <li>Tantangan yang dihadapi dalam penanganan unlawful content, termasuk aspek teknologi, hukum, dan budaya.</li>
                            </ul>
                        </section>

                        <section className='mb-4'>
                            <h2 className='text-center'>BAB II: PEMBAHASAN</h2>
                            <h3>2.1. Pengertian Unlawful Content</h3>
                            <p>
                                Unlawful content adalah segala bentuk konten yang melanggar hukum atau peraturan yang berlaku di suatu yurisdiksi. Konten ini dapat berupa tulisan, gambar, video, atau bentuk lainnya yang disebarkan melalui berbagai platform digital. Definisi unlawful content dapat berbeda-beda tergantung pada hukum yang berlaku di suatu negara. Di Indonesia, beberapa undang-undang yang relevan dengan pengaturan konten ilegal antara lain Undang-Undang Informasi dan Transaksi Elektronik (UU ITE), Undang-Undang Pornografi, dan Undang-Undang Hak Cipta.
                            </p>
                            <h3>2.2. Tujuan dan Manfaat Penanganan Unlawful Content</h3>
                            <p>
                                Penanganan unlawful content bertujuan untuk menciptakan lingkungan digital yang aman, nyaman, dan produktif bagi masyarakat. Adapun manfaat dari upaya ini meliputi:
                            </p>
                            <ul>
                                <li>Melindungi masyarakat dari dampak negatif konten ilegal, seperti penipuan, konflik sosial, dan kerusakan moral.</li>
                                <li>Menjaga stabilitas dan keamanan nasional dari ancaman yang ditimbulkan oleh penyebaran konten berbahaya.</li>
                                <li>Mendukung perkembangan ekosistem digital yang sehat dan berkelanjutan.</li>
                                <li>Menjamin kepatuhan terhadap hukum dan norma yang berlaku di masyarakat.</li>
                            </ul>
                        </section>

                        <section>
                            <h2 className='text-center'>BAB III: PENUTUP</h2>
                            <h3>3.1. Kesimpulan</h3>
                            <p>
                                Unlawful content adalah tantangan besar dalam era digital yang harus dihadapi dengan serius oleh semua pihak. Konten ilegal tidak hanya melanggar hukum, tetapi juga memberikan dampak negatif yang signifikan terhadap masyarakat dan negara. Oleh karena itu, diperlukan upaya kolaboratif antara pemerintah, platform digital, dan masyarakat untuk mengatasi permasalahan ini.
                            </p>
                            <h3>3.2. Saran</h3>
                            <ul>
                                <li>Pemerintah perlu memperkuat regulasi dan penegakan hukum terkait unlawful content, termasuk meningkatkan kapasitas aparat penegak hukum.</li>
                                <li>Platform digital harus berperan aktif dalam mengidentifikasi dan menghapus konten ilegal dari platform mereka.</li>
                                <li>Masyarakat perlu meningkatkan literasi digital agar dapat mengenali dan menghindari penyebaran unlawful content.</li>
                                <li>Penelitian lebih lanjut diperlukan untuk memahami pola dan dampak unlawful content serta menemukan solusi yang lebih efektif.</li>
                            </ul>
                        </section>
                    </p>
                </div>
            </section>
            <img src='https://webew.id/img/bg-lengkung.png' className='img-bg-lengkung-bawah'  style={{width: '100%',minHeight:'360px',marginTop:'0px'}} alt='bg lengkung'/>
            <section id='saran' className='mb-5'>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-7">
                            <div className='box p-3' style={{width: '100%',minHeight:'100px'}}>
                                <form onSubmit={createSaran} className='mt-3'>
                                    <div className='container'>
                                        <div className='row'>
                                            <h6 className='card-title text-center mb-3'>Beri saran untuk kemajuan webew</h6>
                                            <div className='col-12 col-md-6'>
                                                <input type='text' className='form-control form mb-3' value={saran} onChange={(e)=> setSaran(e.target.value)} required placeholder='Beri saran'/>
                                                <input type='text' className='form-disable form mb-3' value={name} readOnly/>
                                            </div>
                                            <div className='col-12 col-md-6 mb-2'>
                                                <input type='submit' className='btn btn-webew-login mb-2' value='Kirim Saran'/>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* NAVBARBOT */}
            <NavbarBot/>
        </div>
    )
}

export default Unlawful