// IMPORT REACT
import React,{ useState, useEffect } from 'react'
// IMPORT AXIOS
import axios from "axios"
// IMPORT USE PARAMS
import { useParams } from "react-router-dom"
// WE AQIQAH HASIL
const WeAqiqahHasil = () => {
    // MEMBUAT VARIABEL WE AQIQAH HASIL
    const [msg, setMsg] = useState('')
    const [urlFoto, setUrlFoto] = useState('')
    const [namaAnak, setNamaAnak] = useState('')
    const [jenisKelamin, setJenisKelamin] = useState('')
    const [sholeh, setSholeh] = useState('')
    const [tanggalLahir, setTanggalLahir] = useState('')
    const [namaBapak, setNamaBapak] = useState('')
    const [namaIbu, setNamaIbu] = useState('')
    const [hariAcara, setHariAcara] = useState('')
    const [tanggalAcara, setTanggalAcara] = useState('')
    const [jamMulai, setJamMulai] = useState('')
    const [jamAkhir, setJamAkhir] = useState('')
    const [alamat, setAlamat] = useState('')
    const [linkGMaps, setLinkGMaps] = useState('')
    const [kalender, setKalender] = useState('')
    const [jenisUndanganDigitalAqiqah, setJenisUndanganDigitalAqiqah] = useState('')
    const [tutupButton, setTutupButton] = useState('')
    const { salinLink } = useParams();
    const { namaTamu } = useParams();
    // USEEFFECT
    useEffect(()=>{
        selectWeAqiqahHasil()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // PROSES MENAMPILKAN DATA WE AQIQAH BERDASARKAN SALIN LINK BY PARAMS
    const selectWeAqiqahHasil = async() =>{
        try{
            const response = await axios.get(`https://server13.webew.id/selectWeAqiqahHasilBE/${salinLink}`)
            setUrlFoto(response.data.urlFoto)
            setNamaAnak(response.data.namaAnak)
            setJenisKelamin(response.data.jenisKelamin)
            setTanggalLahir(response.data.tanggalLahir)
            setNamaBapak(response.data.namaBapak)
            setNamaIbu(response.data.namaIbu)
            setHariAcara(response.data.hariAcara)
            setTanggalAcara(response.data.tanggalAcara)
            setJamMulai(response.data.jamMulai)
            setJamAkhir(response.data.jamAkhir)
            setAlamat(response.data.alamat)
            setLinkGMaps(response.data.linkGMaps)
            setJenisUndanganDigitalAqiqah(response.data.jenisUndanganDigitalAqiqah)
            const tanggalAwal = response.data.tanggalAcara.replace(/[^\w\s]/gi, '') + "T" + response.data.jamMulai.replace(/[^\w\s]/gi, '') + "00"
            const tanggalAkhir = response.data.tanggalAcara.replace(/[^\w\s]/gi, '') + "T" + response.data.jamAkhir.replace(/[^\w\s]/gi, '') + "00"
            setKalender(`https://calendar.google.com/calendar/render?action=TEMPLATE&text=Aqiqah ${response.data.namaAnak}&details=Kami mengharapkan kehadiran para tamu undangan di acara kami ini yaitu Aqiqah anak kami ${response.data.namaAnak} pada hari ${response.data.hariAcara}, ${response.data.tanggalAcara}, dari jam ${response.data.jamMulai} s/d ${response.data.jamAkhir}. Ditunggu kehadirannya yaaa&dates=${tanggalAwal}/${tanggalAkhir}&location=Indonesia`)
            if(response.data.jenisKelamin === "Perempuan"){
                setSholeh("Salihah")
            }else{
                setSholeh("Saleh")
            }
        }catch(error){
            if(error.response){
                setMsg(error.response.data.msg)
            }
        }
    }
    // BUTTON BUKA UNDANGAN
    const btnBukaUndangan = () => {
        setTutupButton("none")
    }
    // RETURN
    return ( // KURUNG BUKA RETURN
        <div className="background-home">
            {/* JENIS WE AQIQAH WHITE 1 */}
            {jenisUndanganDigitalAqiqah === "whiteI"
                ?<section id='whiteI'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/15628817_5655049.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/15628817_5655049.jpg')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/gradient-white-monochrome-background_15628817.htm#query=white%20background&position=32&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH WHITE 2 */}
            {jenisUndanganDigitalAqiqah === "whiteII"
                ?<section id='whiteII'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12230268_4931029.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12230268_4931029.jpg')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/smooth-white-wave-background_12230268.htm#query=white%20background&position=17&from_view=search&track=ais" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH WHITE 3 */}
            {jenisUndanganDigitalAqiqah === "whiteIII"
                ?<section id='whiteIII'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12220552_4935872.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12220552_4935872.jpg')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/white-abstract-background_12220552.htm#page=3&query=white%20background&position=20&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                : <section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH WHITE 4 */}
            {jenisUndanganDigitalAqiqah === "whiteIV"
                ?<section id='whiteIV'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/14571701_5487739.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/14571701_5487739.jpg')",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/paper-style-smooth-background_14571701.htm#page=4&query=white%20background&position=10&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn-kalender mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH FLOWER 1 */}
            {jenisUndanganDigitalAqiqah === "flowerI"
                ?<section id='flowerI'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/17437651_5631800.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/17437651_5631800.jpg')",width:"100%",height:"auto",backgroundPosition:"right",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-8" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",border:"3px solid #de8f8f"}}>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <img src={urlFoto} className='img-fluid mx-auto d-block mt-4' style={{maxHeight:"200px",borderRadius:"50%",boxShadow:"-10px 10px 15px -15px #8e8e8e"}} alt="Aqiqahan" />
                                                <h3 className='text-center mt-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mt-4'>Tasyakuran Aqiqah</h4>
                                                <h6 className='text-center mt-4'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h4 className='text-center fw-bold mt-4 '>{namaAnak}</h4>
                                                <h6 className='text-center'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#ffeeee",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#ffeeee",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#ffeeee",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/watercolor-copy-space-background-with-floral-hand-drawn-elements_12263072.htm#page=2&query=flower%20background&position=27&from_view=search&track=ais" aria-label="By Freepik">
                                            <input type="button" value="By freepik" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#ffeeee",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH FLOWER 2 */}
            {jenisUndanganDigitalAqiqah === "flowerII"
                ?<section id='flowerII'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12552268_5023096.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12552268_5023096.jpg')",width:"100%",height:"auto",backgroundPosition:"left",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-8" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",border:"3px solid #768280"}}>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <img src={urlFoto} className='img-fluid mx-auto d-block mt-4' style={{maxHeight:"200px",borderRadius:"50%",boxShadow:"-10px 10px 15px -15px #8e8e8e"}} alt="Aqiqahan" />
                                                <h3 className='text-center mt-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mt-4'>Tasyakuran Aqiqah</h4>
                                                <h6 className='text-center mt-4'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h4 className='text-center fw-bold mt-4 '>{namaAnak}</h4>
                                                <h6 className='text-center'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#d2e8e5",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#d2e8e5",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#d2e8e5",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/watercolor-background-with-hand-drawn-elements_12552268.htm?query=flower%20background#from_view=detail_alsolike" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#d2e8e5",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH FLOWER 3 */}
            {jenisUndanganDigitalAqiqah === "flowerIII"
                ?<section id='flowerIII'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/8845961_4004353.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/8845961_4004353.jpg')",width:"100%",height:"auto",backgroundPosition:"right",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-8" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",border:"3px solid #a57fc1"}}>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <img src={urlFoto} className='img-fluid mx-auto d-block mt-4' style={{maxHeight:"200px",borderRadius:"50%",boxShadow:"-10px 10px 15px -15px #8e8e8e"}} alt="Aqiqahan" />
                                                <h3 className='text-center mt-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mt-4'>Tasyakuran Aqiqah</h4>
                                                <h6 className='text-center mt-4'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h4 className='text-center fw-bold mt-4 '>{namaAnak}</h4>
                                                <h6 className='text-center'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#f4e4ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#f4e4ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#f4e4ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/powder-pastel-with-hand-drawn-elements-background_8845961.htm#page=3&query=flower%20background&position=8&from_view=search&track=ais" aria-label="By pikisuperstar">
                                            <input type="button" value="By pikisuperstar" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#f4e4ff",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH FLOWER 4 */}
            {jenisUndanganDigitalAqiqah === "flowerIV"
                ?<section id='flowerIV'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12557301_5014255.jpg')",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>{namaTamu}</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>Di Tempat</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-1 px-4' onClick={btnBukaUndangan} style={{border:"4px solid rgb(108, 108, 108)",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"url('https://webew.id/img/undanganDigital/UndanganDigitalAqiqah/12557301_5014255.jpg')",width:"100%",height:"auto",backgroundPosition:"right",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-8" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto",border:"3px solid #6599ba"}}>
                                        <div className="row justify-content-center">
                                            <div className="col-12 col-md-10">
                                                <img src={urlFoto} className='img-fluid mx-auto d-block mt-4' style={{maxHeight:"200px",borderRadius:"50%",boxShadow:"-10px 10px 15px -15px #8e8e8e"}} alt="Aqiqahan" />
                                                <h3 className='text-center mt-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mt-4'>Tasyakuran Aqiqah</h4>
                                                <h6 className='text-center mt-4'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h4 className='text-center fw-bold mt-4 '>{namaAnak}</h4>
                                                <h6 className='text-center'>Berjenis kelamin {jenisKelamin}, pada tanggal {tanggalLahir}</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} - {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#cbe1fb",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"#cbe1fb",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundColor:"#ffffff99",width:"100%",height:"auto"}}>
                            <h5 className='bi bi-image text-center text-center fw-bolder'> Image On Freepik</h5>
                            <div className="container-fluid">
                                <div className="row justify-content-center">
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-photo/lovely-newborn-asian-baby-sleeping-furry-cloth_28788857.htm#query=baby&position=12&from_view=search&track=sph" aria-label="By studioredcup">
                                            <input type="button" value="By studioredcup" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#cbe1fb",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                    <div className="col-12 col-md-4">
                                        <a href="https://www.freepik.com/free-vector/watercolor-background-with-hand-drawn-elements_12557301.htm?query=flower%20background#from_view=detail_alsolike" aria-label="By Freepik">
                                            <input type="button" value="By Freepik" className="btn mx-auto d-block my-2 px-4 py-2 fw-bold w-100" style={{backgroundColor:"#cbe1fb",borderRadius:"20px",color:"rgb(108, 108, 108)",boxShadow:"-10px 10px 15px -15px #8e8e8e"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH COLOR 1 */}
            {jenisUndanganDigitalAqiqah === "colorI"
                ?<section id='colorI'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(180deg,#c4ebff,white)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>Yusuf</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>{namaTamu}</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{border:"none",backgroundColor:"#c4ebff",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"linear-gradient(180deg,#c4ebff,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundImage:"linear-gradient(to top,#c4ebff,white)",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} sd {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundImage:"linear-gradient(180deg,#c4ebff,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH COLOR 2 */}
            {jenisUndanganDigitalAqiqah === "colorII"
                ?<section id='colorII'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(180deg,#f5dcff,white)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>Yusuf</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>{namaTamu}</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{border:"none",backgroundColor:"#f5dcff",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"linear-gradient(180deg,#f5dcff,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundImage:"linear-gradient(to top,#f5dcff,white)",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} sd {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundImage:"linear-gradient(180deg,#f5dcff,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH COLOR 3 */}
            {jenisUndanganDigitalAqiqah === "colorIII"
                ?<section id='colorIII'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(180deg,#ffdcdc,white)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>Yusuf</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>{namaTamu}</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{border:"none",backgroundColor:"#ffdcdc",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"linear-gradient(180deg,#ffdcdc,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundImage:"linear-gradient(to top,#ffdcdc,white)",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} sd {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundImage:"linear-gradient(180deg,#ffdcdc,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* JENIS WE AQIQAH COLOR 4 */}
            {jenisUndanganDigitalAqiqah === "colorIV"
                ?<section id='colorIV'>
                    {/* MODAL */}
                    <div style={{position:"fixed",height:"100vh",width:"100%",backgroundColor:"#0012294d",zIndex:"1",display:tutupButton,overflow:"auto"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center">
                                <div className="col-12 col-sm-8 col-md-7">
                                    <div style={{backgroundImage:"linear-gradient(180deg,#dcfff0,white)",height:"auto",width:"100%",marginTop:"10vh",zIndex:"2",borderRadius:"20px",padding:"30px"}}>
                                        <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 25px -10px #8e8e8e"}} alt="Aqiqahan" />
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td><h6 className='fw-bold' style={{marginTop:"50px"}}>Kepada Yth. Bapak/Ibu/Saudara/I</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><h5 className='fw-bold'>Yusuf</h5></td>
                                                </tr>
                                                <tr>
                                                    <td><h6 className='fw-bold'>{namaTamu}</h6></td>
                                                </tr>
                                                <tr>
                                                    <td><button className='fw-semibold py-2 px-4' onClick={btnBukaUndangan} style={{border:"none",backgroundColor:"#dcfff0",borderRadius:"20px",color:"rgb(108, 108, 108)",display:tutupButton,marginTop:"30px",boxShadow:"0 10px 25px -10px rgb(108, 108, 108)"}}>Buka Undangan</button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BOX 1 */}
                    <section style={{backgroundImage:"linear-gradient(180deg,#dcfff0,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat",backgroundAttachment:"fixed"}}>
                        <div className="container-fluid">
                            <div className="row justify-content-center p-4">
                                <div className="col-12 col-md-10 col-lg-11" style={{margin:"10px 0 10px 0"}}>
                                    <div className='box p-3' style={{backgroundImage:"linear-gradient(to top,#dcfff0,white)",width:"100%",height:"auto"}}>
                                        <div className="row">
                                            <div className="col-12 col-lg-6">
                                                <h3 className='text-center mt-2 mb-4'>بِسْمِ اللَّهِ الرَّحْمَنِ الرَّحِيم</h3>
                                                <h4 className='text-center fw-bold mb-5'>Tasyakuran Aqiqah</h4>
                                                <img src={urlFoto} className='img-fluid mx-auto d-block' style={{maxHeight:"200px",borderRadius:"20px",boxShadow:"-10px 10px 20px -20px #8e8e8e"}} alt="Aqiqahan" />
                                                <h4 className='text-center fw-bold mt-5 '>{namaAnak}</h4>
                                                <h6 className='text-center mt-3 mb-2'>Berjenis kelamin {jenisKelamin}, pada {tanggalLahir}</h6>
                                            </div>
                                            <div className="col-12 col-lg-6">
                                                <h6 className='text-center mt-2'>Puji sukur ke hadirat Allah SWT atas terlaksananya Sunnah Rasul Tasyakuran Aqiqah putra pertama Kami.</h6>
                                                <h6 className='text-center mt-4'>Yang akan dilaksanakan pada :</h6>
                                                <h5 className='text-center fw-bold'>{hariAcara}, {tanggalAcara}</h5>
                                                <h5 className='text-center fw-bold'>{jamMulai} sd {jamAkhir}</h5>
                                                <h6 className='text-center mb-4'>{alamat}</h6>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-md-4">
                                                        <a href={linkGMaps} aria-label="Google Maps">
                                                            <input type="button" value="Lihat Google Maps" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <a href={kalender} aria-label="saveCalendar">
                                                            <input type="button" value="Simpan ke kalender" className="btn-kalender mx-auto d-block my-2 p-2 fw-bold" style={{backgroundColor:"transparent",borderRadius:"20px",color:"rgb(108, 108, 108)",border:"3px solid rgb(108, 108, 108)"}}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <h6 className='text-center mt-4'>Semoga Allah SWT menjadikan anak yang {sholeh}, cerdas, dan berbakti kepada kedua orang tua dan agamanya, serta bermanfaat bagi bangsa dan negara, Aamiin.</h6>
                                                <h6 className='text-center mt-4'>Kami yang berbahagia</h6>
                                                <h5 className='text-center fw-bold'>{namaBapak} & {namaIbu}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOX 2 */}
                        <div className='p-4' style={{backgroundImage:"linear-gradient(180deg,#dcfff0,white)",width:"100%",height:"auto",backgroundPosition:"center",backgroundSize:"cover",backgroundRepeat:"no-repeat"}}>
                            <h6 className='text-center fw-bolder mt-2'>Website By Webew.id</h6>
                        </div>
                    </section>
                </section>
                :<section style={{display:"none"}}></section>
            }
            {/* MESSAGE */}
            <h1 className='text-center'>{msg}</h1>
        </div>
    ) // KURUNG BUKA RETURN
} // KURUNG BUKA WE AQIQAH HASIL
// EKSPOR
export default WeAqiqahHasil